import { QueryHookOptions, useQuery, gql } from "@apollo/client";
//import gql from "graphql-tag";


export const SURVEY_DETAILS_QUERY = gql`
query SurveyDetails($section_id: uuid!) {
  SUR_section_by_pk( ID: $section_id) {
    description
    image
    survey_id
    title
    SUR_survey_questions(order_by: {sort_position: asc}) {
      description
      id
      max_value
      min_value
      mixes
      name
      required
      sort_position
      survey_section_ID
      type
      validation_count
      validation_error_message
      validation_mode
      mixes
      options(order_by: {sort_position: asc}) {
        id
        question_id
        sort_position
        text
      }
    }
    SUR_survey {
      description
      id
      internal_name
      name
    }
  }
}
`

export interface SurveyDetailsData {
  SUR_section_by_pk: {
    ID: string;
    image: string;
    survey_id: string;
    title: string;
    description: string | null;
    SUR_survey_questions: {
      id: string;
      type: string;
      name: string;
      description: string | null;
      required: boolean;
      sort_position: number;
      min_value: number | null;
      max_value: number | null;
      validation_mode: string | null;
      validation_count: number | null;
      validation_error_message: string | null;
      mixes: string | null;
      options: {
        id: string;
        sort_position: number;
        text: string;
      }[];
    }[]
  }
}

export interface SurveyDetailsVariables {
  section_id: string;
}

export function useSurveyDetailsQuery(
  options?:
    | QueryHookOptions<
        SurveyDetailsData,
        SurveyDetailsVariables
      >
    | undefined
) {
  return useQuery<
    SurveyDetailsData,
    SurveyDetailsVariables
  >(SURVEY_DETAILS_QUERY, options);
}
