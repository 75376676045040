import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl
} from "@material-ui/core";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import DownloadCSVIcon from '@material-ui/icons/CloudDownload';
import triggerDownload from "js-file-download";

const QUERY = gql`
query GetUsers {
  app_user(order_by: {display_name: asc}, where: {disabled: {_eq: false}}) {
    id
    display_name
    email
    is_admin
    secret
  }
}
`

const DELETE_USER = gql`
mutation deleteUser($userid: uuid) {
  delete_app_user(where: {id: {_eq: $userid}}) {
    returning {
      id
    }
  }
}
`

const USERS_CSV = gql`
query GetUsers {
  app_user(order_by: {display_name: asc}, where: {disabled: {_eq: false}}) {
    id
    email
    gender
    age
    name
    phone_number
    address
    city
    zip_code
    country
    shipping_info
    availability
  }
}
`

const UPDATE_USER = gql`
mutation updateUser($userid: uuid!, $is_admin: Boolean) {
  update_app_user(where: {id: {_eq: $userid}}, _set: {is_admin: $is_admin}) {
    returning {
      id
      is_admin
    }
  }
}

`

interface QueryData {
  app_user: [{
    id: string;
    display_name: string;
    email: string;
    is_admin: boolean;
    secret: string;
  }
  ]
}

const UserList = () => {
  const history = useHistory();
  const { data, } = useQuery<QueryData>(QUERY);
  const [mappingArray, setMappingArray] = useState([])
  const {data: csvusers, loading: loading, refetch: refetchcsvusers} = useQuery(USERS_CSV, {fetchPolicy: "network-only"})
  const [deleteUser] = useMutation(DELETE_USER)
  const [updateUser] = useMutation(UPDATE_USER);
  const handleCreateNewClicked = () => {
    history.push("/admin/users/new")
  }

  useEffect(() => {
    if(csvusers){
      setMappingArray(csvusers.app_user)
    }
  }, [csvusers]);

  const handleDeleteClicked = (userid: any) => {
    if (window.confirm("Are you sure you wish to delete this user?")) {
      deleteUser({variables: {
        userid: userid
      }}).then((resp) => {
        if (resp.data.delete_app_user) {
          window.location.reload()
        }
      }).catch(e => {
        console.log('Failed to delete user');
        console.log(e);
      })   
    } else {
      return
    }
  }

  const handleDownloadClick = () => {

    let newMappingArray = JSON.parse(JSON.stringify(mappingArray))

    newMappingArray.forEach(function (user: any, index: any) {
      if(user.availability != null) {

        let mondayam = user.availability[0]
        mondayam = mondayam[mondayam.length-2]
        let mondaypm = user.availability[0]
        mondaypm = mondaypm[mondaypm.length-1]
        let tuesdayam = user.availability[1]
        tuesdayam = tuesdayam[tuesdayam.length-2]
        let tuesdaypm = user.availability[1]
        tuesdaypm = tuesdaypm[tuesdaypm.length-1]
        let wednesdayam = user.availability[2]
        wednesdayam = wednesdayam[wednesdayam.length-2]
        let wednesdaypm = user.availability[2]
        wednesdaypm = wednesdaypm[wednesdaypm.length-1]
        let thursdayam = user.availability[3]
        thursdayam = thursdayam[thursdayam.length-2]
        let thursdaypm = user.availability[3]
        thursdaypm = thursdaypm[thursdaypm.length-1]
        let fridayam = user.availability[4]
        fridayam = fridayam[fridayam.length-2]
        let fridaypm = user.availability[4]
        fridaypm = fridaypm[fridaypm.length-1]
        let saturdayam = user.availability[5]
        saturdayam = saturdayam[saturdayam.length-2]
        let saturdaypm = user.availability[5]
        saturdaypm = saturdaypm[saturdaypm.length-1]
        let sundayam = user.availability[6]
        sundayam = sundayam[sundayam.length-2]
        let sundaypm = user.availability[6]
        sundaypm = sundaypm[sundaypm.length-1]

        Object.assign(newMappingArray[index], {"MondayMorning": mondayam})
        Object.assign(newMappingArray[index], {"MondayAfternoon": mondaypm})
        Object.assign(newMappingArray[index], {"TuesdayMorning": tuesdayam})
        Object.assign(newMappingArray[index], {"TuesdayAfternoon": tuesdaypm})
        Object.assign(newMappingArray[index], {"WednesdayMorning": wednesdayam})
        Object.assign(newMappingArray[index], {"WednesdayAfternoon": wednesdaypm})
        Object.assign(newMappingArray[index], {"ThursdayMorning": thursdayam})
        Object.assign(newMappingArray[index], {"ThursdayAfternoon": thursdaypm})
        Object.assign(newMappingArray[index], {"FridayMorning": fridayam})
        Object.assign(newMappingArray[index], {"FridayAfternoon": fridaypm})
        Object.assign(newMappingArray[index], {"SaturdayMorning": saturdayam})
        Object.assign(newMappingArray[index], {"SaturdayAfternoon": saturdaypm})
        Object.assign(newMappingArray[index], {"SundayMorning": saturdayam})
        Object.assign(newMappingArray[index], {"SundayAfternoon": saturdaypm})
      } else {
        Object.assign(newMappingArray[index], {"MondayMorning": ''})
        Object.assign(newMappingArray[index], {"MondayAfternoon": ''})
        Object.assign(newMappingArray[index], {"TuesdayMorning": ''})
        Object.assign(newMappingArray[index], {"TuesdayAfternoon": ''})
        Object.assign(newMappingArray[index], {"WednesdayMorning": ''})
        Object.assign(newMappingArray[index], {"WednesdayAfternoon": ''})
        Object.assign(newMappingArray[index], {"ThursdayMorning": ''})
        Object.assign(newMappingArray[index], {"ThursdayAfternoon": ''})
        Object.assign(newMappingArray[index], {"FridayMorning": ''})
        Object.assign(newMappingArray[index], {"FridayAfternoon": ''})
        Object.assign(newMappingArray[index], {"SaturdayMorning": ''})
        Object.assign(newMappingArray[index], {"SaturdayAfternoon": ''})
        Object.assign(newMappingArray[index], {"SundayMorning": ''})
        Object.assign(newMappingArray[index], {"SundayAfternoon": ''})
      }

      delete user.availability
      delete user.__typename

      if (newMappingArray.length == index + 1) {
        console.log("done here")
        console.log(newMappingArray)
        var newArrayDataOfOjbect = Object.values(newMappingArray)
        console.log(newArrayDataOfOjbect)

        let csv = "";
        let header = Object.keys(newMappingArray[0]).join(",");
        let values = newMappingArray.map((o: any) => Object.values(o).join(",")).join("\n");
        csv += header + "\n" + values
        console.log(csv)

          triggerDownload(
            csv,
            'USER_DATA_CSV',
            "text/csv"
          );

      }
    })
    
  }

  const handleChange = (userid: string, ev: any) => {
    console.log(userid)
    updateUser({variables: {userid: userid, is_admin: ev.target.value}})
  }

  if (loading) {
    return (<div>loading data</div>)
  }

  console.log(data)

  return (<TableContainer component={Paper}>
    <Table size="small" >
      <TableHead>
        <TableRow style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}>
          <Typography variant="h5" style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  padding:15
                }}>
            User List
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<DownloadCSVIcon />}
            onClick={handleDownloadClick}
          >
            USER DATA CSV
          </Button>
        </TableRow>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Role</TableCell>
          <TableCell
                css={css`
                width: 200px;
                `}
              >
                <Button
                fullWidth
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleCreateNewClicked}
                >
                  Create New Admin
                </Button>
              </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data?.app_user?.map(user => {
          return (<TableRow key={user.id}>
            <TableCell component="th" scope="row">
              {user.display_name}
            </TableCell>
            <TableCell component="th" scope="row">
              {user.email}
            </TableCell>
            <TableCell>
              <FormControl variant="outlined" size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                id="statusselect"
                value={user.is_admin}
                onChange={(ev) => handleChange(user.id, ev)}
                style={{ backgroundColor: "white", borderRadius: "10px", width: "170px" }}
              >
                <MenuItem value="true">Admin</MenuItem>
                <MenuItem value="false">Regular User</MenuItem>
              </Select>
              </FormControl>
            </TableCell>
            <TableCell>
                <Button
                fullWidth
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {handleDeleteClicked(user.id)}}
                >
                  Delete
                </Button>
            </TableCell>
          </TableRow>)
          })
        }
      </TableBody>
    </Table>
  </TableContainer>);
};

export default UserList;
