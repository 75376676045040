import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { setContext } from "@apollo/client/link/context";
import { SessionProvider, useSession } from "./SessionContext";
import Login from "./pages/Login";
import AdminPage from "./admin";
import decodeJwt from "jwt-decode";
import LogOut from "./pages/Login/LogOut";
import isMobile from "is-mobile";
import Welcome from "./mobile/pages/welcome/Welcome";
import SurveyPage from "./survey/SurveyPage";
import VerifyEmail from "./pages/special/VerifyEmail";
import { useCheckUserExists } from "./pages/Login/useCheckUserExists";

const GRAPHQL_SERVER_URL = process.env["REACT_APP_GRAPHQL_SERVER_URL"] ||  "https://ai-romi-production-hasura.onrender.com/v1/graphql";
console.log(`GRAPHQL_SERVER_URL: ${GRAPHQL_SERVER_URL}`)
const httpLink = createHttpLink({
  uri: GRAPHQL_SERVER_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("fu2_jwt");
  if (!token) {
    return { headers };
  }
  const role =
    (decodeJwt(token) as any)["https://hasura.io/jwt/claims"][
      "x-hasura-default-role"
    ] || "user";
  return {
    headers: {
      ...headers,
      Authorization: "Bearer " + token,
      "x-hasura-role": role,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function MainView() {
  const { jwt, userId, sessionId, isAdmin } = useSession();
  const [checkUserExists] = useCheckUserExists();
  const history = useHistory();
  const useAdminView = !isMobile() && isAdmin;

  // Log user out if JWT is not valid
  useEffect(() => {
    if (!jwt || !userId) {
      return;
    }

    checkUserExists(userId).then(exists => {
      if (!exists) {
        console.log(`Currently logged in user ${userId} does not exist in the database. Logging out...`)
        history.push("/logout")
      }
    })
  }, [jwt, userId])

  if (!jwt) {
    return (
      <Switch>
        <Route
          path="/verify-email"
          exact={false}
          strict={false}
          render={(p) => <VerifyEmail />}
        />
        <Route
          path="/survey/:surveyId"
          exact={false}
          strict={false}
          render={(p) => <SurveyPage surveyId={p?.match?.params["surveyId"]} />}
        />
        <Route
          path="/login/:code?"
          exact={false}
          strict={false}
          render={(p) => <Login then={p?.match?.params["code"]} />}
        />
        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    );
  }
  return (
    <Switch>
      <Route
        path="/verify-email"
        exact={false}
        strict={false}
        render={(p) => <VerifyEmail />}
      />
      <Route
        path="/survey/:surveyId"
        exact={false}
        strict={false}
        render={(p) => <SurveyPage surveyId={p?.match?.params["surveyId"]} />}
      />
      <Route path="/logout">
        <LogOut />
      </Route>
      <Route path="/admin">
        <AdminPage />
      </Route>
      <Route path="/app/welcome">
        <Welcome />
      </Route>
      <Route path="/app/project" exact={false} strict={false}>
        <Switch>
          <Route
            path="/app/project/:projectId"
            exact={false}
            strict={false}
            render={(p) => (
              <Redirect
                to={`/app/project/${p.match.params["projectId"]}/samples`}
              />
            )}
          />
        </Switch>
      </Route>
      <Route path="/app/lexicon" exact={false} strict={false}>
        <Switch></Switch>
      </Route>
      <Route
        path="/login/:code"
        exact={false}
        strict={false}
        render={(p) => <Redirect to={`/q/${p?.match.params["code"]}`} />}
      />
      <Route path="/">
        {useAdminView ? (
          <Redirect to={`/admin`} />
        ) : sessionId ? (
          <Redirect to={`/app/project/${sessionId}`} />
        ) : (
          <Redirect to="/app/welcome" />
        )}
      </Route>
    </Switch>
  );
}

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Futura Now Text",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#4A1690",
    },
  },
});

function App() {
  return (
    <SessionProvider>
      <ApolloProvider client={client}>
        <Router>
          <ThemeProvider theme={theme}>
            <MainView />
          </ThemeProvider>
        </Router>
      </ApolloProvider>
    </SessionProvider>
  );
}

export default App;
