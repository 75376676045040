import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const UPDATE_QUESTION_MUTATION = gql`
  mutation UpdateQuestion(
    $question_id: uuid!
    $name: String!
    $description: String
    $required: Boolean!
    $type: String!
    $min_value: Int
    $max_value: Int
    $validation_mode: String
    $validation_count: Int
    $validation_error_message: String
  ) {
    update_SUR_survey_question_by_pk(
      pk_columns: { id: $question_id }
      _set: {
        name: $name
        description: $description
        required: $required
        type: $type
        min_value: $min_value
        max_value: $max_value
        validation_mode: $validation_mode
        validation_count: $validation_count
        validation_error_message: $validation_error_message
      }
    ) {
      id
      type
      name
      description
      required
      sort_position
      min_value
      max_value
      validation_mode
      validation_count
      validation_error_message
    }
  }
`;

export interface UpdateQuestionData {
  update_SUR_survey_question_by_pk: {
    id: string;
    survey_id: string;
    type: string;
    name: string;
    description: string | null;
    required: boolean;
    sort_position: number;
    min_value: number | null;
    max_value: number | null;
  };
}

export interface UpdateQuestionVariables {
  question_id: string;
  type: string;
  name: string;
  description?: string | null;
  required: boolean;
  min_value: number | null;
  max_value: number | null;
  validation_mode: string | null;
  validation_count: number | null;
  validation_error_message: string | null;
}

export function useUpdateQuestionMutation(
  options?:
    | MutationHookOptions<UpdateQuestionData, UpdateQuestionVariables>
    | undefined
) {
  return useMutation<UpdateQuestionData, UpdateQuestionVariables>(
    UPDATE_QUESTION_MUTATION,
    options
  );
}
