import React, { useState } from "react";
import { css } from "@emotion/react";
import { Button, Typography } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { gql, useMutation } from "@apollo/client";

const cssParent = css`
  width: 100%;
  margin-top: 20px;
`;

const MUT_UPLOAD = gql`
  mutation UploadBase64Image($base64Encoded: String!) {
    uploadBase64Image(base64Encoded: $base64Encoded) {
      success
      url
    }
  }
`;

enum State {
  idle,
  error,
  reading,
  uploading,
  complete,
}

const Messages = {
  [State.idle]: "Ready",
  [State.error]: "An error occurred",
  [State.reading]: "Reading file...",
  [State.uploading]: "Uploading file...",
  [State.complete]: "Upload complete",
};

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface ImageUploadProps {
  onSuccess: (url: string) => void;
}

const ImageUpload = (p: ImageUploadProps) => {
  const [state, setState] = useState(State.idle);
  const [upload] = useMutation(MUT_UPLOAD);

  const handleFileDropped = (files: File[]) => {
    setState(State.reading);
    toBase64(files[0])
      .then((base64) => {
        setState(State.uploading);
        upload({ variables: { base64Encoded: base64 } }).then((res) => {
          if (res.data.uploadBase64Image.success) {
            setState(State.complete);
            p.onSuccess(res.data.uploadBase64Image.url);
          } else {
            setState(State.error);
          }
        });
      })
      .catch((e) => {
        setState(State.error);
      });
    console.log(files);
  };
  const busy = state === State.reading || state === State.uploading;
  const message = Messages[state];

  return (
    <div css={cssParent}>
      <Typography align="center" variant="subtitle1">
        {message}
      </Typography>
      <div style={{textAlign: "center"}}>
        {busy ? (
          <Button disabled style={{color: 'white', backgroundColor: 'darkblue', width: 400}} variant="outlined" >
            Upload
          </Button>
        ) : (
          <Dropzone
            onDrop={handleFileDropped}
            accept={["image/png", "image/jpeg", "image/gif"]}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button style={{color: 'white', backgroundColor: 'darkblue', width: 400}} variant="outlined" >
                  Upload
                </Button>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
