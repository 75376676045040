import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const query = gql`
query ListProjects {
  project {
    id
    name
    image
    description
    created_at
    project_status
    session_type
    survey_id
    clt
    owner {
      display_name
    }
    totalConsumers: project_consumers_aggregate {
      aggregate {
        count
      }
    }
    totalCompleted: project_consumers_aggregate(where: {completed: {_eq: true}}) {
      aggregate {
        count(columns: completed)
      }
    }
    survey {
      responses {
        id
      }
    }
  }
}
`;

export interface ListProjectsVariables {}

export interface ListProjectsData {
  project: {
    id: string;
    name: string;
    image: string | null;
    clt: boolean;
    description: string | null;
    project_status: string;
    created_at: string;
    qr_codes: { id: string }[];
    survey: {
      responses: {
        id: string
      }[];
    }
    survey_id: string;
    session_type: string;
    totalConsumers: {
      aggregate: {
        count: string
      }
    }
    totalCompleted: {
      aggregate: {
        count: string
      }
    }
    owner: {
      display_name: string;
    } | null;
  }[];
}

export function useListProjects(
  options?:
    | QueryHookOptions<ListProjectsData, ListProjectsVariables>
    | undefined
) {
  return useQuery<ListProjectsData, ListProjectsVariables>(query, options);
}
