import React, { useState } from "react";
import { css } from "@emotion/react";
import Logo from "../../components/Logo";
import Typography from "../../components/Typography";
import { Typography as MUITypography, Grid, Box, Card, CardContent, TextField, Button, Link } from "@material-ui/core";
import useLogin from "./useLogin";
import { Alert } from "@material-ui/lab";
import { ArrowRight} from "@material-ui/icons";
import {gql, useQuery, useMutation} from '@apollo/client'

const cssParent = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const RESETPASS = gql`
  mutation ResetPasswordByEmail($email: String!) {
    app_user_reset_password(email: $email) {
      success
    }
  }
`

export interface LoginProps {
  then?: string;
}

export default function Login(p: LoginProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, state, error } = useLogin();
  const [resetPassword] = useMutation(RESETPASS)
  const handleLoginClick = () => {
    login(email, password);
  };

const resetPass = () => {
  if (email == "") {
    alert("please enter account login email to proceed!")
    return
  } else {
    resetPassword({variables: {
      email: email,
    }}).then((resp) => {
      if (resp) {
        console.log(resp.data.app_user_reset_password.success)
        alert("password successfully reset. Please check your email.")
      }
    }).catch(e => {
      console.log('Failed to reset password');
      console.log(e);
    })
  }
}

  return (
    <div css={cssParent}>
    <div>
      {p?.then ? (
        <Alert severity="info">
          <MUITypography variant="body1">Please log in to access that code</MUITypography>
        </Alert>
      ) : null}

        <div><Logo height={40} /></div>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ minHeight: '90vh', justifyContent: 'center' }}
      >
        <Grid item xs={3}>
          <Box component={Card} style={{ border: '1px solid gray', width: '400px', padding: '20px' }}>
          <CardContent>
            <Box>
              <MUITypography variant="h6" align="center">Sign-In</MUITypography>
            </Box>           
            <Box marginTop="40px">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
                label="Account Login Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Box marginTop="40px">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="password"
                variant="outlined"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box> 
            <Box marginTop="40px">
              <Button
                css={css`
                  width: 220px;
                  height: 60px;
                `}
                fullWidth
                size="medium"
                variant="contained"
                startIcon=""
                color="primary"
                onClick={handleLoginClick}
                disabled={state === "loading"}
              >
                &nbsp;&nbsp;Validate&nbsp;&nbsp;<ArrowRight/>
              </Button>
            </Box>
            {error ? <Box display="flex" justifyContent="center" style={{color:"red"}}>{error}</Box> : null}
            <Box marginTop="15px">
              <MUITypography variant="body2" align="center"><Link onClick={resetPass}>Forgot Password?</Link></MUITypography>
            </Box>  
          </CardContent>
        </Box>
        </Grid>       
      </Grid> 
    </div>
    </div>
  );
};

// import React, { useState } from "react";
// import { css } from "@emotion/react";
// import { gql, useLazyQuery } from "@apollo/client";
// import { useSession } from "../SessionContext";

// const style = css`
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   background: transparent linear-gradient(160deg, #000000 0%, #4077F3 100%) 0% 0% no-repeat padding-box;
//   color: white;
// `

// interface LoginQueryData {
//   authenticate: {
//     jwt?: string;
//     success: boolean
//   }
// }

// interface LoginQueryVars {
//   secret: string;
// }

// const LOGIN_QUERY = gql`
// query Login($secret: String!) {
//   authenticate(secret: $secret) {
//     jwt
//     success
//   }
// }
// `;

// const Login = () => {
//   const { jwt, setJwt } = useSession();
//   const [ accessKey, setAccessKey ] = useState('');
//   const [ error, setError ] = useState('');
//   const onQueryComplete = (data: LoginQueryData) => {
//     if (data.authenticate.success) {
//       setJwt(data.authenticate.jwt!)
//     } else {
//       setError('Invalid login');
//     }
//   }
//   const [runLoginQuery, { loading, data }] = useLazyQuery<
//     LoginQueryData,
//     LoginQueryVars
//   >(LOGIN_QUERY, {onCompleted: onQueryComplete, onError: (e) => {setError(e.message)}, fetchPolicy: "no-cache"});

//   return <div css={style}>
//     <h1>Food Unite</h1>
//     <h2>Enter your access key to continue</h2>
//     <input type="text" value={accessKey} onChange={e => setAccessKey(e.target.value)}/>
//     <button disabled={!accessKey || accessKey.length < 4} onClick={() => {setError('Logging in...'); runLoginQuery({variables: {secret: accessKey}})}}>Enter</button>
//     <p>{error}</p>
//   </div>
// }

// export default Login
