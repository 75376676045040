import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputAdornment,
  Link
} from "@material-ui/core";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import DeleteProject from "./DeleteProject";
import _, { sortBy } from "lodash";
import ApolloRouter from "../../../util/ApolloRouter";
import { Search, ControlPoint, Brightness1 } from "@material-ui/icons";
import { useListProjects } from "./queries/ListProjects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { InputLabel, NativeSelect } from '@material-ui/core';
import { useCreateNewProject, createGuidedPopins } from "./queries/CreateProject";
import { range } from "lodash";
import { useUserId } from "../../../SessionContext";
import triggerDownload from "js-file-download";

const UPDATE_STATUS = gql`
mutation updateStatus($project_status: String!, $id: uuid!) {
  update_project(where: {id: {_eq: $id}}, _set: {project_status: $project_status}) {
    returning {
      id
      project_status
    }
  }
}
`

const DUPE_DATA = gql`
query dupeData($testuuid: uuid!) {
  project_by_pk(id: $testuuid) {
    description
    id
    image
    owner_id
    name
    sniff_and_rate_description
    session_type
    project_status
    play_around_type
    preference_question
    preference_positive
    preference_negative
    project_customers {
      id
      project_id
      user_id
      app_user {
        email
      }
    }
    project_consumers {
      app_user {
        email
      }
      id
      project_id
      user_id
    }
    channels(order_by: {channel_index: asc}) {
      channel_index
      channel_type
      name
      description
    }
    mixes {
      channels
      description
      id
      name
      sort_position
    }
    survey {
      name
      id
      description
      SUR_sections {
        ID
        title
        sort_position
        image
        popin_info
        description
        SUR_survey_questions {
          description
          sort_position
          id
          max_value
          min_value
          name
          required
          survey_section_ID
          type
          mixes
          validation_count
          validation_error_message
          validation_mode
          options {
            question_id
            sort_position
            text
          }
        }
      }
    }
  }
}

`

const DUPE_SURVEY = gql`
mutation dupeSurvey($description: String, $name: String, $internal_name: String) {
  insert_SUR_survey(objects: {description: $description, name: $name, internal_name: $internal_name}) {
    returning {
      id
    }
  }
}
`

const DUPE_SECTIONS = gql`
mutation dupeSections($description: String, $image: String, $popin_info: String, $sort_position: Int, $survey_id: uuid!, $title: String) {
  insert_SUR_section(objects: {description: $description, image: $image, popin_info: $popin_info, sort_position: $sort_position, survey_id: $survey_id, title: $title}) {
    returning {
      ID
    }
  }
}
`

const DUPE_QUESTIONS = gql`
mutation dupeQuestions($survey_section_ID: uuid!, $type: String, $validation_count: Int, $validation_error_message: String, $validation_mode: String, $sort_position: Int, $required: Boolean, $name: String, $mixes: String, $min_value: Int, $max_value: Int, $description: String) {
  insert_SUR_survey_question(objects: {survey_section_ID: $survey_section_ID, type: $type, validation_count: $validation_count, validation_error_message: $validation_error_message, validation_mode: $validation_mode, sort_position: $sort_position, required: $required, name: $name, mixes: $mixes, min_value: $min_value, max_value: $max_value, description: $description}) {
    returning {
      id
    }
  }
}
`

const DUPE_OPTIONS = gql`
mutation dupeOptions($question_id: uuid, $sort_position: Int, $text: String) {
  insert_SUR_survey_question_option(objects: {question_id: $question_id, sort_position: $sort_position, text: $text}) {
    returning {
      id
    }
  }
}
`

const DUPE_PROJECT = gql`
mutation dupeProject($description: String, $image: String, $name: String, $owner_id: uuid, $play_around_type: String, $preference_negative: String, $preference_positive: String, $preference_question: String, $project_status: String, $session_type: String, $sniff_and_rate_description: String, $survey_id: uuid) {
  insert_project(objects: {description: $description, image: $image, name: $name, owner_id: $owner_id, play_around_type: $play_around_type, preference_negative: $preference_negative, preference_positive: $preference_positive, preference_question: $preference_question, project_status: $project_status, session_type: $session_type, sniff_and_rate_description: $sniff_and_rate_description, survey_id: $survey_id}) {
    returning {
      id
    }
  }
}
`

const DUPE_CHANNELS = gql`
mutation dupeChannels($channel_index: Int, $channel_type: String, $project_id: uuid, $name: String) {
  insert_project_channel(objects: {channel_index: $channel_index, channel_type: $channel_type, project_id: $project_id, name: $name}) {
    returning {
      id
    }
  }
}
`

const DUPE_MIXES = gql`
mutation dupeMixes($sort_position: Int, $project_id: uuid, $name: String, $description: String, $channels: _int4) {
  insert_MIX_mix(objects: {sort_position: $sort_position, project_id: $project_id, name: $name, description: $description, channels: $channels}) {
    returning {
      id
    }
  }
}
`

const RESPONSE_CSV = gql`
query MyQuery($projectid: uuid, $surveyid: uuid) {
  MIX_rating(where: {mix: {project_id: {_eq: $projectid}, project: {survey_id: {_eq: $surveyid}}}}, order_by: {app_user: {email: asc}}) {
    mix {
      id
      name
      description
      channels
      project {
        channels(order_by: {channel_index: asc}) {
          name
        }
      }
      SUR_responses(where: {survey_id: {_eq: $surveyid}}, order_by: {mix_id: asc, surveyee_user_id: asc}) {
        question_responses {
          value
          question {
            name
            type
            options {
              text
            }
          }
        }
        survey_duration
        created_at
      }
    }
    rating
    app_user {
      id
      email
      name
      gender
      age
      country
      city
      zip_code
      project_consumers(where: {project_id: {_eq: $projectid}}) {
        sniff_duration
        project {
          name
          session_type
        }
      }
      project_customers(where: {project_id: {_eq: $projectid}}) {
        project {
          name
          session_type
        }
      }
    }
  }
}

`

const END_PRODUCT_CSV = gql`
query ResponseCSV($surveyid: uuid!) {
  SUR_response(where: {survey_id: {_eq: $surveyid}, mix_id: {_is_null: true}}, order_by: {mix_id: asc, surveyee_user_id: asc}) {
    survey {
      name
      SUR_sections {
        SUR_survey_questions(order_by: {sort_position: asc}) {
          name
        }
      }
      project {
        name
        id
        session_type
      }
    }
    surveyee_user {
      id
      name
      gender
      country
      city
      age
      zip_code
      email
    }
    id
    created_at
    question_responses(order_by: {question: {sort_position: asc}}) {
      value
      question {
        type
        name
        options {
          text
        }
      }
    }
  }
}
`

const GET_RESPONSES = gql`
query getResponses($surveyee_user_id: uuid, $survey_id: uuid, $mix_id: uuid) {
  SUR_response(where: {survey_id: {_eq: $survey_id}, surveyee_user_id: {_eq: $surveyee_user_id}, mix_id: {_eq: $mix_id}}, order_by: {created_at: desc}) {
    question_responses {
      value
      question {
        type
        name
        options {
          text
        }
      }
    }
    survey_duration
    created_at
  }
}
`


export type SortByColumn = "name" | "created_at" | "project_status" | "description" | null;

export interface BetterSessionTableProps {
  sessionEntries: {
    id: string;
    name: string;
    owner_name: string;
    total_consumers: string;
    total_completed: string;
    project_status: string;
    created_at?: Date | null;
    clt: boolean;
    image?: string | null;
    description?: string | null;
    survey_id: string;
    session_type: string;
    survey: {
      responses: {
        id: string
      }[];
    };
    qr_codes: {
      id: string;
    }[];
  }[];
}

export function BetterSessionTable(p: BetterSessionTableProps) {

  let testuuidz: String = ""
  let surveyidz: String = ""
  let projectidz: String = ""
  const [createNewProject] = useCreateNewProject();
  const userId = useUserId();
  const [sortByColumn, setSortByColumn] = useState<SortByColumn>("created_at");
  const [isAscending, setIsAscending] = useState(false);
  const [filterSession, setFilterSession] = useState("");
  const [filterOwner, setFilterOwner] = useState("");
  const [filterCreated, setFilterCreated] = useState("");
  const [filterDescription, setFilterDescription] = useState("");
  const [updateStatus] = useMutation(UPDATE_STATUS);
  const {data: dupeData, refetch: refetchDupeData} = useQuery(DUPE_DATA, {fetchPolicy: "network-only", variables: {testuuid: testuuidz}})
  const {data: csvresponses, refetch: refetchcsvresponses} = useQuery(RESPONSE_CSV, {fetchPolicy: "network-only", variables: {projectid: projectidz, surveyid: surveyidz}})
  const {data: endproductcsvresponses, refetch: refetchendproductcsvresponses} = useQuery(END_PRODUCT_CSV, {fetchPolicy: "network-only", variables: {surveyid: surveyidz}})
  const {data: responses, refetch: refetchResponses} = useQuery(GET_RESPONSES, {fetchPolicy: "network-only", variables: {survey_id: 0, mix_id: 0, surveyee_user_id: 0}})
  const [dupeSurvey] = useMutation(DUPE_SURVEY);
  const [dupeSections] = useMutation(DUPE_SECTIONS);
  const [dupeQuestions] = useMutation(DUPE_QUESTIONS);
  const [dupeOptions] = useMutation(DUPE_OPTIONS);
  const [dupeProject] = useMutation(DUPE_PROJECT);
  const [dupeChannels] = useMutation(DUPE_CHANNELS);
  const [dupeMixes] = useMutation(DUPE_MIXES);
  const [createPopins] = useMutation(createGuidedPopins);
  const history = useHistory();

  const handleDownloadClick = (surveyid: any, sessiontype: any, sessionname: any, projectid: any) => {
    if (sessiontype == 'End-Product Test') {
     let maxresponses = 0
      let newMappingArray = new Array as any
      let responsecsv = new Array as any
      if (surveyid) {
        refetchendproductcsvresponses({surveyid: surveyid}).then ((resp) => {
          newMappingArray = JSON.parse(JSON.stringify([resp.data]))
          newMappingArray[0].SUR_response.forEach(function (response: any, responseindex: any) {
            let responsearray = new Array as any
            responsearray.push(response.survey.project.name)
            responsearray.push(response.survey.project.session_type)
            let formatteddate = new Date(response.created_at)
            const createdString = formatteddate
            ? `${formatteddate.toLocaleDateString()} ${formatteddate.toLocaleTimeString()}`
            : "Not set";
            responsearray.push(createdString)
            responsearray.push(response.surveyee_user.email)
            responsearray.push(response.surveyee_user.name)
            responsearray.push(response.surveyee_user.gender)
            responsearray.push(response.surveyee_user.age)
            responsearray.push(response.surveyee_user.city)
            responsearray.push(response.surveyee_user.zip_code)
            responsearray.push(response.surveyee_user.country)
            if (parseInt(response.question_responses.length) > maxresponses) {
              maxresponses = parseInt(response.question_responses.length)
            }
            if(response.question_responses.length > 0) {
              response.question_responses.forEach(function (question_response: any, question_responseindex: any) {
                responsearray.push(question_response.question.type)
                responsearray.push(question_response.question.name.replaceAll(",", "-"))
                responsearray.push(question_response.value)
                let optionstring = ""
                if (question_response.question.options.length > 0) {
                  question_response.question.options.forEach(function (options: any, optionsindex: any) {
                    if (question_response.question.options.length == optionsindex + 1) {
                      optionstring = optionstring + (options.text)
                    } else {
                      optionstring = optionstring + (options.text + "|")
                    }
                  })
                  responsearray.push(optionstring)
                } else {
                  responsearray.push(" ")
                }
              })
            }
            responsecsv.push(responsearray)
            if (newMappingArray[0].SUR_response.length == responseindex + 1) {
  
              let questionheaderstring = ",Question Type, Question, Answer, Options"
              let questionheader = ""
              for(var i = 0; i < maxresponses; i++) {
                questionheader = questionheader + questionheaderstring
              }
              var newArrayDataOfOjbect = Object.values(responsecsv)
              let csv = "";
              let header = ("Test Name, Test Type, Response Date, Email, User Name, Gender, Age, City, Zip, Country" + questionheader);
              let values = newArrayDataOfOjbect.map((o: any) => Object.values(o).join(",")).join("\n");
              csv += header + "\n" + values
                triggerDownload(
                  csv,
                  sessionname + "_responses",
                  "text/csv"
                );
            }
          })
        }).catch(e => {
        console.log(e);         
        })
      }
    } else {
      let maxresponses = 0
      let newMappingArray = new Array as any
      let responsecsv = new Array as any
      if (surveyid) {
        refetchcsvresponses({surveyid: surveyid, projectid: projectid}).then ((resp) => {
          newMappingArray = JSON.parse(JSON.stringify([resp.data]))
          newMappingArray[0].MIX_rating.forEach(async function (response: any, responseindex: any) {
            let responsearray = new Array as any
            let surveyduration = 0
            let top3 = "NO"
            if (response.app_user.project_consumers.length > 0) {
              responsearray.push(response.app_user.project_consumers[0].project.name)
              responsearray.push(response.app_user.project_consumers[0].project.session_type)
            } else {
              responsearray.push(response.app_user.project_customers[0].project.name)
              responsearray.push(response.app_user.project_customers[0].project.session_type)
            }
            let myuserid = response.app_user.id
            let mymixid = response.mix.id
            responsearray.push(response.app_user.email)
            responsearray.push(response.app_user.name)
            responsearray.push(response.app_user.gender)
            responsearray.push(response.app_user.age)
            responsearray.push(response.app_user.city)
            responsearray.push(response.app_user.zip_code)
            responsearray.push(response.app_user.country)
            if (response.app_user.project_consumers.length > 0) {
              var date = new Date(0);
              date.setSeconds(parseInt(response.app_user.project_consumers[0].sniff_duration)); // specify value for SECONDS here
              var timeString = date.toISOString().substring(11, 19);
              responsearray.push(timeString)
            } else {
              responsearray.push("in consumer table - this is a customer")
            }
            responsearray.push(response.mix.description)
            responsearray.push(response.rating)
            let channelflows = ""
            let channelnames = ""
            response.mix.project.channels.forEach(function (channelname: any, channelnameindex: any) {
              if (response.mix.project.channels.length == channelnameindex + 1) {
                channelnames = channelnames + (channelname.name)
              } else {
                channelnames = channelnames + (channelname.name + "|")
              }
            })
            responsearray.push(channelnames)
            response.mix.channels.forEach(function (channel: any, channelindex: any) {
              if (response.mix.channels.length == channelindex + 1) {
                channelflows = channelflows + (channel)
              } else {
                channelflows = channelflows + (channel + "|")
              }
            })
            responsearray.push(channelflows)

            let promise = new Promise((resolve, reject) => {
              refetchResponses({survey_id: surveyid, surveyee_user_id: myuserid, mix_id: mymixid}).then ((resp) => {
                if (resp.data.SUR_response.length > 0) {

                  let formatteddate = new Date(resp.data.SUR_response[0].created_at )
                  const createdString = formatteddate
                  ? `${formatteddate.toLocaleDateString()} ${formatteddate.toLocaleTimeString()}`
                  : "Not set";
                  responsearray.splice(2, 0, createdString)
                  top3 = "YES"   
                  responsearray.splice(11, 0, top3)
                  var date = new Date(0);
                  date.setSeconds(parseInt(resp.data.SUR_response[0].survey_duration )); // specify value for SECONDS here
                  var timeString2 = date.toISOString().substring(11, 19);
                  responsearray.push(timeString2)
                  if (parseInt(resp.data.SUR_response[0].question_responses.length) > maxresponses) {
                    maxresponses = parseInt(resp.data.SUR_response[0].question_responses.length)
                  }
                  if(resp.data.SUR_response[0].question_responses.length > 0) {
                    resp.data.SUR_response[0].question_responses.forEach(function (question_response: any, question_responseindex: any) {
                      responsearray.push(question_response.question.type)
                      responsearray.push(question_response.question.name.replaceAll(",", "-"))
                      responsearray.push(question_response.value)
                      let optionstring = ""
                      if (question_response.question.options.length > 0) {
                        question_response.question.options.forEach(function (options: any, optionsindex: any) {
                          if (question_response.question.options.length == optionsindex + 1) {
                            optionstring = optionstring + (options.text)
                          } else {
                            optionstring = optionstring + (options.text + "|")
                          }
                        })
                        responsearray.push(optionstring)
                      } else {
                        responsearray.push(" ")
                      }
                    })
                  }
                } else {
                  responsearray.splice(2, 0, "no survey")
                  responsearray.splice(11, 0, "NO")
                }
                resolve(responsearray)
              }).catch(e => {
                console.log(e);         
              })  
            })
            const newresponsearray = await promise
            responsecsv.push(newresponsearray)
            if (newMappingArray[0].MIX_rating.length === responsecsv.length) {
              let questionheaderstring = ", Question Type, Question, Answer, Options"
              let questionheader = ""
              for(var i = 0; i < maxresponses; i++) {
                questionheader = questionheader + questionheaderstring
              }
              let csv = "";
              let header = ("Test Name, Test Type, Response Date, Email, User Name, Gender, Age, City, Zip, Country, Sniff Duration, Top 3, Mix, Mix Rating, Channel Names, Channel Flows, Survey Duration" + questionheader);           
              let values = responsecsv.map((o: any) => Object.values(o).join(",")).join("\n");
              csv += header + "\n" + values
              triggerDownload(csv, sessionname, "text/csv");
            }                              
          })
        }).catch(e => {
        console.log(e);         
        })
      }
    }
  }

  const handleCreateNewClicked = () => {

    createNewProject({variables: {
      name:"New Test",
      image:"",
      description:'',
      sniff_and_rate_description:'',
      play_around_type:'Standard',
      preference_question:'',
      preference_positive:'',
      preference_negative:'',
      session_type: '',
      sniff_test_length: 5,
      sniff_rating_scale: 10,
      channels: range(8).map(index => ({
        name: `Channel ${index + 1}`,
        channel_index: index,
      })),
      owner_id: userId!
    }}).then((resp) => {
      if (resp.data) {
        createPopins({variables: {
          project_id: resp.data.insert_project_one.id
        }})
        history.push(`/admin/sessions/${resp.data.insert_project_one.id}`); 
      }
    }).catch(e => {
      console.log('Failed to create session');
      console.log(e);
    })
  };

  const handleSortingLabelClicked = (column: SortByColumn) => {
    if (sortByColumn === column) {
      setIsAscending(current => !current);
    } else {
      setSortByColumn(column);
      setIsAscending(true);
    }
  }

  // Compute rows to display based on sorts and filters
  let sortedEntries = p.sessionEntries.filter(session => {
    if (filterSession && !session.name.toLowerCase().includes(filterSession.toLowerCase())) {
      return false;
    }
    if (filterOwner && session.owner_name && !session.owner_name.toLowerCase().includes(filterOwner.toLowerCase())) {
      return false;
    }
    const createdString = session.created_at
      ? `${session.created_at.toLocaleDateString()} ${session.created_at.toLocaleTimeString()}`
      : "Not set";
    if (filterCreated && !createdString.toLowerCase().includes(filterCreated.toLowerCase())) {
      return false;
    }
    if (filterDescription && session.description && !session.description.toLowerCase().includes(filterDescription.toLowerCase())) {
      return false;
    }
    return true;
  })
  sortedEntries = _.sortBy(sortedEntries, (iter) => {
    switch (sortByColumn) {
      case "name":
        return iter.name;
      case "project_status":
        return iter.project_status;
      case "created_at":
        return iter.created_at;
      case "description":
        return iter.description;
    }
  });
  if (!isAscending) {
    sortedEntries = _.reverse(sortedEntries);
  }

  const handleChange = (sessionid: string, ev: any) => {
    updateStatus({variables: {id: sessionid, project_status: ev.target.value}})
  }

  const getColor = (status: string) => {
    if (status == 'Open') {
      return 'Green'
    } else if (status == 'Closed') {
      return 'Red'
    } else if (status == 'Paused') {
      return 'Orange'
    } else {
      return 'Gray'
    }

  }

  async function duplicateProject(testid: String) {

    let promise = new Promise((resolve, reject) => {

      refetchDupeData({testuuid: testid}).then ((resp) => {
      if(resp.data) {
        dupeSurvey({variables: {
          description: resp.data.project_by_pk.survey.description, 
          name: resp.data.project_by_pk.survey.name + " *duplicate", 
          internal_name: resp.data.project_by_pk.survey.name + " *duplicate",
      }}).then((resp2: any) => {
        if(resp2.data) {
          let survid = resp2.data.insert_SUR_survey.returning[0].id
          resolve(survid)
          refetchDupeData({testuuid: testid}).then ((resp3) => {
            if(resp3.data) {                 
              resp3.data.project_by_pk.survey.SUR_sections.map((section: any) => {
                dupeSections({variables: {
                  description: section.description, 
                  image: section.image, 
                  popin_info: section.popin_info, 
                  sort_position: section.sort_position, 
                  survey_id: survid, 
                  title: section.title
              }}).then((resp4: any) => {
                  if(resp4.data) {
                    let secid = resp4.data.insert_SUR_section.returning[0].ID
                    refetchDupeData({testuuid: testid}).then ((resp5) => {
                      if(resp5.data) {
                        section.SUR_survey_questions.map((question: any) => {
                          dupeQuestions({variables: {
                            survey_section_ID: secid, 
                            type: question.type, 
                            validation_count: question.validation_count, 
                            validation_error_message: question.validation_error_message, 
                            validation_mode: question.validation_mode, 
                            sort_position: question.sort_position, 
                            required: question.required, 
                            name: question.name, 
                            mixes: null, 
                            min_value: question.min_value, 
                            max_value: question.max_value, 
                            description: question.description
                          }}).then((resp6: any) => {
                              if(resp6.data) {
                                let quesid = resp6.data.insert_SUR_survey_question.returning[0].id
                                question.options.map((option: any, index: any) => {
                                  dupeOptions({variables: {
                                    question_id: quesid, 
                                    text: option.text, 
                                    sort_position: option.sort_position, 
                                  }}).then((resp7: any) => {
                                      if(resp7.data) { 
                
                                      }
                                  }).catch(e => {
                                    console.log(e);
                                  })  
                                })                               
                              }
                          }).catch(e => {
                            console.log(e);
                          })  
                           
                        })
                      }

                    })               
                  }
              }).catch(e => {
                console.log(e);
              })
            });
          }        
        })   
        }
      }).catch(e => {
        console.log(e);
      })
    }
  })
  })

  const projectsurvid = await promise
  let projid = ""
  refetchDupeData({testuuid: testid}).then ((resp9) => {
    if(resp9.data) {
      dupeProject({variables: {
        description: resp9.data.project_by_pk.description, 
        image: resp9.data.project_by_pk.image, 
        name: resp9.data.project_by_pk.name + " *duplicate", 
        owner_id: resp9.data.project_by_pk.owner_id, 
        play_around_type: resp9.data.project_by_pk.play_around_type, 
        preference_negative: resp9.data.project_by_pk.preference_negative, 
        preference_positive: resp9.data.project_by_pk.preference_positive, 
        preference_question: resp9.data.project_by_pk.preference_question, 
        project_status: resp9.data.project_by_pk.project_status, 
        session_type: resp9.data.project_by_pk.session_type, 
        sniff_and_rate_description: resp9.data.project_by_pk.sniff_and_rate_description, 
        survey_id: projectsurvid
      }}).then((resp10: any) => {
        if(resp10.data) {
          projid = resp10.data.insert_project.returning[0].id
          duplicatemixes(projid, testid)
          refetchDupeData({testuuid: testid}).then ((resp11) => {
            if(resp11.data) {
              resp11.data.project_by_pk.channels.map((channel: any) => {
              dupeChannels({variables: {
                channel_index: channel.channel_index, 
                channel_type: channel.channel_type, 
                project_id: projid, 
                name: channel.name
              }})

            })
            }
          })        
        }
      })
    }
  })
  }

  const duplicatemixes = (projid: any, testid: any) => {
    refetchDupeData({testuuid: testid}).then ((resp13) => {
      if(resp13.data) {
        resp13.data.project_by_pk.mixes.map((mix: any) => {
        console.log(`{${mix.channels.join(", ")}}`)
        dupeMixes({variables: {
          sort_position: mix.sort_position, 
          project_id: projid, 
          name: mix.name, 
          description: mix.description, 
          channels: `{${mix.channels.join(", ")}}`,
        }}).then((resp12: any) => {
          if(resp12.data) {
          
          }
        })
  
      })
      }
    })  
  }

  return (
    <TableContainer component={Paper} style={{ border: '1px solid gray', borderRadius: '5px' }}>
      <Table size="small" >
        <TableHead >
          <TableRow>
            <TableCell align="center" colSpan={3}>
                <div css={css`
                  font-size: 1.4em;
                  color: #4A1690;
                  font-weight: 800;
                `}>Admin</div>
                <div>Test List</div>
            </TableCell>


            <TableCell align="center" colSpan={5}>
              <Button
                css={css`
                  width: 220px;
                `}
                size="medium"
                variant="contained"
                startIcon=""
                color="primary"
                onClick={handleCreateNewClicked}
              ><ControlPoint />
                &nbsp;&nbsp;Create New Test
              </Button>
            </TableCell>         

            <TableCell align="center" colSpan={4}>
            {/*<FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">Order</InputLabel>
                <Select
                    defaultValue={10}
                    label="Status"
                    style={{ backgroundColor: "white", borderRadius: "10px" }}
                >
                <MenuItem value={10}>Name</MenuItem>
                </Select>
            </FormControl>
            */}
            </TableCell>
 
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell colSpan={2} style={{textAlign: "center"}}>
                <SortingHeaderLabel active={sortByColumn === "project_status"} ascending={isAscending} onClick={() => handleSortingLabelClicked("project_status")}>
                test status
                </SortingHeaderLabel>
              </TableCell>
              <TableCell style={{textAlign: "center"}}>
                completed
              </TableCell> 
              <TableCell style={{textAlign: "center"}}>
                <SortingHeaderLabel active={sortByColumn === "name"} ascending={isAscending} onClick={() => handleSortingLabelClicked("name")}>
                name
                </SortingHeaderLabel>
              </TableCell>  
              <TableCell style={{textAlign: "center"}}>
                <SortingHeaderLabel active={sortByColumn === "created_at"} ascending={isAscending} onClick={() => handleSortingLabelClicked("created_at")}>
                created
                </SortingHeaderLabel>
              </TableCell>
              <TableCell colSpan={4} style={{textAlign: "center"}}>
                actions
              </TableCell>                        
            </TableRow>
          {sortedEntries.map((session) => (
            <TableRow     
              key={session.id}
            >
              <TableCell>
                <Brightness1 style={{color: getColor(session.project_status)}}></Brightness1>
              </TableCell>  
              <TableCell style={{paddingTop: "10px", paddingBottom: "10px"}}>
              <FormControl variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
              <Select
                id="statusselect"
                value={session.project_status}
                onChange={(ev) => handleChange(session.id, ev)}
                style={{ backgroundColor: "white", borderRadius: "10px", width: "110px" }}
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
                <MenuItem value="Paused">Paused</MenuItem>
              </Select>
              </FormControl>
              </TableCell>           
              <TableCell style={{textAlign: "center"}}>
                <span>{session.total_completed}/{session.total_consumers}</span>
              </TableCell>
              { session.clt == true &&
              <TableCell style={{textAlign: "center"}}>
                <span>{session.name}</span><span style={{color: "red"}}> *CLT</span>
              </TableCell>
              }
              { session.clt == false &&
              <TableCell style={{textAlign: "center"}}>
                <span>{session.name}</span>
              </TableCell>                
              }
              <TableCell style={{textAlign: "center"}}>
                {session.created_at
                  ? `${session.created_at.toLocaleDateString()} ${session.created_at.toLocaleTimeString()}`
                  : "Not set"}
              </TableCell>
              <TableCell><Button disabled={session.survey.responses.length == 0} variant="outlined" color="primary" onClick={() => handleDownloadClick(session.survey_id, session.session_type, session.name, session.id)}>CSV</Button></TableCell>
              <TableCell><Link onClick={() => duplicateProject(session.id)} color="inherit" underline="none"><Button variant="outlined" color="primary">Duplicate</Button></Link></TableCell>
              <TableCell>
                <Link href={`/admin/sessions/${session.id}`} color="inherit" underline="none">      
                  <Button
                    variant="outlined"
                    color="primary"
                  >Modify
                  </Button>
                </Link>
              </TableCell>
              <TableCell><DeleteProject projectId={session.id} surveyId={session.survey_id} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface FilterInputProps {
  filter: string;
  onChange: (newFilter: string) => void;
}

function FilterInput(p: FilterInputProps) {
  return (
    <TextField
    fullWidth
      value={p.filter}
      onChange={e => p.onChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}

interface SortingHeaderLabelProps {
  children: React.ReactNode;
  active: boolean;
  ascending: boolean;
  onClick: () => void;
}

function SortingHeaderLabel(p: SortingHeaderLabelProps) {
  return <Box style={{fontWeight: p.active ? "bold" : undefined}} onClick={p.onClick}>
    <Box display="inline" style={{marginRight: "10px"}}>{p.children}</Box>
    {(p.active && p.ascending) ? <FontAwesomeIcon icon={faSortDown}/> : null}
    {(p.active && !p.ascending) ? <FontAwesomeIcon icon={faSortUp}/> : null}
  </Box>
}

const SniffTestList = () => {
  const query = useListProjects({pollInterval: 2000});

  return (
    <ApolloRouter
      loadable={query}
      ready={(data) => (
        <BetterSessionTable
          sessionEntries={data.project.map((p) => ({
            id: p.id,
            name: p.name,
            image: p.image,
            clt: p.clt,
            total_consumers: p.totalConsumers.aggregate.count,
            total_completed: p.totalCompleted.aggregate.count,
            description: p.description || "Not set",
            owner_name: p.owner?.display_name || "Not set",
            project_status: p.project_status,
            qr_codes: p.qr_codes,
            survey: p.survey,
            survey_id: p.survey_id,
            session_type: p.session_type,
            created_at: new Date(p.created_at),
          }))}
        />
      )}
    />
  );
};

export default SniffTestList;
