import React, { useState } from "react";
import { css } from "@emotion/react";
import {gql, useQuery, useMutation} from '@apollo/client'
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  responsiveFontSizes,
  TextField,
  Typography,
} from "@material-ui/core";
import crs from "crypto-random-string";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDice } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { useCreateUser } from "../../queries/CreateUser";

const styles = {
  container: css`
    height: calc(100vh - 160px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    gap: 1em 1em;
  `,
  card: css`
    max-width: 400px;
  `,
  access: css`
    display: flex;
  `,
};

const CREATE_USER = gql`
mutation RegisterUserSimple($email: String!, $name: String!, $password: String!) {
  app_user_register(email: $email, name: $name, password: $password, is_admin: true) {
    success
    app_user_id
  }
}
`;

const SET_ADMIN = gql`
mutation setAdmin($id: uuid) {
  update_app_user(where: {id: {_eq: $id}}, _set: {is_admin: true}) {
    returning {
      id
      is_admin
    }
  }
}
`

interface CreateUserProps {}

export default function CreateUser(p: CreateUserProps) {
  const history = useHistory();
  const [createUser] = useCreateUser();
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secret, setSecret] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);
  const [addAdmin] = useMutation(CREATE_USER)
  const [setAdmin] = useMutation(SET_ADMIN)

  const valid = displayName.length >= 2 && email.length >= 4;

  const handleCreate = () => {
    addAdmin({
      variables: {
        name: displayName,
        email: email,
        password: password,
      },
      refetchQueries: ["GetUsers"],
    }).then((resp) => {
      console.log(resp)
      setAdmin({
        variables: {
          id: resp.data.app_user_register.app_user_id
        }
      })
      history.replace("/admin/users");
    }).catch(e => {
      alert("email already exists!");
      console.log(e);
    })   
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div css={styles.container}>
      <Card css={styles.card}>
        <CardContent>
          <Typography variant="h5">Create New Admin</Typography>

          <TextField
            fullWidth
            label="Name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <div css={styles.access}>
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div css={styles.access}>
            <TextField
              fullWidth
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </CardContent>
      </Card>
      <div />
      <Footer>
        <div>
          <Box component="span" m={1}>
            <Button variant="contained" onClick={handleBack}>
              Cancel
            </Button>
          </Box>
          <Box component="span">
            <Button variant="contained" color="primary" onClick={handleCreate} disabled={!valid}>
              Create User
            </Button>
          </Box>
        </div>
      </Footer>
    </div>
  );
}
