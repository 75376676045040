import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const DELETE_QUESTION_MUTATION = gql`
  mutation DeleteQuestion($question_id: uuid!) {
    delete_SUR_survey_question_by_pk(id: $question_id) {
      id
      options {
        id
      }
    }
  }
`;

export interface DeleteQuestionData {
  delete_SUR_survey_question_by_pk: {
    id: string;
    survey_id: string;
    options: {
      id: string;
    }[]
  }
}

export interface DeleteQuestionVariables {
  question_id: string;
}

export function useDeleteQuestionMutation(
  options?:
    | MutationHookOptions<DeleteQuestionData, DeleteQuestionVariables>
    | undefined
) {
  return useMutation<DeleteQuestionData, DeleteQuestionVariables>(
    DELETE_QUESTION_MUTATION,
    options
  );
}
