import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Radio,
  CardContent,
  Divider,
  FormControl,
  FormGroup,
  Checkbox,
  Select,
  Chip,
  FormLabel,
  useTheme,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  Typography,
  IconButton,
  Switch,
  TextField,
  Accordion,
  AccordionSummary,
  InputLabel,
  AccordionDetails
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ExpandMore } from "@material-ui/icons";
import { useIsMount } from "../../../../util/useIsMount";
import Options, { QuestionOption } from "./Options";
import QuestionTypeSelect from "./QuestionTypeSelect";
import RatingOptionsNumeric from "./RatingOptionsNumeric";
import SurveyCard from "../../../../components/SurveyCard";
import { useDebouncedEffect } from "../../../../util/useDebouncedEffect";
import SelectionOptions from "./SelectionOptions";
import {gql, useQuery, useMutation} from '@apollo/client'
import { truncate } from "lodash";

export interface QuestionUpdate {
  id: string;
  type: string;
  name: string;
  description: string;
  required: boolean;
  minValue: number | null;
  maxValue: number | null;
  validationMode: string | null;
  validationNumber: number | null;
  validationErrorMessage: string | null;
}

export interface QuestionCardDataProps {
  id: string;
  index: number;
  isFirst: boolean;
  isLast: boolean;
  name: string;
  description: string;
  type: string;
  required: boolean;
  minValue: number | null;
  maxValue: number | null;
  validationMode: string | null;
  validationNumber: number | null;
  validationErrorMessage: string | null;
  options: QuestionOption[];
  mixes: string;
}

export interface QuestionCardProps extends QuestionCardDataProps {
  onQuestionUpdated: (update: QuestionUpdate) => void;
  onQuestionDeleted: () => void;
  onQuestionMoved: (direction: "up" | "down") => void;
  onOptionCreated: (option: QuestionOption) => void;
  onOptionUpdated: (option: QuestionOption) => void;
  onOptionDeleted: (optionId: string) => void;
}

export default function QuestionCard(p: QuestionCardProps) {

  const GET_MIXES = gql`
  query getMixes($questionid: uuid) {
    MIX_mix(where: {project: {survey: {SUR_sections: {SUR_survey_questions: {id: {_eq: $questionid}}}}}, owner_id: {_is_null: true}}) {
      id
      name
      description
    }
  }  
  `

  const ALL_MIXES = gql`
  mutation MyMutation($questionid: uuid!, $mixes: String!) {
    update_SUR_survey_question(where: {id: {_eq: $questionid}}, _set: {mixes: $mixes}) {
      returning {
        mixes
      }
    }
  }
  
  `

  let empty = new Array()
  const isFirstRender = useIsMount();
  const [name, setName] = useState(p.name);
  const [description, setDescription] = useState(p.description);
  const [type, setType] = useState(p.type);
  const [required, setRequired] = useState(p.required);
  const [ratingMin, setRatingMin] = useState<number | null>(p.minValue);
  const [ratingMax, setRatingMax] = useState<number | null>(p.maxValue);
  const [validationMode, setValidationMode] = useState<string | null>(
    p.validationMode
  );
  const [validationNumber, setValidationCount] = useState<number | null>(
    p.validationNumber
  );
  const [validationErrorMessage, setValidationErrorMessage] = useState<string | null>(p.validationErrorMessage);
  const {data: allthemixes, loading: loading, refetch: refetchallthemixes} = useQuery(GET_MIXES, {fetchPolicy: "network-only", variables: {questionid: p.id}})
  const [questionMixes, setQuestionMixes] = useState(empty);
  const [allMixes] = useMutation(ALL_MIXES)
  const displaySelectOptions = type.startsWith("select_");
  const displayValidationOptions =
    type.startsWith("select_color") || type.startsWith("select_checkbox");
  const displayRatingOptions = type.startsWith("rating_");

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if(p.mixes && p.id && allthemixes){

      // question mixes
      let thismixarray = Array()
      thismixarray = p.mixes.split(',')
      setQuestionMixes(thismixarray)


      // all the mixes
      refetchallthemixes({questionid: p.id})

    }
  }, [p.mixes, allthemixes]);

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        console.log(`changed detected on question: ${name}`);
        p.onQuestionUpdated({
          id: p.id,
          name: name,
          description: description,
          type: type,
          required: required,
          minValue: ratingMin,
          maxValue: ratingMax,
          validationMode: validationMode,
          validationNumber: validationNumber,
          validationErrorMessage: validationErrorMessage
        });
      }else{

      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    100,
    [
      name,
      description,
      type,
      required,
      ratingMin,
      ratingMax,
      validationMode,
      validationNumber,
      validationErrorMessage
    ]
  );

  if (loading) {
    return (<div>loading data</div>)
  }

  const allSniffs = (ev:any) => {

    let zmixes = new Array()


    allthemixes.MIX_mix.map((zmix: any) => {
      console.log(zmix.id.toString())
      zmixes.push(zmix.id.toString())
      console.log(zmixes)
      if (allthemixes.MIX_mix.length == zmixes.length) {
        allMixes({variables: {
          questionid: p.id,
          mixes: `${zmixes.join(",")}`,
        }}).then ((resp) => {
          if (resp.data){
            console.log(resp.data)
            setQuestionMixes(zmixes)
            refetchallthemixes({questionid: p.id})
          }
        }).catch(e => {
          console.log('Failed to add sniffs');
          console.log(e);         
        })
      }
    })
  }

  const someSniffs = (ev:any) => {
    allMixes({variables: {
      questionid: p.id,
      mixes: null,
    }}).then ((resp) => {
      if (resp.data){
          console.log(resp.data)
          setQuestionMixes(new Array())
          refetchallthemixes({questionid: p.id})
      }
    }).catch(e => {
      console.log('Failed to add sniffs');
      console.log(e);         
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let target = e.target;
    let value = target.value;

    if (e.target.checked) {
      //add to
      console.log(questionMixes)
      console.log(value)
      questionMixes.push(value.toString())
      allMixes({variables: {
        questionid: p.id,
        mixes: `${questionMixes.join(",")}`,
      }}).then ((resp) => {
        if (resp.data){
            console.log(resp.data)
            //setQuestionMixes(questionMixes)
            //refetchallthemixes({questionid: p.id})
            
        }
      }).catch(e => {
        console.log('Failed to add sniffs');
        console.log(e);         
      })  

    } else {
      //delete from
      alert("delete mix")
      const index = questionMixes.indexOf(value)
      if (index > -1) {
        questionMixes.splice(index, 1);
        allMixes({variables: {
          questionid: p.id,
          mixes: `${questionMixes.join(",")}`,
        }}).then ((resp) => {
          if (resp.data){
              console.log(resp.data)
              //setQuestionMixes(questionMixes)
              //refetchallthemixes({questionid: p.id})
              
          }
        }).catch(e => {
          console.log('Failed to add sniffs');
          console.log(e);         
        })          
      }
    }
  };

  return (
    <SurveyCard style={{ border: '0px' }}>
      <Accordion style={{ marginTop: '15px', border: '1px solid gray' }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
        <Typography>{`QUESTION ${p.index + 1}`}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'block'}}>
      <CardContent>
        { allthemixes.MIX_mix.length > 0 &&
        <Box display="flex" flexDirection="row" style={{ marginBottom: '50px'}}>
          <Box marginRight={1} width={"100%"}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                color="primary"             
              >
                <FormControlLabel 
                  checked={questionMixes.length == allthemixes.MIX_mix.length}
                  control={<Radio 
                    color="primary"
                    onChange={(ev) => allSniffs(ev)}
                  />} 
                  style={{marginRight: 30}}
                  label="Question linked to all sniffs" />
                <FormControlLabel 
                  checked={questionMixes.length != allthemixes.MIX_mix.length}
                  value="some" 
                  control={<Radio 
                    color="primary"
                    onChange={(ev) => someSniffs(ev)}
                  />} 
                  style={{marginRight: 10}}
                  label="Question is linked to these sniffs:" />
              </RadioGroup>
            </FormControl>
            {questionMixes.length != allthemixes.MIX_mix.length && 
            <FormControl style={{ margin: 1, width: 300, marginLeft: 30}} >
              <FormLabel component="legend">Select Sniffs</FormLabel>
              <FormGroup>
              {allthemixes.MIX_mix.map((mix: any) => (
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={questionMixes.includes(mix.id)} onChange={handleChange} value={mix.id} />
                }
                label={mix.name + " - " + mix.description}
              />
              ))}
              </FormGroup>
            </FormControl> 
            }         
          </Box>
        </Box>
        }
        <Box display="flex" flexDirection="row">
          <Box flexGrow={2} marginRight={1}>
            <TextField
              fullWidth
              label={`Question ${p.index + 1}`}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box marginLeft={1} width={"200px"}>
            <QuestionTypeSelect value={type} onChange={setType} />
          </Box>
        </Box>
        <TextField
          fullWidth
          label="Short Version for Comparison Screen"
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {displaySelectOptions ? (
          <Box marginTop={2}>
            <Options
              type={type}
              options={p.options}
              onOptionCreated={p.onOptionCreated}
              onOptionDeleted={p.onOptionDeleted}
              onOptionUpdated={p.onOptionUpdated}
            />
          </Box>
        ) : null}
        {displayValidationOptions ? (
          <Box marginTop={2}>
            <SelectionOptions
              validationMode={validationMode}
              validationCount={validationNumber}
              validationErrorMessage={validationErrorMessage}
              onValidationModeChange={(mode) => setValidationMode(mode)}
              onValidationCountChange={(count) => setValidationCount(count)}
              onValidationErrorMessageChange={(message) => setValidationErrorMessage(message)}
            />
          </Box>
        ) : null}
        {displayRatingOptions ? (
          <Box marginTop={2}>
            <RatingOptionsNumeric
              min={ratingMin}
              max={ratingMax}
              onMinChange={setRatingMin}
              onMaxChange={setRatingMax}
            />
          </Box>
        ) : null}
        <Box paddingTop={2}>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button disabled={p.isFirst} onClick={() => p.onQuestionMoved("up")}>
            move up
          </Button>
          <Button disabled={p.isLast} onClick={() => p.onQuestionMoved("down")}>
            move down
          </Button>
          <IconButton onClick={p.onQuestionDeleted} aria-label="delete">
            <FontAwesomeIcon icon={faTrash} fixedWidth />
          </IconButton>
          <FormControlLabel
            control={
              <Switch
                checked={required}
                onChange={(e, checked) => {
                  setRequired(checked);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="Required"
            labelPlacement="start"
          />
        </Box>
      </CardContent>
    </AccordionDetails>
    </Accordion>
    </SurveyCard>
  );
}
