import { Button } from "@material-ui/core";
import { max } from "lodash";
import React from "react";
import { mapApollo } from "../../../../util/ApolloLoadable";
import ApolloRouter from "../../../../util/ApolloRouter";
import SurveyForm, { SurveyFormDataProps } from "../components/SurveyForm";
import { ControlPoint } from "@material-ui/icons";
import {
  SurveyDetailsData,
  useSurveyDetailsQuery,
} from "../queries/SurveyDetails";
import { v4 as uuidv4 } from "uuid";
import { useInsertQuestionMutation } from "../queries/InsertQuestion";
import { useDeleteQuestionMutation } from "../queries/DeleteQuestion";
import { QuestionUpdate } from "../components/QuestionCard";
import { useUpdateQuestionMutation } from "../queries/UpdateQuestion";
import { QuestionOption } from "../components/Options";
import { useInsertOptionMutation } from "../queries/InsertOption";
import { useUpdateOptionMutation } from "../queries/UpdateOption";
import { useDeleteOptionMutation } from "../queries/DeleteOption";
import { useUpdateSurveyInfoMutation } from "../queries/UpdateSurveyInfo";
import { useSwapQuestionsMutation } from "../queries/SwapQuestions";

export interface EditSurveyProps {
  surveyId: string;
}

export default function EditSurvey(p: EditSurveyProps) {
  const query = useSurveyDetailsQuery({
    variables: { section_id: p.surveyId },
    fetchPolicy: "no-cache",
  });
  const [insertQuestion] = useInsertQuestionMutation();
  const [deleteQuestion] = useDeleteQuestionMutation();
  const [updateQuestion] = useUpdateQuestionMutation();
  const [swapQuestions] = useSwapQuestionsMutation();
  const [updateSurveyInfo] = useUpdateSurveyInfoMutation();
  const [insertOption] = useInsertOptionMutation();
  const [updateOption] = useUpdateOptionMutation();
  const [deleteOption] = useDeleteOptionMutation();

  const handleInformationChange = (
    internalName: string,
    name: string,
    description: string
  ) => {
    updateSurveyInfo({
      variables: {
        survey_id: p.surveyId,
        internal_name: internalName,
        name: name,
        description: description,
      },
    });
  };

  const handleAddQuestion = () => {
    const highestSortPosition =
      max(
        (query.data?.SUR_section_by_pk.SUR_survey_questions || []).map(
          (q) => q.sort_position
        )
      ) || 0;
    const newSortPosition =
      highestSortPosition + 1000 + Math.round(Math.random() * 100);

    insertQuestion({
      variables: {
        id: uuidv4(),
        name: "",
        sort_position: newSortPosition,
        type: "text_short",
        survey_section_ID: p.surveyId
      },
      refetchQueries: ["SurveyDetails"],
    });
  };

  const handleDeleteQuestion = (questionId: string) => {
    deleteQuestion({
      variables: {
        question_id: questionId,
      },
      refetchQueries: ["SurveyDetails"],
    });
  };

  const handleUpdateQuestion = (update: QuestionUpdate) => {
    updateQuestion({
      variables: {
        question_id: update.id,
        name: update.name,
        description: update.description || null,
        required: update.required,
        type: update.type,
        min_value: update.minValue,
        max_value: update.maxValue,
        validation_mode: update.validationMode,
        validation_count: update.validationNumber,
        validation_error_message: update.validationErrorMessage
      },
      refetchQueries: ["SurveyDetails"],
    });
  };

  const handleSwapQuestion = (questionAId: string, questionBId: string) => {
    swapQuestions({
      variables: {
        questionAId: questionAId,
        questionBId: questionBId
      },
      refetchQueries: ["SurveyDetails"]
    })
  }

  const handleOptionCreated = (option: QuestionOption, questionId: string) => {
    const getHighestSortPosition = () => {
      const question = query.data?.SUR_section_by_pk.SUR_survey_questions?.find(
        (q) => q.id === questionId
      );
      if (question == null) {
        return 0;
      }
      return max(question.options.map((o) => o.sort_position)) || 0;
    };

    insertOption({
      variables: {
        question_id: questionId,
        option_id: option.id,
        sort_position:
          getHighestSortPosition() + 1000 + Math.round(Math.random() * 100),
        text: option.text,
      },
      refetchQueries: ["SurveyDetails"],
    });
  };

  const handleOptionUpdated = (option: QuestionOption) => {
    updateOption({
      variables: {
        option_id: option.id,
        text: option.text,
      },
      refetchQueries: ["SurveyDetails"],
    });
  };

  const handleOptionDeleted = (optionId: string) => {
    deleteOption({
      variables: {
        option_id: optionId,
      },
      refetchQueries: ["SurveyDetails"],
    });
  };

  return (
    <ApolloRouter
      loadable={mapApollo(query, mapQueryData)}
      ready={(data) => (
        <React.Fragment>
          <SurveyForm 
            onInformationChange={handleInformationChange}
            onDeleteQuestion={handleDeleteQuestion}
            onUpdateQuestion={handleUpdateQuestion}
            onSwapQuestion={handleSwapQuestion}
            onOptionCreated={handleOptionCreated}
            onOptionUpdated={handleOptionUpdated}
            onOptionDeleted={handleOptionDeleted}
            {...data}
          />
          <Button onClick={handleAddQuestion} style={{color: 'white', backgroundColor: 'darkblue', width: 400, marginTop: 20}} variant="outlined" >
            Add Question
          </Button>  
        </React.Fragment>
      )}
    />
  );
}

function mapQueryData(data: SurveyDetailsData): SurveyFormDataProps {
  return {
    id: "",
    internalName:"",
    name: "",
    description: "",
    questions: data.SUR_section_by_pk.SUR_survey_questions?.map((q, index) => ({
      id: q.id,
      index: index,
      isFirst: index === 0,
      isLast: index === data.SUR_section_by_pk.SUR_survey_questions.length - 1,
      name: q.name,
      description: q.description || "",
      required: q.required,
      type: q.type,
      minValue: q.min_value,
      maxValue: q.max_value,
      validationMode: q.validation_mode,
      validationNumber: q.validation_count,
      validationErrorMessage: q.validation_error_message,
      mixes: q.mixes || "",
      options: q.options.map((option) => ({
        id: option.id,
        text: option.text,
      })),
    })),
  };
}
