import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const MUT = gql`
mutation deleteVideo($id: uuid!) {
  delete_app_training_videos_by_pk(id: $id) {
    id
  }
}
`;

export interface DeleteSurveyAttachmentData {
  delete_app_training_videos_by_pk: {
    id: string;
  }
}

export interface DeleteSurveyAttachmentVariables {
  id: string;
}

export function useDeleteSurveyAttachmentMutation(
  options?:
    | MutationHookOptions<DeleteSurveyAttachmentData, DeleteSurveyAttachmentVariables>
    | undefined
) {
  return useMutation<DeleteSurveyAttachmentData, DeleteSurveyAttachmentVariables>(
    MUT,
    options
  );
}
