import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const createUserQuery = gql`
mutation RegisterUserSimple($email: String!, $name: String!, $password: String!) {
  app_user_register(email: $email, name: $name, display_name: $display_name, password: $password) {
    success
    user {
      projects {
        project_customers {
          app_user {
            id
            email
          }
        }
      }
    id
    }
  }
}
`;

export interface CreateUserData {
  insert_app_user_one: {
    id: string;
    created_at: Date;
  };
}

export interface CreateUserVariables {
  display_name: string;
  secret: string;
}

export function useCreateUser(
  options?: MutationHookOptions<CreateUserData, CreateUserVariables> | undefined
) {
  return useMutation<CreateUserData, CreateUserVariables>(
    createUserQuery,
    options
  );
}
