import { gql, useApolloClient } from "@apollo/client";

const QUERY = gql`
  query CheckIfUserExists($id: uuid!) {
    app_user_by_pk(id: $id) {
      id
    }
  }
`;

export function useCheckUserExists() {
  const client = useApolloClient();
  const checkUserExists = async (userId: string) => {
    console.log(`Checking if user ${userId} exists`)
    const { data } = await client.query<
      { app_user_by_pk: null | { id: string } },
      { id: string }
    >({
      query: QUERY,
      variables: {
        id: userId,
      },
    });
    return data.app_user_by_pk?.id != null;
  };
  return [checkUserExists]
}
