import React from "react";
import { css } from "@emotion/react";
import logoImage from "./g.png";

interface LogoProps {
  height?: number;
}

export default function Logo(props: LogoProps) {
  const height = props.height || 40;

  const cssParent = css`
    display: flex;
    font: "Fedra Serif"
    vertical-align: middle;
    height: ${height}px;
    user-select: none;
    padding: 2px;
    background-color: #4A1690;
  `;
  const cssImage = css`
    max-width: 100%;
    max-height: 100%;
    margin-right: 5px;
  `;

  const cssAI = css`
    color: white;
    font-size: ${height*.75}px;
    line-height: ${height*1}px;
    font-weight: 200;
  `;
  const cssRomi = css`
    color: white;
    font-size: ${height*.75}px;
    line-height: ${height*1}px;
    font-weight: 400;
  `;

  return (
    <div css={cssParent}>
      <span css={cssAI}>AI&nbsp;</span>
      <span css={cssRomi}>Romi</span>
    </div>
  );
};
