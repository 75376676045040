import { gql } from "@apollo/client";

export const getSniffData =  gql`
query getSniffData($sniffid: uuid!) {
  project(where: {id: {_eq: $sniffid}}) {
    id
    image
    play_around_type
    preference_negative
    preference_positive
    preference_question
    sniff_and_rate_description
    session_type
    name
    description
    sniff_test_length
    sniff_rating_scale
    random_mixes
    clt
    channels(order_by: {channel_index: asc}) {
      channel_index
      description
      name
      image
      duration
      id
      enabled
      channel_type
    }
    mixes(order_by: {sort_position: asc}, where: {owner_id: {_is_null: true}}) {
      channels
      name
      id
      description
      sort_position
      comment
      owner {
        name
      }
    }
    survey_id
    survey {
      name
      internal_name
      id
      description
      SUR_sections(order_by: {sort_position: asc}) {
        ID
        title
        description
        image
        survey_id
        sort_position
        popin_info
        SUR_survey_questions(order_by: {sort_position: asc}) {
          description
          id
          max_value
          min_value
          mixes
          name
          options {
            id
            question_id
            sort_position
            text
          }
        }
      }
    }
    project_customers(order_by: {app_user: {email: asc}}) {
      app_user {
        email
        id
      }
    }
    project_consumers(order_by: {app_user: {email: asc}}) {
      app_user {
        email
        id
      }
    }
    project_guided_popins {
      popin_one
      popin_two
      popin_three
    }
  }
}
`

export const nukeClt = gql`
mutation nukeClt {
  update_project(where: {}, _set: {clt: false}) {
    returning {
      id
    }
  }
}
`

export const updatePopinOne = gql`
mutation updatePopinOne($popin_one: String, $project_id: uuid!) {
  update_project_guided_popins(where: {project_id: {_eq: $project_id}}, _set: {popin_one: $popin_one}) {
    returning {
      popin_one
    }
  }
}
`

export const updatePopinTwo = gql`
mutation updatePopinOne($popin_two: String, $project_id: uuid!) {
  update_project_guided_popins(where: {project_id: {_eq: $project_id}}, _set: {popin_two: $popin_two}) {
    returning {
      popin_one
    }
  }
}
`

export const updatePopinThree = gql`
mutation updatePopinOne($popin_three: String, $project_id: uuid!) {
  update_project_guided_popins(where: {project_id: {_eq: $project_id}}, _set: {popin_three: $popin_three}) {
    returning {
      popin_one
    }
  }
}
`

export const updateGeneralInfo = gql`
mutation updateGeneralInfo($id: uuid, $image: String, $play_around_type: String, $preference_negative: String, $preference_positive: String, $preference_question: String, $session_type: String, $name: String, $sniff_and_rate_description:String, $description: String, $sniff_test_length: Int, $sniff_rating_scale: Int, $random_mixes: Boolean, $clt: Boolean  ) {
  update_project(where: {id: {_eq: $id}}, _set: {image: $image, play_around_type: $play_around_type, preference_negative: $preference_negative, preference_positive: $preference_positive, preference_question: $preference_question, session_type: $session_type, name: $name, sniff_and_rate_description: $sniff_and_rate_description, description: $description, sniff_test_length: $sniff_test_length, sniff_rating_scale: $sniff_rating_scale, random_mixes: $random_mixes, clt: $clt}) {
    returning {
      id
      image
      play_around_type
      preference_negative
      preference_positive
      preference_question
      sniff_and_rate_description
      session_type
      name
      description
      sniff_test_length
      sniff_rating_scale
      random_mixes
      clt
      channels(order_by: {channel_index: asc}) {
        channel_index
        description
        name
        image
        duration
        id
        enabled
        channel_type
      }
      mixes(order_by: {sort_position: asc}, where: {owner_id: {_is_null: true}}) {
        channels
        name
        id
        description
        sort_position
        comment
        owner {
          name
        }
      }
      survey_id
      survey {
        name
        internal_name
        id
        description
        SUR_sections(order_by: {sort_position: asc}) {
          ID
          title
          description
          image
          survey_id
          sort_position
          popin_info
          SUR_survey_questions(order_by: {sort_position: asc}) {
            description
            id
            max_value
            min_value
            mixes
            name
            options {
              id
              question_id
              sort_position
              text
            }
          }
        }
      }
      project_customers {
        app_user {
          email
          id
        }
      }
      project_consumers {
        app_user {
          email
          id
        }
      }
      project_guided_popins {
        popin_one
        popin_two
        popin_three
      }
    }
  }
}
`

export const updateChannels = gql`
mutation updateChannels($channelid: uuid!, $channelname: String, $duration: Int!, $description: String, $channel_type: String) {
  update_project_channel(where: {id: {_eq: $channelid}}, _set: {name: $channelname, duration: $duration, description: $description, channel_type: $channel_type}) {
    returning {
        project {
          id
          image
          play_around_type
          preference_negative
          preference_positive
          preference_question
          sniff_and_rate_description
          session_type
          name
          description
          sniff_test_length
          sniff_rating_scale
          random_mixes
          clt
          project_guided_popins {
            popin_one
            popin_two
            popin_three
          }
          channels(order_by: {channel_index: asc}) {
            channel_index
            description
            name
            image
            duration
            id
            enabled
            channel_type
          }
          mixes(order_by: {sort_position: asc}, where: {owner_id: {_is_null: true}}) {
            channels
            name
            id
            description
            sort_position
            comment
            owner {
              name
            }
          }
          survey_id
          survey {
            name
            internal_name
            id
            description
            SUR_sections(order_by: {sort_position: asc}) {
              ID
              title
              description
              image
              survey_id
              sort_position
              popin_info
              SUR_survey_questions(order_by: {sort_position: asc}) {
                description
                id
                max_value
                min_value
                mixes
                name
                options {
                  id
                  question_id
                  sort_position
                  text
                }
              }
            }
          }          
        }
    }
  }
}
`

export const addSniff = gql`
mutation addSniff($project_id: uuid!, $description: String!, $sort_position: Int!) {
  insert_MIX_mix_one(object: {name: "NEW SNIFF", description: $description, project_id: $project_id, sort_position: $sort_position, channels: "{0,0,0,0,0,0,0,0}"}) {
    id
    project {
      id
      image
      play_around_type
      preference_negative
      preference_positive
      preference_question
      sniff_and_rate_description
      session_type
      name
      description
      sniff_test_length
      sniff_rating_scale
      random_mixes
      clt
      project_guided_popins {
        popin_one
        popin_two
        popin_three
      }
      channels(order_by: {channel_index: asc}) {
        channel_index
        description
        name
        image
        duration
        id
        enabled
        channel_type
      }
      mixes(order_by: {sort_position: asc}, where: {owner_id: {_is_null: true}}) {
        channels
        name
        id
        description
        sort_position
        comment
        owner {
          name
        }
      }
      survey_id
      survey {
        name
        internal_name
        id
        description
        SUR_sections(order_by: {sort_position: asc}) {
          ID
          title
          description
          image
          survey_id
          sort_position
          popin_info
          SUR_survey_questions(order_by: {sort_position: asc}) {
            description
            id
            max_value
            min_value
            mixes
            name
            options {
              id
              question_id
              sort_position
              text
            }
          }
        }
      }
    }
  }
}
`
export const deletesniff = gql`
mutation deleteSniff($id: uuid!) {
  delete_MIX_mix(where: {id: {_eq: $id}}) {
    returning {
        project {
        id
        image
        play_around_type
        preference_negative
        preference_positive
        preference_question
        sniff_and_rate_description
        session_type
        name
        description
        sniff_test_length
        sniff_rating_scale
        random_mixes
        clt
        project_guided_popins {
          popin_one
          popin_two
          popin_three
        }
        channels(order_by: {channel_index: asc}) {
          channel_index
          description
          name
          image
          duration
          id
          enabled
          channel_type
        }
        mixes(order_by: {sort_position: asc}, where: {owner_id: {_is_null: true}}) {
          channels
          name
          id
          description
          sort_position
          comment
          owner {
            name
          }
        }
        survey_id
        survey {
          name
          internal_name
          id
          description
          SUR_sections(order_by: {sort_position: asc}) {
            ID
            title
            description
            image
            survey_id
            sort_position
            popin_info
            SUR_survey_questions(order_by: {sort_position: asc}) {
              description
              id
              max_value
              min_value
              mixes
              name
              options {
                id
                question_id
                sort_position
                text
              }
            }
          }
        }          
      }
    }
  }
}

`
export const duplicateSniff = gql`
mutation addSniff($project_id: uuid!, $name: String, $description: String, $channels: _int4, $sort_position: Int) {
  insert_MIX_mix_one(object: {name: $name, description: $description, project_id: $project_id, channels: $channels, sort_position: $sort_position}) {
    id
    project {
      id
      image
      play_around_type
      preference_negative
      preference_positive
      preference_question
      sniff_and_rate_description
      session_type
      name
      description
      sniff_test_length
      sniff_rating_scale
      random_mixes
      clt
      project_guided_popins {
        popin_one
        popin_two
        popin_three
      }
      channels(order_by: {channel_index: asc}) {
        channel_index
        description
        name
        image
        duration
        id
        enabled
        channel_type
      }
      mixes(order_by: {sort_position: asc}, where: {owner_id: {_is_null: true}}) {
        channels
        name
        id
        description
        sort_position
        comment
        owner {
          name
        }
      }
      survey_id
      survey {
        name
        internal_name
        id
        description
        SUR_sections(order_by: {sort_position: asc}) {
          ID
          title
          description
          image
          survey_id
          sort_position
          popin_info
          SUR_survey_questions(order_by: {sort_position: asc}) {
            description
            id
            max_value
            min_value
            mixes
            name
            options {
              id
              question_id
              sort_position
              text
            }
          }
        }
      }
    }
  }
}
`

export const updateSniffTestSettings = gql`
mutation updateSniff($sniff_id: uuid!, $channels: _int4!, $name: String, $description: String) {
  update_MIX_mix(where: {id: {_eq: $sniff_id}}, _set: {channels: $channels, name: $name, description: $description}) {
    returning {
      project {
      id
      image
      play_around_type
      preference_negative
      preference_positive
      preference_question
      sniff_and_rate_description
      session_type
      name
      description
      sniff_test_length
      sniff_rating_scale
      random_mixes
      clt
      project_guided_popins {
        popin_one
        popin_two
        popin_three
      }
      channels(order_by: {channel_index: asc}) {
        channel_index
        description
        name
        image
        duration
        id
        enabled
        channel_type
      }
      mixes(order_by: {sort_position: asc}, where: {owner_id: {_is_null: true}}) {
        channels
        name
        id
        description
        sort_position
        comment
        owner {
          name
        }
      }
      survey_id
      survey {
        name
        internal_name
        id
        description
        SUR_sections(order_by: {sort_position: asc}) {
          ID
          title
          description
          image
          survey_id
          sort_position
          popin_info
          SUR_survey_questions(order_by: {sort_position: asc}) {
            description
            id
            max_value
            min_value
            mixes
            name
            options {
              id
              question_id
              sort_position
              text
            }
          }
        }
      }          
      }
    }
  }
}
`

export const addSection = gql`
mutation addSection($survey_id: uuid!, $sort_position: Int) {
  insert_SUR_section(objects: {survey_id: $survey_id, sort_position: $sort_position}) {
    returning {
      SUR_survey {
        project {
        id
        image
        play_around_type
        preference_negative
        preference_positive
        preference_question
        sniff_and_rate_description
        session_type
        name
        description
        sniff_test_length
        sniff_rating_scale
        random_mixes
        clt
        project_guided_popins {
          popin_one
          popin_two
          popin_three
        }
        channels(order_by: {channel_index: asc}) {
          channel_index
          description
          name
          image
          duration
          id
          enabled
          channel_type
        }
        mixes(order_by: {sort_position: asc}, where: {owner_id: {_is_null: true}}) {
          channels
          name
          id
          description
          sort_position
          comment
          owner {
            name
          }
        }
        survey_id
        survey {
          name
          internal_name
          id
          description
          SUR_sections(order_by: {sort_position: asc}) {
              ID
              title
              description
              image
              survey_id
              sort_position
              popin_info
            SUR_survey_questions(order_by: {sort_position: asc}) {
              description
              id
              max_value
              min_value
              mixes
              name
              options {
                id
                question_id
                sort_position
                text
              }
            }
          }
        }          
        }
      }
    }
  }
}
`

export const deleteSection = gql`
mutation deleteSection($section_id: uuid!) {
  delete_SUR_section(where: {ID: {_eq: $section_id}}) {
    returning {
        SUR_survey {
          project {
          id
          image
          play_around_type
          preference_negative
          preference_positive
          preference_question
          sniff_and_rate_description
          session_type
          name
          description
          sniff_test_length
          sniff_rating_scale
          random_mixes
          clt
          project_guided_popins {
            popin_one
            popin_two
            popin_three
          }
          channels(order_by: {channel_index: asc}) {
            channel_index
            description
            name
            image
            duration
            id
            enabled
            channel_type
          }
          mixes(order_by: {sort_position: asc}, where: {owner_id: {_is_null: true}}) {
            channels
            name
            id
            description
            sort_position
            comment
            owner {
              name
            }
          }
          survey_id
          survey {
            name
            internal_name
            id
            description
            SUR_sections(order_by: {sort_position: asc}) {
              ID
              title
              description
              image
              survey_id
              sort_position
              popin_info
              SUR_survey_questions(order_by: {sort_position: asc}) {
                description
                id
                max_value
                min_value
                mixes
                name
                options {
                  id
                  question_id
                  sort_position
                  text
                }
              }
            }
          }          
          }
        }
    }
  }
}
`

export const updateSections = gql`
mutation updateSections($sectionid: uuid!, $image: String, $description: String, $title: String, $popin_info: String) {
  update_SUR_section(where: {ID: {_eq: $sectionid}}, _set: {image: $image, description: $description, title: $title, popin_info: $popin_info}) {
    returning {
      SUR_survey {
        project {
          id
          image
          play_around_type
          preference_negative
          preference_positive
          preference_question
          sniff_and_rate_description
          session_type
          name
          description
          sniff_test_length
          sniff_rating_scale
          random_mixes
          clt
          project_guided_popins {
            popin_one
            popin_two
            popin_three
          }
          channels(order_by: {channel_index: asc}) {
            channel_index
            description
            name
            image
            duration
            id
            enabled
            channel_type
          }
          mixes(order_by: {sort_position: asc}, where: {owner_id: {_is_null: true}}) {
            channels
            name
            id
            description
            sort_position
            comment
            owner {
              name
            }
          }
          survey_id
          survey {
            name
            internal_name
            id
            description
            SUR_sections(order_by: {sort_position: asc}) {
              ID
              title
              description
              image
              survey_id
              sort_position
              popin_info
              SUR_survey_questions(order_by: {sort_position: asc}) {
                description
                id
                max_value
                min_value
                mixes
                name
                options {
                  id
                  question_id
                  sort_position
                  text
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const codeSearch = gql`
query codeLookup($code: String!) {
  MIX_mix(where: {description: {_eq: $code}}) {
    description
  }
}  
`

export const EMAIL_LOOKUP = gql`
query emailLookup($email: String) {
    app_user(where: {email: {_eq: $email}}) {
      id
      email
    }
  }
`

export const ADD_CUSTOMER = gql`
mutation insertProjectCustomer($user_id: uuid!, $project_id: uuid!) {
  insert_project_customers(objects: {user_id: $user_id, project_id: $project_id}) {
    returning {
      project {
        project_customers {
          app_user {
            id
            email
          }
        }
        channels {
          id
          duration
        }
      }
      project_id
    }
  }
}
`

export const ADD_OIL_USER = gql`
mutation addOilUser($app_user_id: uuid!, $channel_id: uuid!, $oil_level: Int!, $project_id: uuid!) {
  insert_project_channel_oil(objects: {app_user_id: $app_user_id, channel_id: $channel_id, oil_level: $oil_level, project_id: $project_id}) {
    returning {
      app_user_id
      channel_id
      id
      oil_level
      project_id
      project {
        project_customers(order_by: {app_user: {email: asc}}) {
          app_user {
            email
            id
          }
        }
        project_consumers(order_by: {app_user: {email: asc}}) {
          app_user {
            email
            id
          }
        }
      }
    }
  }
}
`

export const NO_ADMIN = gql`
mutation noAdmin($userid: uuid) {
  update_app_user(where: {id: {_eq: $userid}}, _set: {is_admin: false}) {
    returning {
      display_name
      is_admin
    }
  }
}
`

export const DELETE_CUSTOMER = gql`
mutation deleteProjectCustomer($user_id: uuid!, $project_id: uuid!) {
  delete_project_customers(where: {project_id: {_eq: $project_id}, user_id: {_eq: $user_id}}) {
    returning {
      project {
        project_customers {
          app_user {
            email
            id
          }
        }
      }
    }
  }
  delete_project_channel_oil(where: {app_user_id: {_eq: $user_id}, project: {id: {_eq: $project_id}}}) {
    returning {
      app_user_id
      project_id
    }
  }
}

`

export const ADD_CONSUMER = gql`
mutation insertProjectConsumer($user_id: uuid!, $project_id: uuid!) {
  insert_project_consumers(objects: {user_id: $user_id, project_id: $project_id}) {
    returning {
      project {
        project_customers {
          app_user {
            id
            email
          }
        }
        channels {
          id
          duration
        }
      }
      project_id
    }
  }
}
`

export const DELETE_CONSUMER = gql`
mutation deleteProjectConsumer($user_id: uuid!, $project_id: uuid!) {
  delete_project_consumers(where: {project_id: {_eq: $project_id}, user_id: {_eq: $user_id}}) {
    returning {
      project {
        project_consumers {
          app_user {
            email
            id
          }
        }
      }
    }
  }
  delete_project_channel_oil(where: {app_user_id: {_eq: $user_id}, project: {id: {_eq: $project_id}}}) {
    returning {
      app_user_id
      project_id
    }
  }
}
`

export const ADD_PLACEHOLDER = gql`
mutation RegisterUserSimple($email: String!, $name: String!, $password: String!) {
  app_user_register(email: $email, name: $name, password: $password) {
    success
    user {
      projects {
        project_customers {
          app_user {
            id
            email
          }
        }
      }
    id
    }
  }
}
`

export const SWAP_SECTION = gql`
mutation updateSectionSort($sectionid: uuid!, $sort_position: Int!) {
    update_SUR_section(where: {ID: {_eq: $sectionid}}, _set: {sort_position: $sort_position}) {
      returning {
        SUR_survey {
            SUR_sections(order_by: {sort_position: asc}) {
              ID
              title
              description
              image
              survey_id
              sort_position
              SUR_survey_questions(order_by: {sort_position: asc}) {
                description
                id
                max_value
                min_value
                mixes
                name
                options {
                  id
                  question_id
                  sort_position
                  text
                }
              }
            }
          } 
        }
      }
    }
`

export const GetChannelName = gql`
query getChannelData($project_id: uuid) {
  project_channel(where: {project_id: {_eq: $project_id}}, order_by: {channel_index: asc}) {
    name
    channel_index
  }
}
`

export const SWAP_MIX = gql`
mutation updateMixSort($mixid: uuid!, $sort_position: Int!) {
  update_MIX_mix(where: {id: {_eq: $mixid}}, _set: {sort_position: $sort_position}) {
    returning {
      project {
        mixes(order_by: {sort_position: asc}) {
          sort_position
          id
          name
          description
          channels
        }
      }
    }
  }
}
`