import React from "react";
import { css } from "@emotion/react";
import { Box, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const cssParent = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: white;
`;

const styles = {
  image: css`
    width: 100%;
    height: auto;
  `
}



export default function Welcome() {
  const history = useHistory();
  return (
    <div css={cssParent}>
      <div
        css={css`
          background-color: white;
        `}
      >
        <Typography variant="h3" color="primary">Access denied</Typography>
        <Typography variant="subtitle1" color="primary">
          Please log in as an administrator to access this page.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/logout");
          }}
        >
          Log out
        </Button>
      </div>
    </div>
  );
}
