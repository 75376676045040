import React, { useState } from "react";
import { css } from "@emotion/react";
import { Button, Typography } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { gql, useMutation } from "@apollo/client";

const cssParent = css``;

const MUT_UPLOAD = gql`
mutation UploadBase64File($base64Encoded: String!, $fileName: String!, $mimeType: String! = "") {
  uploadBase64File(base64Encoded: $base64Encoded, fileName: $fileName, mimeType: $mimeType) {
    url
    success
  }
}
`;

enum State {
  idle,
  error,
  error_too_big,
  reading,
  uploading,
  complete,
}

const Messages = {
  [State.idle]: "Ready",
  [State.error]: "An error occurred",
  [State.error_too_big]: "Files cannot be larger than 50mb",
  [State.reading]: "Reading file...",
  [State.uploading]: "Uploading file...",
  [State.complete]: "Upload complete",
};

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface FileUploadProps {
  onSuccess: (fileName: string, url: string) => void;
}

const FileUpload = (p: FileUploadProps) => {
  const [state, setState] = useState(State.idle);
  const [upload] = useMutation(MUT_UPLOAD);

  const handleFileDropped = (files: File[]) => {
    setState(State.reading);

    const file = files[0];
    if (file.size > 50 * 1024 * 1024) {
      setState(State.error_too_big);
      return;
    }

    toBase64(file)
      .then((base64) => {
        console.log(base64)
        console.log(files[0].name)
        setState(State.uploading);
        upload({ variables: { base64Encoded: base64, fileName: files[0].name } }).then((res) => {
          if (res.data.uploadBase64File.success) {
            console.log(res.data)
            setState(State.complete);
            p.onSuccess(files[0].name, res.data.uploadBase64File.url);
          } else {
            setState(State.error);
          }
        });
      })
      .catch((e) => {
        console.log(e)
        setState(State.error);
      });
    console.log(files);
  };
  const busy = state === State.reading || state === State.uploading;
  const message = Messages[state];

  return (
    <div style={{marginTop:70}}>
      <Typography align="center" variant="subtitle1">
        {message}
      </Typography>
      <div>
        {busy ? (
          <Button disabled fullWidth variant="contained">
            Upload
          </Button>
        ) : (
          <Dropzone
            onDrop={handleFileDropped}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button fullWidth color="primary" variant="contained">
                  Upload
                </Button>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
