import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFont,
  faAlignLeft,
  faDotCircle,
  faCheckSquare,
  faCaretSquareDown,
  faStar,
  faHeart,
  faSlidersH,
  faPalette,
  faArrowAltCircleUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  Box,
} from "@material-ui/core";
import React from "react";

interface TypeMenuItemProps {
  icon: IconProp;
  label: string;
}

function TypeMenuItem(p: TypeMenuItemProps) {
  return (
    <React.Fragment>
      <Box paddingRight={1} display="inline">
        <FontAwesomeIcon icon={p.icon} fixedWidth />
      </Box>
      {p.label}
    </React.Fragment>
  );
}

export interface QuestionTypeSelectProps {
  value: string;
  onChange: (newValue: string) => void;
}

export default function QuestionTypeSelect(p: QuestionTypeSelectProps) {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
      <Select
        fullWidth
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={p.value}
        onChange={(e) => p.onChange(e.target.value as string)}
        label="Type"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <MenuItem value={"text_paragraph"}>
          <TypeMenuItem label="Open Answer" icon={faAlignLeft} />
        </MenuItem>
        <MenuItem value={"select_radio"}>
          <TypeMenuItem label="Single Choice" icon={faDotCircle} />
        </MenuItem>
        <MenuItem value={"select_checkbox"}>
          <TypeMenuItem label="Multiple Choice" icon={faCheckSquare} />
        </MenuItem>
        <MenuItem value={"select_color"}>
          <TypeMenuItem label="Colors Grid" icon={faPalette} />
        </MenuItem>
        <MenuItem value={"rating_star"}>
          <TypeMenuItem label="Rating" icon={faStar} />
        </MenuItem>
        <MenuItem value={"select_ranking"}>
          <TypeMenuItem label="Statement Ranking" icon={faArrowAltCircleUp} />
        </MenuItem>
      </Select>
    </FormControl>
  );
}
