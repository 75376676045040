import React, { useState } from "react";
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { css } from "@emotion/react";
import { useMutation } from "@apollo/client";
import { DeleteProjectData, DeleteProjectMutation, DeleteProjectVariables } from "./queries/queries";
import { useHistory } from "react-router-dom";

const styles = {
  backdrop: css`
    z-index: 999,
    color: '#f00',
  `,
};

interface DeleteProjectProps {
  projectId: string;
  surveyId: string
}

export default function DeleteProject(p: DeleteProjectProps) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [deleteProject] = useMutation<DeleteProjectData, DeleteProjectVariables>(DeleteProjectMutation, {refetchQueries: ["GetProjects"]})

  const redirectToList = () => {
    history.replace("/admin/sessions")
  }
  const handleDeleteClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirmDelete = () => {
    deleteProject({variables: {project_id: p.projectId, survey_id: p.surveyId}}).then(handleClose).catch(e => {
      setError(e.toString())
    });
  }

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleDeleteClick}
        disabled={open}
      >
        Delete
      </Button>
      <Backdrop
        css={styles.backdrop}
        style={{ zIndex: 999 }}
        open={open}
      >
        <Card
          css={css`
            max-width: 300px;
          `}
        >
          <CardContent>
            <Typography variant="h6">Delete Test</Typography>
            <Typography variant="body2">
              Are you sure you want to delete this entire test? This action cannot be reversed.
            </Typography>
            {error ? <Typography variant="body2">
              Error deleting: {error}
            </Typography> : null}
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button variant="outlined" color="secondary" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </CardActions>
        </Card>
      </Backdrop>
    </React.Fragment>
  );
}
