import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Box,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputAdornment,
  Link
} from "@material-ui/core";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import DeleteProject from "./DeleteProject";
import _, { sortBy } from "lodash";
import ApolloRouter from "../../../util/ApolloRouter";
import { Search, ControlPoint, Brightness1 } from "@material-ui/icons";
import { useListProjects } from "./queries/ListProjects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { InputLabel, NativeSelect } from '@material-ui/core';
import { useCreateNewProject, createGuidedPopins } from "./queries/CreateProject";
import { range } from "lodash";
import { useUserId } from "../../../SessionContext";
import triggerDownload from "js-file-download";
import { useAddSurveyAttachmentMutation } from "./queries/AddSurveyAttachment";
import { useDeleteSurveyAttachmentMutation } from "./queries/DeleteSurveyAttachment";
import LoadableImage from "../components/LoadableImage";
import ImageUpload from "../components/ImageUpload";
import FileUpload from "../components/FileUpload";

const GET_VIDEOS = gql`
query getVideos {
    app_training_videos {
      id
      label
      url
    }
  }  
`


const TrainingVideoList = () => {

    const {data: videos, loading: loading, refetch: refetchvideos} = useQuery(GET_VIDEOS, {fetchPolicy: "network-only"})
    const [addSurveyAttachment] = useAddSurveyAttachmentMutation();
    const [deleteSurveyAttachment] = useDeleteSurveyAttachmentMutation();
    const [language, setLanguage] = useState("");

    const handleAttachmentAdded = (
        label: string,
        url: string
      ) => {
        console.log(`handleAttachmentAdded: ${label} ${url}`)
        addSurveyAttachment({
          variables: {
            label,
            url
          },
        }).then((resp) => {
            if (resp.data) {
                refetchvideos()
                console.log(videos)
            }
          }).catch(e => {
            console.log(e);
          })
      }
    
      const handleAttachmentRemoved = (id: string) => {
        console.log(`handleAttachmentRemoved: ${id}`);
        deleteSurveyAttachment({
          variables: {
            id
          },
        }).then((resp) => {
            if (resp.data) {
                refetchvideos()
                console.log(videos)
            }
          }).catch(e => {
            console.log('Failed to delete video');
            console.log(e);
          })
      }

    if (loading) { return (<div>loading data</div>) }

    console.log(videos.app_training_videos)

    return (

        <Box component={Paper} style={{ border: '1px solid gray', borderRadius: '5px', padding: 20 }}>
            <Box marginTop={2}>
              <Typography style={{marginBottom: 40}}>Training Videos</Typography>
              {videos.app_training_videos.map((video: any) => (
                <Box key={video.id} style={{marginBottom: 10}}>
                  <Button variant="outlined" color="secondary" onClick={() => handleAttachmentRemoved(video.id)}>
                    Delete
                  </Button>
                  <a href={video.url} style={{marginLeft: 30}} target="_blank">
                    {video.label}
                  </a>
                </Box>
              ))}
              <FileUpload onSuccess={handleAttachmentAdded} />
            </Box>
        </Box>

    );
};

export default TrainingVideoList;
