import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SniffTestList from "./pages/projects/SniffTestList";
import TrainingVideoList from "./pages/projects/TrainingVideos";
import UserList from "./pages/users/UserList";
import CreateUser from "./pages/users/CreateUser";
import SurveyList from "./pages/surveys/SurveyList";
import CreateSurvey from "./pages/surveys/CreateSurvey";
import ManageSurveyPage from "./pages/surveys/ManageSurveyPage";
import { NewSniffTest } from "./pages/projects/NewSession";
import { EditSniffTest } from "./pages/projects/[id]";

export default function Routes() {
  return (
    <Switch>
      <Route path="/admin/sessions/new" render={(p) => <NewSniffTest />} />
      <Route
        path="/admin/sessions/:sessionId"
        render={(p) => <EditSniffTest sessionId={p.match.params["sessionId"]} />}
      />
      {/* <Route path="/admin/export" render={(p) => <Export />} /> */}
      <Route path="/admin/sessions" render={(p) => <SniffTestList />} />
      <Route path="/admin/videos" render={(p) => <TrainingVideoList />} />
      <Route path="/admin/users/new" render={(p) => <CreateUser />} />
      <Route path="/admin/users" render={(p) => <UserList />} />
      {/* <Route path="/admin/mixes" render={(p) => <MixList />} /> */}
      <Route path="/admin/surveys/new" render={(p) => <CreateSurvey />} />
      <Route
        path="/admin/surveys/:surveyId"
        render={(p) => (
          <ManageSurveyPage surveyId={p.match.params["surveyId"]} />
        )}
      />
      <Route path="/admin/surveys" render={(p) => <SurveyList />} />
      <Route path="/admin" render={(p) => <Redirect to="/admin/sessions/" />} />
    </Switch>
  );
}
