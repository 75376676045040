import { gql, useLazyQuery } from '@apollo/client';
import { useState } from 'react'
import { useSession } from '../../SessionContext';

interface LoginQueryData {
  app_user_login: {
    jwt?: string;
    success: boolean
  }
}

interface LoginQueryVars {
  email: string;
  password: string;
}

const LOGIN_QUERY = gql`
query Login($email: String!, $password: String!) {
  app_user_login(email: $email, password: $password) {
    success
    jwt
  }
}

`;

export default function useLogin() {
  const { setJwt } = useSession();
  const [ error, setError ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const onQueryComplete = (data: LoginQueryData) => {
    if (data.app_user_login.success) {
      setSuccess(true);
      setJwt(data.app_user_login.jwt!)
    } else {
      setSuccess(false)
      setError('Invalid login');
    }
  }
  const [runLoginQuery, { loading }] = useLazyQuery<
    LoginQueryData,
    LoginQueryVars
  >(LOGIN_QUERY, {onCompleted: onQueryComplete, onError: (e) => {setSuccess(false); setError(e.message)}, fetchPolicy: "no-cache"});

  const login = (email: string, password: string) => {
      setSuccess(false);
      return runLoginQuery({variables: {email, password}})
  }
  const logout = () => {
    setJwt('');
  }

  return {
      login,
      logout,
      state: success ? 'success' : loading ? 'loading' : error ? 'error' : 'idle',
      error
  }

}