import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import gql from "graphql-tag";

const mutation = gql`
mutation ValidateUserEmail($token: String!) {
  app_user_validate_email(token: $token) {
    success
  }
}
`

export default function VerifyEmail() {
  const [validateEmail] = useMutation<{app_user_validate_email?: {success: boolean}}, {token: string}>(mutation)

  const [label, setLabel] = useState("Verifying email...")
  const location = useLocation();
  const token = location.search.substring(7);

  useEffect(() => {
    validateEmail({variables: {token}}).then((result) => {
      if (result.data?.app_user_validate_email?.success) {
        setLabel("Your email has been verified. Please close this page and continue in the app.")
      } else {
        setLabel("Email verification failed")
      }
    });
  }, []);
  return <div>{label}</div>
}