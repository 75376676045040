import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const MUT = gql`
mutation addVideo($label: String, $url: String) {
  insert_app_training_videos_one(object: {label: $label, url: $url}) {
    id
  }
}
`;

export interface AddSurveyAttachmentData {
  insert_app_training_videos_one: {
    id: string;
  }
}

export interface AddSurveyAttachmentVariables {
  label: string;
  url: string;
}

export function useAddSurveyAttachmentMutation(
  options?:
    | MutationHookOptions<AddSurveyAttachmentData, AddSurveyAttachmentVariables>
    | undefined
) {
  return useMutation<AddSurveyAttachmentData, AddSurveyAttachmentVariables>(
    MUT,
    options
  );
}
