import {
    Box,
    Grid,
    Container,
    Button,
    InputLabel,
    Card,
    Paper,
    CardContent,
    TextField,
    Typography,
    Stepper,
    Step,
    StepLabel,
    IconButton,
    FormControl,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio,
    Link,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Snackbar,
    SnackbarOrigin,
    SnackbarContent,
    FormGroup,
    Switch
  
  } from "@material-ui/core";
  import { EditorState, ContentState } from 'draft-js';
  import { Editor } from 'react-draft-wysiwyg';
  import { convertToHTML, convertFromHTML } from 'draft-convert';
  import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
  import { faTrash } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { max } from "lodash";
  import { RemoveCircle, ExpandMore, Menu, AddCircleOutline, RemoveCircleOutline, CheckCircleOutline } from "@material-ui/icons";
  import LoadableImage from "../components/LoadableImage";
  import ImageUpload from "../components/ImageUpload";
  import React, { useEffect, useState } from "react";
  import { useUserId } from "../../../SessionContext";
  import {gql, useQuery, useMutation} from '@apollo/client'
  import EditSurvey from "../surveys/tabs/EditSurvey";
  import { getSniffData, 
    updateGeneralInfo, 
    updateChannels, 
    addSniff, 
    deletesniff, 
    duplicateSniff, 
    updateSniffTestSettings, 
    addSection, 
    deleteSection, 
    updateSections, 
    codeSearch, 
    EMAIL_LOOKUP, 
    ADD_CUSTOMER, 
    ADD_OIL_USER, 
    NO_ADMIN, 
    DELETE_CUSTOMER, 
    ADD_CONSUMER, 
    DELETE_CONSUMER, 
    ADD_PLACEHOLDER,
    SWAP_SECTION,
    GetChannelName,
    nukeClt,
    updatePopinOne,
    updatePopinTwo,
    updatePopinThree,
    SWAP_MIX } from "./queries/ModifyProject";
  
  interface EditSniffTestProps {
    sessionId: string;
  }

  export interface State extends SnackbarOrigin {
    open: boolean;
  }

  const steps = ['General Information', 'Channel Settings', 'Sniff Set-Up', 'Survey Set-Up'];
  const steps2 = ['General Information','Survey Set-Up'];
  
  export function EditSniffTest(p: EditSniffTestProps) {
    const [isBusy, setIsBusy] = useState(false);
    const userId = useUserId();
    const [generalInfo, setGeneralInfo] = useState()
    const {data: generalinfo, loading: loading, refetch: refetchgeneral} = useQuery(getSniffData, {fetchPolicy: "network-only", variables: {sniffid: p.sessionId}})
    const [updateGeneral] = useMutation(updateGeneralInfo)
    const [updateChannelSettings] = useMutation(updateChannels)
    const [addSniffTest] = useMutation(addSniff)
    const [addDuplicateSniff] = useMutation(duplicateSniff)
    const [updateSniffTestSet] = useMutation(updateSniffTestSettings)
    const [addSurveySection] = useMutation(addSection)
    const [nukeAllClt] = useMutation(nukeClt)
    const [deleteSurveySection] = useMutation(deleteSection)
    const [updateSurveySections] = useMutation(updateSections)
    const [deleteSniff] = useMutation(deletesniff)
    const [theSteps, setTheSteps] = useState(steps)
    const {data: codesearchResults, refetch: refetchCodeSearch} = useQuery(codeSearch, {fetchPolicy: "network-only", variables: {code: ""}})

    useEffect(() => {
        if(generalinfo){
            setGeneralInfo(generalinfo.project[0])
            if (generalinfo.project[0].session_type == 'End-Product Test') {
                setTheSteps(steps2)
              } else {
                setTheSteps(steps)
              }
        }
    }, [generalinfo]);

    // snackbar stuff
    const [state, setState] = React.useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });

    const { vertical, horizontal, open } = state;

    const AlertMe = (newState: SnackbarOrigin) => {
        setState({ open: true, ...newState });
    }

    const handleClose1 = () => {
        setState({ ...state, open: false });
    };
  
    //wizard step stuff
    const [activeStep, setActiveStep] = React.useState(0);
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //update general info
    const handleUpdate = (
      initialName: string,
      inititalSniffLength: string,
      initialSniffRatingScale: string,
      initialSessionType: string,
      initialImage: string,
      initialDescription: string,
      intitialSniffAndRateDescription: string,
      initialPreferenceQuest: string,
      initialPlayAroundType: string,
      initialPositive: string,
      initialNegative: string,
      initialClt: boolean,
      initialRandom: boolean
    ) => {
      setIsBusy(true);
      updateGeneral({variables: {
        id: p.sessionId,
        name: initialName,
        image: initialImage || '',
        description: initialDescription || '',
        sniff_and_rate_description: intitialSniffAndRateDescription || '',
        play_around_type: initialPlayAroundType || '',
        preference_question: initialPreferenceQuest || '',
        preference_positive: initialPositive || '',
        preference_negative: initialNegative || '',
        session_type: initialSessionType || undefined,
        sniff_test_length: inititalSniffLength || '',
        sniff_rating_scale: initialSniffRatingScale || '',
        clt: initialClt || false,
        random_mixes: initialRandom
      }}).then((resp) => {
        if (resp.data) {
          if (resp.data.update_project.returning[0].session_type == 'End-Product Test') {
            setTheSteps(steps2)
            handleNext()
          } else {
            setTheSteps(steps)
            handleNext()
          }
          setGeneralInfo(resp.data.update_project.returning[0])
        }
      }).catch(e => {
        setIsBusy(false);
        console.log('Failed to update session');
        console.log(e);
      })
    };

    //update clt
    const handleUpdateClt = (
      initialName: string,
      inititalSniffLength: string,
      initialSniffRatingScale: string,
      initialSessionType: string,
      initialImage: string,
      initialDescription: string,
      intitialSniffAndRateDescription: string,
      initialPreferenceQuest: string,
      initialPlayAroundType: string,
      initialPositive: string,
      initialNegative: string,
      initialClt: boolean,
      initialRandom: boolean
    ) => {
      setIsBusy(true);
      if (initialClt == true) {
        nukeAllClt().then ((cltresp) => {
          if(cltresp) {
            updateGeneral({variables: {
              id: p.sessionId,
              name: initialName,
              image: initialImage || '',
              description: initialDescription || '',
              sniff_and_rate_description: intitialSniffAndRateDescription || '',
              play_around_type: initialPlayAroundType || '',
              preference_question: initialPreferenceQuest || '',
              preference_positive: initialPositive || '',
              preference_negative: initialNegative || '',
              session_type: initialSessionType || undefined,
              sniff_test_length: inititalSniffLength || '',
              sniff_rating_scale: initialSniffRatingScale || '',
              clt: initialClt || false,
              random_mixes: initialRandom
            }}).then((resp) => {
              if (resp.data) {
                setGeneralInfo(resp.data.update_project.returning[0])
              }
            }).catch(e => {
              setIsBusy(false);
              console.log('Failed to update session');
              console.log(e);
            })          
          }
        })
      } else {
        updateGeneral({variables: {
          id: p.sessionId,
          name: initialName,
          image: initialImage || '',
          description: initialDescription || '',
          sniff_and_rate_description: intitialSniffAndRateDescription || '',
          play_around_type: initialPlayAroundType || '',
          preference_question: initialPreferenceQuest || '',
          preference_positive: initialPositive || '',
          preference_negative: initialNegative || '',
          session_type: initialSessionType || undefined,
          sniff_test_length: inititalSniffLength || '',
          sniff_rating_scale: initialSniffRatingScale || '',
          clt: initialClt || false,
          random_mixes: initialRandom
        }}).then((resp) => {
          if (resp.data) {
            setGeneralInfo(resp.data.update_project.returning[0])
          }
        }).catch(e => {
          setIsBusy(false);
          console.log('Failed to update session');
          console.log(e);
        })   
      }
    };
  
    //update channel settings
    const handleUpdate2 = (thischannelsettings: []) => {
        setIsBusy(true);
        thischannelsettings.map(channel => {
          updateChannelSettings({variables: {
            channelid: channel['id'],
            channelname:channel['name'],
            channel_type:channel['channel_type'],
            duration:channel['duration'],
            description:channel['description']
          }}).then ((resp) => {
            if (resp.data){
                setGeneralInfo(resp.data.update_project_channel.returning[0].project)
            }
          }).catch(e => {
            setIsBusy(false);
            console.log('Failed to update channels');
            console.log(e);         
          })
        });
        refetchgeneral({sniffid:p.sessionId})
        handleNext()
    };

    //add sniff
    async function handleSubmit3(sniffSettings: any[]) {
      if (sniffSettings.length > 0) {
        handleUpdateAddSniff(sniffSettings)
      }
        let promise = new Promise((resolve, reject) => {
            let text = "";
            let charset = "abcdefghijklmnopqrstuvwxyz"
            for (var i = 0; i < 2; i++)
            text += charset.charAt(Math.floor(Math.random() * charset.length)); 
            text = text.toUpperCase()
            let numbers = "";
            let charset2 = "0123456789"
            for (var i = 0; i < 2; i++)
            numbers += charset2.charAt(Math.floor(Math.random() * charset2.length)); 
            refetchCodeSearch({code: text+numbers}).then ((resp) => {
                if (resp.data.MIX_mix.length > 0) {
                    handleSubmit3(sniffSettings)
                } else {
                    resolve(text+numbers)
                }
            }).catch(e => {

            })           
        })
        const thecode = await promise
        let highestSortPosition = 0
        highestSortPosition =
        max(
          (generalinfo.project[0].mixes || []).map(
            (mix: any) => mix.sort_position
          )
        ) || 0;
        const newSortPosition = highestSortPosition + 1000 + Math.round(Math.random() * 100);
        addSniffTest({variables: {
        project_id: p.sessionId,
        description: thecode,
        sort_position: newSortPosition
        }}).then((resp) => {
        if (resp.data) {
            setGeneralInfo(resp.data.insert_MIX_mix_one.project)
        }
        }).catch(e => {
        setIsBusy(false);
        console.log('Failed to create sniff test');
        console.log(e);
        })
    };

    //delete sniff
    const handleDeleteSniff = ( id: string, e:Event) => {
      e.stopPropagation()
      if (window.confirm("Are you sure you wish to delete this sniff?")) {
        setIsBusy(true);
        deleteSniff({variables: {
          id: id,
        }}).then((resp) => {
          if (resp.data) {
            setGeneralInfo(resp.data.delete_MIX_mix.returning[0].project)
          }
        }).catch(e => {
          setIsBusy(false);
          console.log('Failed to delete section');
          console.log(e);
        })
      } else {
        return
      }
    };
  
    //duplicate sniff
    async function handleDuplicate( sniffSettings: any[], index: any, e:Event, sort_position: any) {
      handleUpdate3(sniffSettings)
      e.stopPropagation()
      setIsBusy(true);
      let promise = new Promise((resolve, reject) => {
        let text = "";
        let charset = "abcdefghijklmnopqrstuvwxyz"
        for (var i = 0; i < 2; i++)
        text += charset.charAt(Math.floor(Math.random() * charset.length)); 
        text = text.toUpperCase()
        let numbers = "";
        let charset2 = "0123456789"
        for (var i = 0; i < 2; i++)
        numbers += charset2.charAt(Math.floor(Math.random() * charset2.length)); 
        console.log(text+numbers)
        refetchCodeSearch({code: text+numbers}).then ((resp) => {
            if (resp.data.MIX_mix.length > 0) {
                handleSubmit3(sniffSettings)
            } else {
                resolve(text+numbers)
            }
        }).catch(e => {

        })           
      })
      const thecode = await promise
      addDuplicateSniff({variables: {
        project_id: p.sessionId,
        description: thecode,
        sort_position: sort_position + 1,
        name: sniffSettings[index]["name"] + " *duplicate",
        channels: `{${sniffSettings[index]["channels"].join(", ")}}`,
        owner_id: userId!
      }}).then((resp) => {
        if (resp.data) {
          setGeneralInfo(resp.data.insert_MIX_mix_one.project)
          //AlertMe({vertical: 'bottom', horizontal: 'center'})
        }
      }).catch(e => {
        setIsBusy(false);
        console.log('Failed to create duplicate sniff');
        console.log(e);
      })
    };
  
    //update sniffs
    const handleUpdate3 = ( sniffSettings: any[]) => {
      setIsBusy(true);
      sniffSettings.map(sniff => {
        updateSniffTestSet({variables: {
          sniff_id: sniff["id"],
          description: sniff["description"],
          name: sniff["name"],
          channels: `{${sniff["channels"].join(", ")}}`,
        }}).then ((resp) => {
          if (resp.data){
              setGeneralInfo(resp.data.update_MIX_mix.returning[0].project)
          }
        }).catch(e => {
          setIsBusy(false);
          console.log('Failed to update sniff');
          console.log(e);         
        })
      });
      refetchgeneral({sniffid:p.sessionId})
      handleNext()
    };

    //update sniffs
    const handleUpdateAddSniff = ( sniffSettings: any[]) => {
      setIsBusy(true);
      sniffSettings.map(sniff => {
        updateSniffTestSet({variables: {
          sniff_id: sniff["id"],
          description: sniff["description"],
          name: sniff["name"],
          channels: `{${sniff["channels"].join(", ")}}`,
        }}).then ((resp) => {
          if (resp.data){
              setGeneralInfo(resp.data.update_MIX_mix.returning[0].project)
          }
        }).catch(e => {
          setIsBusy(false);
          console.log('Failed to update sniff');
          console.log(e);         
        })
      });
      refetchgeneral({sniffid:p.sessionId})
    };

    //add survey section
    const handleSubmit4 = ( surveyid: string) => {
        let highestSortPosition = 0
        highestSortPosition =
        max(
          (generalinfo.project[0].survey.SUR_sections || []).map(
            (section: any) => section.sort_position
          )
        ) || 0;
      const newSortPosition = highestSortPosition + 1000 + Math.round(Math.random() * 100);
      setIsBusy(true);
      addSurveySection({variables: {
        survey_id: surveyid,
        sort_position: newSortPosition
      }}).then((resp) => {
        if (resp.data) {
          setGeneralInfo(resp.data.insert_SUR_section.returning[0].SUR_survey.project)
        }
      }).catch(e => {
        setIsBusy(false);
        console.log('Failed to create section');
        console.log(e);
      })
    };

    //delete survey section
    const handleDelete4 = ( sectionid: string) => {
        if (window.confirm("Are you sure you wish to delete this section?")) {
          setIsBusy(true);
          deleteSurveySection({variables: {
            section_id: sectionid,
          }}).then((resp) => {
            if (resp.data) {
              setGeneralInfo(resp.data.delete_SUR_section.returning[0].SUR_survey.project)
            }
          }).catch(e => {
            setIsBusy(false);
            console.log('Failed to delete section');
            console.log(e);
          })
        } else {
          return
        }
      };

    //update Survey Sections
    const handleUpdate4 = ( section: any[]) => {
        setIsBusy(true);
        section.map(sections => {
            updateSurveySections({variables: {
            sectionid: sections["ID"],
            description: sections["description"],
            title: sections["title"],
            image: sections["image"],
            popin_info: sections["popin_info"]
            }}).then ((resp) => {
            if (resp.data){
                setGeneralInfo(resp.data.update_SUR_section.returning[0].SUR_survey.project)
            }
            }).catch(e => {
            setIsBusy(false);
            console.log('Failed to update survey');
            console.log(e);         
            })
        });
        AlertMe({vertical: 'bottom', horizontal: 'center'})
        };

    //update Survey Sections on Back Button
    const handleUpdate4Back = ( section: any[]) => {
      setIsBusy(true);
      section.map(sections => {
          updateSurveySections({variables: {
          sectionid: sections["ID"],
          description: sections["description"],
          title: sections["title"],
          image: sections["image"],
          popin_info: sections["popin_info"]
          }}).then ((resp) => {
          if (resp.data){
              setGeneralInfo(resp.data.update_SUR_section.returning[0].SUR_survey.project)
          }
          }).catch(e => {
          setIsBusy(false);
          console.log('Failed to update survey');
          console.log(e);         
          })
      });
      handleBack()
      };

    //return step content
    const getStepContent = (activeStep: any) => {
      switch (activeStep) {
        case 0:
          return <NewSessionForm onUpdate={handleUpdate} onUpdateClt={handleUpdateClt} isBusy={isBusy} testid={p.sessionId} generalinfo={generalInfo}/>
        case 1:
            if (generalinfo?.project[0].session_type == 'End-Product Test') {
                return <SurveySettings onSubmit4={handleSubmit4} onUpdate4={handleUpdate4} onDelete4={handleDelete4} handleUpdate4Back={handleUpdate4Back} isBusy={isBusy} testid={p.sessionId} generalinfo={generalInfo}/>
            } else {
                return <ChannelSettings onUpdate2={handleUpdate2} isBusy={isBusy} testid={p.sessionId} generalinfo={generalInfo} handleBack={handleBack}/>
            }
        case 2:
          return <SniffTestSetup onSubmit3={handleSubmit3} onDeleteSniff={handleDeleteSniff} onUpdate3={handleUpdate3} handleBack={handleBack} onDuplicate={handleDuplicate} testid={p.sessionId} generalinfo={generalInfo} isBusy={isBusy}/>
        case 3:
          return <SurveySettings onSubmit4={handleSubmit4} onUpdate4={handleUpdate4} onDelete4={handleDelete4} handleUpdate4Back={handleUpdate4Back} isBusy={isBusy} testid={p.sessionId} generalinfo={generalInfo}/>
      }
    }
  
    if (loading) {
      return (<div>loading data</div>)
    }

    return (
      <Container style={{marginBottom: -20}}>
        <div style={{paddingBottom: 100}}>
        <Box display="flex" justifyContent="center" width="100%">
          <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#4A1690',
                    padding: '20px'
                }}>
                <Grid container>
                    <Grid item xs={12} style={{fontSize: '1.2em'}}>{generalinfo?.project[0].name}</Grid>
                    <Grid item xs={12}>{generalinfo?.project[0].session_type}</Grid>
                </Grid>
          </div>
          <Stepper activeStep={activeStep} style={{ border: '1px solid gray' }}>
            {theSteps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps} >{label}</StepLabel>       
                </Step>
              );
            })} 
          </Stepper>
          </Box>       
          <Box>
            <Box marginTop="20px">
              <div style={{ width: '100%' }}>{getStepContent(activeStep)}</div>
            </Box>
          </Box>         
          </div>            
          <Snackbar open={open} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal}} key={vertical + horizontal} onClose={handleClose1} style={{marginLeft: '120px', color: 'green'}}>
            <div>       
            <SnackbarContent style={{
                backgroundColor:'green',
                minWidth: 800,
                display: 'block',
                textAlign: 'center'
                }}
                message={<div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} id="client-snackbar"><CheckCircleOutline/>&nbsp;&nbsp;Updated Successfully!</div>}
            />
            </div>
          </Snackbar>        
      </Container>
    );
  }

  //** GENERAL INFORMATION WIZARD STEP */
  
  interface NewSessionFormProps {
    testid?: string,
    generalinfo?: any,
    isBusy?: boolean;
    onUpdate: (sessionName: string, sniffLength: string, sniffRatingScale: string, sessionType: string, image: string, testDescription: string, sniffAndRateDescription: string, preferenceQuest: string, playAroundType: string, positiveWording: string, negativeWording: string, Clt: boolean, RandomMixes: boolean) => void;
    onUpdateClt: (sessionName: string, sniffLength: string, sniffRatingScale: string, sessionType: string, image: string, testDescription: string, sniffAndRateDescription: string, preferenceQuest: string, playAroundType: string, positiveWording: string, negativeWording: string, Clt: boolean, RandomMixes: boolean) => void;
  }
  
  function NewSessionForm(p: NewSessionFormProps) {

    const generalinfo = p.generalinfo
    const sessionId = p.testid
    const [isBusy, setIsBusy] = useState(p.isBusy || false);
    const [sessionName, setSessionName] = useState("");
    const [randomMixes, setRandomMixes] = useState(false);
    const [Clt, setClt] = useState(false);
    const [sniffLength, setSniffLength] = useState("");
    const [sniffRatingScale, setSniffRatingScale] = useState("");
    const [testDescription, setTestDescription] = useState("");
    const [sniffAndRateDescription, setSniffAndRateDescription] = useState("");
    const [image, setImage] = useState("");
    const [sessionType, setSessionType] = useState("Pre-Set Sniff Test");
    const [preferenceQuest, setPreferenceQuest] = useState("");
    const [playAroundType, setPlayAroundType] = useState("Standard");
    const [positiveWording, setPositiveWording] = useState("");
    const [negativeWording, setNegativeWording] = useState("");
    const [popinOne, setPopinOne] = useState("");
    const [popinTwo, setPopinTwo] = useState("");
    const [popinThree, setPopinThree] = useState("");
    const [savePopinOne] = useMutation(updatePopinOne)
    const [savePopinTwo] = useMutation(updatePopinTwo)
    const [savePopinThree] = useMutation(updatePopinThree)
    const {refetch: lookupEmail} = useQuery(EMAIL_LOOKUP)
    const [customers, setCustomers] = useState([{}])
    const [customerInsert, setCustomerInsert] = useState([])
    const [addCustomer] = useMutation(ADD_CUSTOMER)
    const [addOilUser] = useMutation(ADD_OIL_USER)
    const [noAdmin] = useMutation(NO_ADMIN)
    const [deleteCustomer] = useMutation(DELETE_CUSTOMER)
    const [consumers, setConsumers] = useState([{}])
    const [consumerInsert, setConsumerInsert] = useState([])
    const [addConsumer] = useMutation(ADD_CONSUMER)
    const [deleteConsumer] = useMutation(DELETE_CONSUMER)
    const [addPlaceholder] = useMutation(ADD_PLACEHOLDER)
    const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
    );
    const [editorState2, setEditorState2] = useState(
      () => EditorState.createEmpty(),
    );
    const [editorState3, setEditorState3] = useState(
      () => EditorState.createEmpty(),
    );
    const [editorState4, setEditorState4] = useState(
      () => EditorState.createEmpty(),
    );
   
    useEffect(() => {
      if(generalinfo){
        console.log(generalinfo)
        setSessionName(generalinfo.name)
        setClt(generalinfo.clt)
        setRandomMixes(generalinfo.random_mixes)
        setImage(generalinfo.image)
        setTestDescription(generalinfo.description)
        setSniffAndRateDescription(generalinfo.sniff_and_rate_description)
        setSessionType(generalinfo.session_type)
        setSniffLength(generalinfo.sniff_test_length)
        setSniffRatingScale(generalinfo.sniff_rating_scale)
        setPlayAroundType(generalinfo.play_around_type)
        setPreferenceQuest(generalinfo.preference_question)
        setPositiveWording(generalinfo.preference_positive)
        setNegativeWording(generalinfo.preference_negative)
        setCustomers(generalinfo.project_customers)
        setConsumers(generalinfo.project_consumers)
        setPopinOne(generalinfo.project_guided_popins[0].popin_one || "")
        setPopinTwo(generalinfo.project_guided_popins[0].popin_two || "")
        setPopinThree(generalinfo.project_guided_popins[0].popin_three || "")
        if (p.generalinfo.description) {
          let contentState = convertFromHTML(p.generalinfo.description) as any as ContentState;
          setEditorState(EditorState.createWithContent(contentState))         
        }
        if (p.generalinfo.project_guided_popins[0].popin_one) {
          let contentState2 = convertFromHTML(p.generalinfo.project_guided_popins[0].popin_one) as any as ContentState;
          setEditorState2(EditorState.createWithContent(contentState2))    
        }
        if (p.generalinfo.project_guided_popins[0].popin_two) {
          let contentState3 = convertFromHTML(p.generalinfo.project_guided_popins[0].popin_two) as any as ContentState;
          setEditorState3(EditorState.createWithContent(contentState3))         
        }
        if (p.generalinfo.project_guided_popins[0].popin_three) {
          let contentState4 = convertFromHTML(p.generalinfo.project_guided_popins[0].popin_three) as any as ContentState;
          setEditorState4(EditorState.createWithContent(contentState4))         
        }
      }
    }, [generalinfo]);

    useEffect(() => {
      handleUpdateClt()
    }, [Clt, randomMixes]);  

    const handleUpdate = () => {
      p.onUpdate(sessionName, sniffLength, sniffRatingScale, sessionType, image, testDescription, sniffAndRateDescription, preferenceQuest, playAroundType, positiveWording, negativeWording, Clt, randomMixes);
    };

    const handleUpdateClt = () => {
      p.onUpdateClt(sessionName, sniffLength, sniffRatingScale, sessionType, image, testDescription, sniffAndRateDescription, preferenceQuest, playAroundType, positiveWording, negativeWording, Clt, randomMixes);
    };

    useEffect(() => {
      let html = convertToHTML(editorState.getCurrentContent());
      if (html != "<p></p>") {
        setTestDescription(html)
      }
    }, [editorState]);

    useEffect(() => {
      let html = convertToHTML(editorState2.getCurrentContent());
      if (html != "<p></p>") {
        setPopinOne(html)
        savePopinOne({variables: {
          project_id: sessionId,
          popin_one: popinOne
        }}).then((popinoneresp) => {
          if (popinoneresp.data) {

          }
        })
      }
    }, [editorState2]);

    useEffect(() => {
      let html = convertToHTML(editorState3.getCurrentContent());
      if (html != "<p></p>") {
        setPopinTwo(html)
        savePopinTwo({variables: {
          project_id: sessionId,
          popin_two: popinTwo
        }}).then((popinoneresp) => {
          if (popinoneresp.data) {

          }
        })
      }
    }, [editorState3]);

    useEffect(() => {
      let html = convertToHTML(editorState4.getCurrentContent());
      if (html != "<p></p>") {
        setPopinThree(html)
        savePopinThree({variables: {
          project_id: sessionId,
          popin_three: popinThree
        }}).then((popinoneresp) => {
          if (popinoneresp.data) {

          }
        })
      }
    }, [editorState4]);
  
    const updateCustomers = (e:any) => {
        let custs = e.target.value.trim()
        custs = custs.replace(/[\n\r\t\s]+/g, ",").split(",")
        setCustomerInsert(custs)
    }

    const addCustomers = (e:any) => {
      customerInsert.forEach(emails => {
        let noadminid = "0"
        let theuserid = "0"
        lookupEmail({email: emails}).then ((resp) => {
          if (resp.data.app_user.length > 0){
              theuserid = resp.data.app_user[0].id  
              addCustomer({variables: {
                user_id: resp.data.app_user[0].id,
                project_id: sessionId
              }}).then((resp2) => {
                if (resp2.data) {
                  setCustomers(resp2.data.insert_project_customers.returning[0].project.project_customers) 
                  resp2.data.insert_project_customers.returning[0].project.channels.forEach((channel: any) => {
                    addOilUser({variables: {
                      app_user_id: theuserid,
                      channel_id: channel.id,
                      oil_level: channel.duration,
                      project_id: sessionId
                    }}).then((resp9) => {
                      if (resp9.data) {
                        setCustomers(resp9.data.insert_project_channel_oil.returning[0].project.project_customers)   
                      }
                    })
                  })              
                }
              }).catch(e => {
                setIsBusy(false);
                console.log('Failed to add user');
                console.log(e);
              })    
          } else {

          }
          }).catch(e => {
          console.log(e);         
          })
      })
    }

    const deleteCustomers = (userid: String) => {
      deleteCustomer({variables: {
        user_id: userid,
        project_id: sessionId
      }}).then((resp) => {
        if (resp.data) {
          setCustomers(resp.data.delete_project_customers.returning[0].project.project_customers)                   
        }
      }).catch(e => {
        setIsBusy(false);
        console.log('Failed to add user');
        console.log(e);
      })   
    }

    const updateConsumers = (e:any) => {
      let custs = e.target.value.trim()
      custs = custs.replace(/[\n\r\t\s]+/g, ",").split(",")
      setConsumerInsert(custs)
    }

    const addConsumers = (e:any) => {
      consumerInsert.forEach(emails => {
        let noadminid = "0"
        let theuserid = "0"
        lookupEmail({email: emails}).then ((resp) => {
          if (resp.data.app_user.length > 0){
              console.log(resp.data.app_user[0].id)
              theuserid = resp.data.app_user[0].id     
              addConsumer({variables: {
                user_id: resp.data.app_user[0].id,
                project_id: sessionId
              }}).then((resp2) => {
                if (resp2.data) {
                  resp2.data.insert_project_consumers.returning[0].project.channels.forEach((channel: any) => {
                    addOilUser({variables: {
                      app_user_id: theuserid,
                      channel_id: channel.id,
                      oil_level: channel.duration,
                      project_id: sessionId
                    }}).then((resp9) => {
                      if (resp9.data) {
                        setConsumers(resp9.data.insert_project_channel_oil.returning[0].project.project_consumers)   
                      }
                    })
                  })                  
                }
              }).catch(e => {
                setIsBusy(false);
                console.log('Failed to add user');
                console.log(e);
              })       
          } else {
 
          }
          }).catch(e => {
          console.log(e);         
          })
      })
    }

    const deleteConsumers = (userid: String) => {
      deleteConsumer({variables: {
        user_id: userid,
        project_id: sessionId
      }}).then((resp) => {
        if (resp.data) {
          setConsumers(resp.data.delete_project_consumers.returning[0].project.project_consumers)                   
        }
      }).catch(e => {
        setIsBusy(false);
        console.log('Failed to add user');
        console.log(e);
      })   
    }

    const updateClt = (event: React.ChangeEvent<HTMLInputElement>) => {
      setClt(event.target.checked)
    };

    const updateRandom = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRandomMixes(event.target.checked)
    };

    return (
      <Grid container spacing={2} style={{marginTop:50}}>
      <Grid item xs={6}>
      <Box component={Card} style={{ border: '1px solid gray' }}>
        <CardContent>
          <Typography variant="h5">
            Test Name
          </Typography>
          <Box marginTop="20px">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Name of Test (Required)"
              value={sessionName}
              onChange={(e) => setSessionName(e.target.value)}
            />
          </Box>
          <Box marginTop="20px">
          <FormGroup>
            <FormControlLabel control={<Switch color="primary" checked={Clt} onChange={updateClt} />} label="CLT" />
            <FormControlLabel control={<Switch color="primary" checked={randomMixes} onChange={updateRandom} />} label="Random Mixes" />
          </FormGroup>
          </Box>
          <Box marginTop="40px">
            <Typography variant="h5" style={{marginBottom: 25}}>
              Test Description
            </Typography>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
          </Box>
          <Box marginTop="40px">
            <div>
            <Typography variant="h5" style={{marginBottom: 25}}>
              Test Image
            </Typography>
              <LoadableImage
                url={image}
              />
              <ImageUpload onSuccess={setImage} />
            </div>
          </Box>
          <Typography variant="h5" style={{marginBottom: 25, marginTop: 25}}>
              Sniff Settings
          </Typography>
          <Box marginTop="40px">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              multiline
              rows={5}
              variant="outlined"
              label="Sniff & Rate Description"
              value={sniffAndRateDescription}
              onChange={(e) => setSniffAndRateDescription(e.target.value)}
            />
          </Box> 
          <Box marginTop="40px">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Sniff Test Length"
              value={sniffLength}
              onChange={(e) => setSniffLength(e.target.value)}
            />
          </Box>
          <Box marginTop="40px">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Sniff Test Rating Scale"
              value={sniffRatingScale}
              onChange={(e) => setSniffRatingScale(e.target.value)}
            />
          </Box> 
        </CardContent>
      </Box>
      </Grid>
      <Grid item xs={6}>
      <Box component={Card} style={{ border: '1px solid gray' }}>
        <CardContent>
          <Box>
            <FormControl>
            <Typography variant="h5" style={{marginBottom: 25}}>
              Test Type
            </Typography>
            <RadioGroup style={{ marginBottom: 15 }}
              color="primary"
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => {setSessionType(e.target.value)}}
              value={sessionType}
            >
              <FormControlLabel value="Pre-Set Sniff Test" style={{width: '100%'}} control={<Radio color="primary" />} label="Pre-Set Sniff Test" />
              <FormControlLabel value="Full Test" style={{width: '100%'}}  control={<Radio color="primary" />} label="Full Test" />
              <FormControlLabel value="Guided Play Around" style={{width: '100%'}}  control={<Radio color="primary" />} label="Guided Play Around" />
              <FormControlLabel value="End-Product Test" style={{width: '100%'}}  control={<Radio color="primary" />} label="End-Product Test" />
            </RadioGroup>
            </FormControl>

            { sessionType == "Guided Play Around"  &&
            <div style={{marginBottom: "30px"}}>
            <Accordion style={{ marginTop: '15px', border: '1px solid gray' }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
              <Typography>"Ready To Blend" Popin</Typography>
              </AccordionSummary>
              <AccordionDetails >
                <Box width="100%">
                  <Editor
                    editorState={editorState2}
                    onEditorStateChange={setEditorState2}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                  />
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ marginTop: '15px', border: '1px solid gray' }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
              <Typography>"Ready To Move Forward" Popin</Typography>
              </AccordionSummary>
              <AccordionDetails >
                <Box width="100%">
                  <Editor
                    editorState={editorState3}
                    onEditorStateChange={setEditorState3}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                  />
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ marginTop: '15px', border: '1px solid gray' }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
              <Typography>"That's a Blend" Popin</Typography>
              </AccordionSummary>
              <AccordionDetails >
                <Box width="100%">
                  <Editor
                    editorState={editorState4}
                    onEditorStateChange={setEditorState4}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
            </div>
            }

            { (sessionType == "Full Test" || sessionType == "Guided Play Around")   &&
            <div>
            <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginBottom: 15, marginTop: 15  }}>Play Around Phase Setup</FormLabel>
            <RadioGroup
              color="primary"
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => setPlayAroundType(e.target.value)}
              value={playAroundType}
            >
              <FormControlLabel value="Standard" style={{width: '100%'}} control={<Radio color="primary" />} label="Standard" />
              <FormControlLabel value="Customized" style={{width: '100%'}} control={<Radio color="primary" />} label="Customized" />
            </RadioGroup>
            </FormControl>
            { playAroundType == "Customized" &&
              <div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box marginTop="20px">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="outlined"
                      label="Preference Question"
                      value={preferenceQuest}
                      onChange={(e) => setPreferenceQuest(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box marginTop="20px">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="outlined"
                      label="Preference Positive Wording"
                      value={positiveWording}
                      onChange={(e) => setPositiveWording(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box marginTop="20px">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="outlined"
                      label="Preference Negative Wording"
                      value={negativeWording}
                      onChange={(e) => setNegativeWording(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>
              </div>
              }
            </div>
            }
            <Typography variant="h6" style={{ marginTop: 25 }}>
            Customers
            </Typography>
            {customers?.map((x:any) => (
            <Grid container spacing={2} key={x.app_user?.id} alignItems="center" style={{ marginTop:5 }}>
              <Grid item xs={6} style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}>
                <Typography variant="subtitle2" style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}>
                {x?.app_user?.email}&nbsp;&nbsp;
                <CheckCircleOutline fontSize="small" htmlColor="green" style={{display: 'none'}}></CheckCircleOutline>
                </Typography>
              </Grid>   
              <Grid item xs={3} ></Grid>
              <Grid item xs={3}>
                <Link onClick={() => deleteCustomers(x?.app_user?.id)} href="#" color="inherit" style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}><RemoveCircle fontSize="small" htmlColor="red"></RemoveCircle>&nbsp;Delete</Link>
              </Grid>                 
            </Grid>
            ))}
            <Box marginTop="40px">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                onChange={(ev) => updateCustomers(ev)}
              />
              <div style={{textAlign: "center"}}>
              <Button style={{color: 'white', marginTop: 20, backgroundColor: 'darkblue', width: 400}} variant="outlined"  onClick={(ev) => addCustomers(ev)}>
                ADD CUSTOMERS
              </Button>
              </div>
            </Box>
            <Typography variant="h6" style={{ marginTop: 25 }}>
            Consumers
            </Typography>
            {consumers?.map((x:any) => (
            <Grid container key={x.app_user?.email} spacing={2} alignItems="center" style={{ marginTop:5 }}>
              <Grid item xs={6} style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}>
                <Typography variant="subtitle2" style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}>
                {x?.app_user?.email}&nbsp;&nbsp;
                <CheckCircleOutline fontSize="small" htmlColor="green" style={{display: 'none'}}></CheckCircleOutline>
                </Typography>
              </Grid>    
              <Grid item xs={3} >
              </Grid>
              <Grid item xs={3}>
                <Link onClick={() => deleteConsumers(x?.app_user?.id)} href="#" color="inherit" style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}><RemoveCircle fontSize="small" htmlColor="red"></RemoveCircle>&nbsp;Delete</Link>
              </Grid>                 
            </Grid>
            ))}
            <Box marginTop="40px">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                onChange={(ev) => updateConsumers(ev)}
              />
              <div style={{textAlign: "center"}}>
              <Button style={{color: 'white', marginTop: 20, backgroundColor: 'darkblue', width: 400}} variant="outlined" onClick={(ev) => addConsumers(ev)}>
                ADD CONSUMERS
              </Button>
              </div>
            </Box>
          </Box>
        </CardContent>
      </Box>
      </Grid>
      <Grid container >
        <Grid item xs={9} style={{marginTop: 30}}></Grid>
        <Grid item xs={3} style={{marginTop: 30}}>
          <Button onClick={handleUpdate} style={{color: 'white', backgroundColor: 'green', width: 200}} variant="outlined" >
            NEXT
          </Button>
        </Grid>
      </Grid>
      </Grid>
    );
  }

  //** CHANNEL SETTINGS WIZARD STEP */
  
  interface NewChannelSettingsProps {
    testid?: string,
    generalinfo?: any,
    isBusy?: boolean;
    onUpdate2: (thischannelsettings: []) => void;
    handleBack: any;
  }
  
  function ChannelSettings(p: NewChannelSettingsProps) {
  
    const [channelSettings, setChannelSettings] = useState(p.generalinfo.channels);
    const handleUpdate2 = () => { p.onUpdate2(channelSettings)};
    const handleBack = () => {p.handleBack()}
  
    const updateName = (e:any, index:number) => {
      let newChannelSettings = [...channelSettings]
      newChannelSettings[index] = {...newChannelSettings[index], name: e.target.value}
      setChannelSettings(newChannelSettings)
    }

    const updateDuration = (e:any, index:number) => {
      let newChannelSettings = [...channelSettings]
      newChannelSettings[index] = {...newChannelSettings[index], duration: e.target.value}
      setChannelSettings(newChannelSettings)
    }
  
    const updateDesc = (e:any, index:number) => {
      let newChannelSettings = [...channelSettings]
      newChannelSettings[index] = {...newChannelSettings[index], description: e.target.value}
      setChannelSettings(newChannelSettings)
    }

    const updateChannelType = (e:any, index:number) => {
        let newChannelSettings = [...channelSettings]
        newChannelSettings[index] = {...newChannelSettings[index], channel_type: e}
        setChannelSettings(newChannelSettings)
      }

    useEffect(() => {
        if(p.generalinfo){
            setChannelSettings(p.generalinfo.channels)
        }
      }, [p.generalinfo]);
  
  return (
  <div style={{marginTop:50}}>
  {channelSettings.map((channel: any, index: any) => (
  <Accordion key={channel?.id} style={{ marginTop: '15px', border: '1px solid gray' }}>
    <AccordionSummary
      expandIcon={<ExpandMore />}
      aria-controls="panel2a-content"
      id="panel2a-header"
    >
    <Typography>{channel.name}</Typography>
    </AccordionSummary>
    <AccordionDetails >
    <Box marginTop="40px" width="100%" paddingLeft="20%" paddingRight="20%">
            <Typography variant="h6">
            Channel Name
            </Typography>
            <Box marginTop="10px">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
                defaultValue={channel.name}
                onChange={(ev) => updateName(ev, index)}
              />
            </Box>
            <Typography variant="h6" style={{ marginTop: 40 }}>
            Channel Ingredients Description
            </Typography>
            <Box marginTop="10px">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={8}
                variant="outlined"
                defaultValue={channel.description}
                onChange={(ev) => updateDesc(ev, index)}
              />
            </Box>
            <Typography variant="h6">
            Duration
            </Typography>
            <Box marginTop="10px">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
                defaultValue={channel.duration}
                onChange={(ev) => updateDuration(ev, index)}
              />
            </Box>
            {p.generalinfo?.session_type !== 'Pre-Set Sniff Test' &&
            <FormControl style={{ marginBottom: 40}}>
            <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginTop: 40}}><Typography variant="h6">Channel Type</Typography></FormLabel>
            <RadioGroup
              color="primary"
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => {updateChannelType(e.target.value, index)}}
              value={channel.channel_type}              
            >
              <FormControlLabel value="Customizable" control={<Radio color="primary" />} label="Customizable" />
              <FormControlLabel value="Locked" control={<Radio color="primary" />} label="Locked" />
              <FormControlLabel value="Hidden" control={<Radio color="primary" />} label="Hidden" />
            </RadioGroup>
            </FormControl>
            }
          </Box>
    </AccordionDetails>
  </Accordion>
  ))}
  <Grid container >
    <Grid item xs={6} style={{marginTop: 30}}></Grid>
    <Grid item xs={3} style={{marginTop: 30}}>
      <Button onClick={handleBack} style={{color: 'white', backgroundColor: 'grey', width: 200}} variant="outlined" >
        BACK
      </Button>         
    </Grid>
    <Grid item xs={3} style={{marginTop: 30}}>
      <Button onClick={handleUpdate2} style={{color: 'white', backgroundColor: 'green', width: 200}} variant="outlined" >
        NEXT
      </Button>
    </Grid>
  </Grid>
  </div>
  );
  }

  //** SURVEY INFORMATION WIZARD STEP */
  
  interface NewSurveyProps {
    testid?: string,
    generalinfo?: any,
    isBusy?: boolean;
    onSubmit4: (surveyid: string) => void;
    onDelete4: (sectionid: string) => void;
    onUpdate4: (section: []) => void;
    handleUpdate4Back: any;
  }
  
  function SurveySettings(p: NewSurveyProps) {

    const onSubmit4 = () => { p.onSubmit4(p.generalinfo.survey_id)};
    const onDelete4 = (sectionid: string) => { p.onDelete4(sectionid)};
    const handleUpdate4Back = () => {p.handleUpdate4Back(section)}
    const handleUpdate4 = () => { p.onUpdate4(section)};
    const [section, setSection] = useState(p.generalinfo.survey.SUR_sections);
    const [swapSections] = useMutation(SWAP_SECTION)

    useEffect(() => {
      if(p.generalinfo){
          setSection(p.generalinfo.survey.SUR_sections)
      }
    }, [p.generalinfo]);


    const updateSectionTitle = (e:any, index:number) => {
    const newSection = [...section]
    newSection[index] = {...newSection[index], title: e.target.value}
    setSection(newSection)
    }

    const updateSectionDesc = (e:any, index:number) => {
    const newSection = [...section]
    newSection[index] = {...newSection[index], description: e.target.value}
    setSection(newSection)
    }

    const updatePopinInfo = (e:any, index:number) => {
    const newSection = [...section]
    newSection[index] = {...newSection[index], popin_info: e.target.value}
    setSection(newSection)
    }

    const setTheSectionImage = (ev: any, index:number) => {
    const newSection = [...section]
    newSection[index] = {...newSection[index], image: ev}
    setSection(newSection)
    }

    const onSectionMoved = (sectionIndex: number, direction: string) => {
    const nextSection = section[direction === 'up' ? sectionIndex - 1 : sectionIndex + 1];
    const nextSectionID = nextSection.ID
    const nextSectionSortPosition = nextSection.sort_position
    const thisSectionID = section[sectionIndex].ID
    const thisSectionSortPosition = section[sectionIndex].sort_position

    swapSections({
        variables: {
            sectionid: thisSectionID,
            sort_position: nextSectionSortPosition
        }}).then ((resp) => {
        if (resp.data){
            swapSections({
                variables: {
                    sectionid: nextSectionID,
                    sort_position: thisSectionSortPosition
                }}).then ((resp2) => {
                if (resp2.data){
                    setSection(resp2.data.update_SUR_section.returning[0].SUR_survey.SUR_sections)   
                }
                }).catch(e => {
                console.log(e);         
                })
        }
        }).catch(e => {
        console.log(e);         
        })
    }

  return (
    <div style={{marginTop:50}}>
    {section.length > 0 && section.map((section: any, index: any) => ( 
    <div style={{ marginTop: '15px'}} key={section.id}>
        <Paper elevation={3} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginBottom: '30px',
              border: '1px solid gray'
          }}>
        <Grid container spacing={2}>
            <Grid item xs={7} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            &nbsp;&nbsp;{section.title}
            </Grid>
            <Grid item xs={2} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <Button onClick={() => onSectionMoved(index, "up")}>
            move up
            </Button>
            </Grid>
            <Grid item xs={2} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <Button onClick={() => onSectionMoved(index, "down")}>
                move down
            </Button>            
            </Grid>
            <Grid item xs={1}>
            <IconButton onClick={() => onDelete4(section.ID)}aria-label="delete">
                <FontAwesomeIcon icon={faTrash} fixedWidth />
            </IconButton>     
            </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Box component={Card} style={{
              paddingLeft: 100,
              paddingRight: 100,
          }} >
            <CardContent>
            <Box marginTop="20px" >
                <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
                label="Section Title"
                value={section.title}
                onChange={(ev) => updateSectionTitle(ev, index)}               
                />
            </Box>
            <Box marginTop="40px">
                <div>
                <InputLabel> Section Image</InputLabel>
                <LoadableImage
                    url={section.image}
                />
                <ImageUpload onSuccess={(ev) => setTheSectionImage(ev, index)} />
                </div>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" marginTop="50px">
                <EditSurvey surveyId={section.ID} />
            </Box>
            <Box marginTop="80px">
                <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                label="End Section Popin Title"
                value={section.description}
                onChange={(ev) => updateSectionDesc(ev, index)}
                
                />
            </Box>
            <Box marginTop="40px" marginBottom="20px">
                <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                label="End Section Popin Information"
                value={section.popin_info}
                onChange={(ev) => updatePopinInfo(ev, index)}
                
                />
            </Box>
            </CardContent>
        </Box>
        </Grid>
        </Grid>
        </Paper>         
    </div>

    ))}
    <Grid container >
      <Grid item xs={3} style={{marginTop: 30}}>
      </Grid>
      <Grid item xs={3} style={{marginTop: 30}}>
        <Button onClick={onSubmit4} style={{color: 'white', backgroundColor: 'darkblue', width: 200}} variant="outlined" >
          Add Section
        </Button>  
      </Grid>
      <Grid item xs={3} style={{marginTop: 30}}>
        <Button onClick={handleUpdate4Back} style={{color: 'white', backgroundColor: 'grey', width: 200}} variant="outlined" >
          BACK
        </Button>         
      </Grid>
      <Grid item xs={3} style={{marginTop: 30}}>
        <Button onClick={handleUpdate4} style={{color: 'white', backgroundColor: 'green', width: 200}} variant="outlined" >
          VALIDATE
        </Button>
      </Grid>
    </Grid>
  </div>
  );
  }

  //** SNIFF SETUP WIZARD STEP */
  
  interface NewSniffTestSetupProps {
    testid?: string,
    generalinfo?: any,
    isBusy?: boolean;
    onSubmit3: (sniffSettings: []) => void;
    onDuplicate: (sniffSettings: [], index: any, e:any, sort_position: any) => void;
    onDeleteSniff: (id: any, e:any) => void;
    onUpdate3: (sniffSettings: []) => void;
    handleBack: any;
  }
  
  function SniffTestSetup(p: NewSniffTestSetupProps) {

    const generalinfo = p.generalinfo
    const {data: channelname, refetch: refetchchanelname} = useQuery(GetChannelName, {fetchPolicy: "network-only", variables: {project_id: generalinfo.id}})
    const onSubmit3 = () => { p.onSubmit3(sniffSettings)};
    const handleBack = () => {p.handleBack()}
    const handleDuplicate = (index: any, e:any, sort_position: any) => { p.onDuplicate(sniffSettings, index, e, sort_position)};
    const handleDeleteSniff = (id: any, e:any) => { p.onDeleteSniff(id, e)};
    const handleUpdate3 = () => { p.onUpdate3(sniffSettings)};
    const [channelNames, setChannelNames] = useState(channelname)
    const [sniffSettings, setSniffSettings] = useState(generalinfo.mixes);
    const [swapMixes] = useMutation(SWAP_MIX)
  
    useEffect(() => {
      if(generalinfo?.name){
        setSniffSettings(generalinfo.mixes)
        setChannelNames(refetchchanelname({project_id: generalinfo.id}))
      }
    }, [generalinfo]);
  
    const updateName = (e:any, index:number) => {
      const newSniffSettings = [...sniffSettings]
      newSniffSettings[index] = {...newSniffSettings[index], name: e.target.value}
      setSniffSettings(newSniffSettings)
    }
  
    const getChannelName1 = (thisindex: any) => {
      if(channelname) {
        return channelname.project_channel[thisindex].name
      } else {
        return ""
      }
    }
  
    const incrementFlow = (event: any, channelindex: any, sniffindex:any) => {
    const newSniffSettings = JSON.parse(JSON.stringify(sniffSettings))
    if (newSniffSettings[sniffindex].channels[channelindex] < 100) {
        newSniffSettings[sniffindex].channels[channelindex] = newSniffSettings[sniffindex].channels[channelindex] + 20
    }
    setSniffSettings(newSniffSettings)
    }
  
    const decrementFlow = (event: any, channelindex: any, sniffindex: any) => {
    const newSniffSettings = JSON.parse(JSON.stringify(sniffSettings))
    if (newSniffSettings[sniffindex].channels[channelindex] > 0) {
      newSniffSettings[sniffindex].channels[channelindex] = newSniffSettings[sniffindex].channels[channelindex] - 20
    }
    setSniffSettings(newSniffSettings)
    }

    
    const onMixMoved = (sniffIndex: number, direction: string, e:any) => {
      e.stopPropagation()
      const nextMix = sniffSettings[direction === 'up' ? sniffIndex - 1 : sniffIndex + 1];
      const nextMixId = nextMix.id
      const nextMixSortPosition = nextMix.sort_position
      const thisMixID = sniffSettings[sniffIndex].id
      const thisMixSortPosition = sniffSettings[sniffIndex].sort_position
      console.log(thisMixID + " - " + nextMixSortPosition + " - " + nextMixId + " - " + thisMixSortPosition)
      swapMixes({
          variables: {
              mixid: thisMixID,
              sort_position: nextMixSortPosition
          }}).then ((resp) => {
          if (resp.data){
              swapMixes({
                  variables: {
                      mixid: nextMixId,
                      sort_position: thisMixSortPosition
                  }}).then ((resp2) => {
                  if (resp2.data){
                    console.log(resp2.data.update_MIX_mix.returning[0].project.mixes)
                      setSniffSettings(resp2.data.update_MIX_mix.returning[0].project.mixes)   
                  }
                  }).catch(e => {
                  console.log(e);         
                  })
          }
          }).catch(e => {
          console.log(e);         
          })
      }
  
    return (
    <div style={{marginTop:50}}>
    { sniffSettings.length != 0 && sniffSettings?.map((mix: any, index: any) => (
    <Accordion style={{ marginTop: '15px', border: '1px solid gray' }} >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      > 
      <Grid container spacing={2}>
        <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }}>
          <Menu></Menu>&nbsp;{mix.name}</Typography>
        </Grid>
        <Grid item xs={2}>

          <Box><Button onClick={(e) => handleDuplicate(index, e, mix.sort_position)} >Duplicate</Button></Box>


        </Grid>
        <Grid item xs={2}>
          <Box>&nbsp;&nbsp;<Button onClick={(e) => handleDeleteSniff(mix.id, e)}>Delete</Button></Box>
        </Grid>
        <Grid item xs={2} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <Button onClick={(e) => onMixMoved(index, "up", e)}>
            move up
            </Button>
        </Grid>
        <Grid item xs={2} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <Button onClick={(e) => onMixMoved(index, "down", e)}>
                move down
            </Button>            
        </Grid>

      </Grid>
      </AccordionSummary>


      <Grid container spacing={2} style={{paddingBottom: 25}}>

      <AccordionDetails style={{paddingBottom:40}}>
      <Grid container spacing={2}>

        <Grid item xs={1}>
        </Grid>
        <Grid item xs={5} style={{marginBottom: 40, marginTop: 20}}>
          <Box>
            <Typography variant="subtitle1" style={{marginTop: '20px'}} >Name</Typography>
          </Box>
          <Box marginTop="10px">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              defaultValue={mix.name}
              onChange={(ev) => updateName(ev, index)}
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={4} style={{marginBottom: 40, marginTop: 20}}>
          <Box>
            <Typography variant="subtitle1" style={{marginTop: '20px'}}>Random Code</Typography>
          </Box>
          <Box marginTop="20px">
          <Typography variant="subtitle1" color="primary" >{mix.description}</Typography>
          </Box>
        </Grid>
        { //* ************************************************************CHANNELS 1 AND 5**************************************************************************
        }
        <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginLeft:35,
          }} >
          1.{getChannelName1(0)}</Typography>
        </Grid>
        <Grid item xs={2}  style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              paddingLeft: 20,
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 0, index)}></RemoveCircleOutline>&nbsp;
          <div style={{
              borderStyle: "solid",
              borderWidth: 4,
              borderColor: "#4A1690",
              borderRadius: 50,
              width: "47px",
              height: "47px",
            }}>
          <span style={{
            lineHeight: "47px",
            display: "block",
            textAlign: "center",
          }}>{mix.channels[0]}</span>
          </div>
          &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 0, index)}></AddCircleOutline></Typography>
        </Grid>
        <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          5.{getChannelName1(4)}</Typography>
        </Grid>
        <Grid item xs={2}  style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 4, index)}></RemoveCircleOutline>&nbsp;
          <div style={{
              borderStyle: "solid",
              borderWidth: 4,
              borderColor: "#4A1690",
              borderRadius: 50,
              width: "47px",
              height: "47px",
            }}>
          <span style={{
            lineHeight: "47px",
            display: "block",
            textAlign: "center",
          }}>{mix.channels[4]}</span>
          </div>
          &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 4, index)}></AddCircleOutline></Typography>
        </Grid>
        { //* ************************************************************CHANNELS 2 AND 6**************************************************************************
        }
        <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginLeft:35,
          }} >
          2.{getChannelName1(1)}</Typography>
        </Grid>
        <Grid item xs={2}  style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              paddingLeft: 20,
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 1, index)}></RemoveCircleOutline>&nbsp;
          <div style={{
              borderStyle: "solid",
              borderWidth: 4,
              borderColor: "#4A1690",
              borderRadius: 50,
              width: "47px",
              height: "47px",
            }}>
          <span style={{
            lineHeight: "47px",
            display: "block",
            textAlign: "center",
          }}>{mix.channels[1]}</span>
          </div>
          &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 1, index)}></AddCircleOutline></Typography>
        </Grid>
        <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          6.{getChannelName1(5)}</Typography>
        </Grid>
        <Grid item xs={2}  style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 5, index)}></RemoveCircleOutline>&nbsp;
          <div style={{
              borderStyle: "solid",
              borderWidth: 4,
              borderColor: "#4A1690",
              borderRadius: 50,
              width: "47px",
              height: "47px",
            }}>
          <span style={{
            lineHeight: "47px",
            display: "block",
            textAlign: "center",
          }}>{mix.channels[5]}</span>
          </div>
          &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 5, index)}></AddCircleOutline></Typography>
        </Grid>
        { //* ************************************************************CHANNELS 3 AND 7**************************************************************************
        }
        <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginLeft:35,
          }} >
          3.{getChannelName1(2)}</Typography>
        </Grid>
        <Grid item xs={2}  style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              paddingLeft: 20,
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 2, index)}></RemoveCircleOutline>&nbsp;
          <div style={{
              borderStyle: "solid",
              borderWidth: 4,
              borderColor: "#4A1690",
              borderRadius: 50,
              width: "47px",
              height: "47px",
            }}>
          <span style={{
            lineHeight: "47px",
            display: "block",
            textAlign: "center",
          }}>{mix.channels[2]}</span>
          </div>
          &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 2, index)}></AddCircleOutline></Typography>
        </Grid>
        <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          7.{getChannelName1(6)}</Typography>
        </Grid>
        <Grid item xs={2}  style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 6, index)}></RemoveCircleOutline>&nbsp;
          <div style={{
              borderStyle: "solid",
              borderWidth: 4,
              borderColor: "#4A1690",
              borderRadius: 50,
              width: "47px",
              height: "47px",
            }}>
          <span style={{
            lineHeight: "47px",
            display: "block",
            textAlign: "center",
          }}>{mix.channels[6]}</span>
          </div>
          &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 6, index)}></AddCircleOutline></Typography>
        </Grid>  
        { //* ************************************************************CHANNELS 4 AND 8**************************************************************************
        }
        <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginLeft:35,
          }} >
          4.{getChannelName1(3)}</Typography>
        </Grid>
        <Grid item xs={2}  style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              paddingLeft: 20,
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 3, index)}></RemoveCircleOutline>&nbsp;
          <div style={{
              borderStyle: "solid",
              borderWidth: 4,
              borderColor: "#4A1690",
              borderRadius: 50,
              width: "47px",
              height: "47px",
            }}>
          <span style={{
            lineHeight: "47px",
            display: "block",
            textAlign: "center",
          }}>{mix.channels[3]}</span>
          </div>
          &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 3, index)}></AddCircleOutline></Typography>
        </Grid>
        <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          8.{getChannelName1(7)}</Typography>
        </Grid>
        <Grid item xs={2}  style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <Typography style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
          }} >
          <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 7, index)}></RemoveCircleOutline>&nbsp;
          <div style={{
              borderStyle: "solid",
              borderWidth: 4,
              borderColor: "#4A1690",
              borderRadius: 50,
              width: "47px",
              height: "47px",
            }}>
          <span style={{
            lineHeight: "47px",
            display: "block",
            textAlign: "center",
          }}>{mix.channels[7]}</span>
          </div>
          &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 7, index)}></AddCircleOutline></Typography>
        </Grid>   
      </Grid>
      </AccordionDetails>
      </Grid>
    </Accordion>
    ))}
    <Grid container >
      <Grid item xs={3} style={{marginTop: 30}}></Grid>
      <Grid item xs={3} style={{marginTop: 30}}>
      <Button onClick={onSubmit3} style={{color: 'white', backgroundColor: 'darkblue', width: 200}} variant="outlined" >
        Add Sniff
      </Button> 
      </Grid>
      <Grid item xs={3} style={{marginTop: 30}}>
        <Button onClick={handleBack} style={{color: 'white', backgroundColor: 'grey', width: 200}} variant="outlined" >
          BACK
        </Button>         
      </Grid>
      <Grid item xs={3} style={{marginTop: 30}}>
        <Button onClick={handleUpdate3} style={{color: 'white', backgroundColor: 'green', width: 200}} variant="outlined" >
          NEXT
        </Button>
      </Grid>
    </Grid>
</div>
    );
    }