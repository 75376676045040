import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const mutation = gql`
mutation CreateNewProject($name: String!, $image: String, $description: String, $sniff_and_rate_description: String, $session_type: String, $channels: [project_channel_insert_input!]!, $owner_id: uuid!, $play_around_type: String, $preference_question: String, $preference_positive: String, $preference_negative: String, $sniff_test_length: Int, $sniff_rating_scale: Int  ) {
  insert_project_one(object: {survey: {data: {name: $name, internal_name: $name}}, name: $name, session_type: $session_type, channels: {data: $channels}, owner_id: $owner_id, image: $image, description: $description, sniff_and_rate_description: $sniff_and_rate_description, preference_question: $preference_question, play_around_type: $play_around_type, preference_positive: $preference_positive, preference_negative: $preference_negative, sniff_test_length: $sniff_test_length, sniff_rating_scale: $sniff_rating_scale}) {
    id
    image
    play_around_type
    preference_negative
    preference_positive
    preference_question
    sniff_and_rate_description
    session_type
    name
    description
    sniff_test_length
    sniff_rating_scale
    channels(order_by: {channel_index: asc}) {
      channel_index
      description
      name
      image
      duration
      id
      enabled
      channel_type
    }
    mixes {
      channels
      name
      id
      description
    }
    survey_id
    survey {
      name
      internal_name
      id
      description
      SUR_sections(order_by: {sort_position: asc}) {
        ID
        title
        description
        image
        survey_id
        sort_position
        SUR_survey_questions(order_by: {sort_position: asc}) {
          description
          id
          max_value
          min_value
          mixes
          name
          options {
            id
            question_id
            sort_position
            text
          }
        }
      }
    }
  }
}
`;

export const createGuidedPopins = gql`
mutation createGuidedPopins($project_id: uuid!) {
  insert_project_guided_popins_one(object: {popin_one: "", popin_three: "", popin_two: "", project_id: $project_id}) {
    id
    project_id
    popin_one
    popin_three
    popin_two
  }
}
`

export interface CreateNewProjectData {
  insert_project_one: {
    id: string;
    image: string;
    description: string;
    session_type?: string;
    sniff_and_rate_description?: string;
    play_around_type?: string;
    preference_question?: string;
    preference_positive?: string;
    preference_negative?: string;
  };
}

export interface CreateNewProjectVariables {
  name: string;
  sniff_test_length: number;
  sniff_rating_scale: number;
  image: string;
  description: string;
  play_around_type?: string;
  preference_question?: string;
  preference_positive?: string;
  preference_negative?: string;
  sniff_and_rate_description?: string
  session_type?: string;
  owner_id: string;
  channels: {
    name: string;
    channel_index: number;
    duration?: number;
    enabled?: boolean;
  }[];
}

export function useCreateNewProject(
  options?:
    | MutationHookOptions<CreateNewProjectData, CreateNewProjectVariables>
    | undefined
) {
  return useMutation<CreateNewProjectData, CreateNewProjectVariables>(
    mutation,
    options
  );
}
