import React, { useState } from "react";
import { css } from "@emotion/react";
import { Image } from "@material-ui/icons";

const cssParent = css`
text-align: center;
background-color: #eeeeee ;
width:100%;
height: 150px;
margin-top: 5px;
color: white;
border-radius: 5px;
vertical-align: middle;
`

const cssImage = css`
height: 100%;
width: 100%;
object-fit: contain;
max-height: inherit;
`

const cssErrorText = css`
  color: white;
  vertical-align: middle;
  padding: 5px;
  line-height:150px;
`

const variantStyles = {
  "default": null,
  "icon": css`
    width: 125px;
    height: 75px;
  `
}

interface LoadableImageProps {
  url?: string;
  variant?: "icon"
}

const LoadableImage = (p: LoadableImageProps) => {
  const [error, setError] = useState(false);

  const handleOnError = () => {
    setError(true);
  }

  return (<div css={[cssParent, variantStyles[p.variant || "default"]]} {...p}>
    {error ? <span css={cssErrorText}>Error loading image</span> : p.url ? <img alt="" css={cssImage} src={p.url} onError={handleOnError}/> : <span css={cssErrorText}><Image fontSize="large"></Image></span>}
    </div>);
}

export default LoadableImage;

