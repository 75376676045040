import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const INSERT_QUESTION_MUTATION = gql`
mutation InsertQuestion($id: uuid!, $survey_section_ID: uuid!, $name: String!, $type: String!, $sort_position: Int!, $required: Boolean = false, $description: String = null) {
  insert_SUR_survey_question_one(object: {id: $id, name: $name, type: $type, sort_position: $sort_position, required: $required, description: $description, survey_section_ID: $survey_section_ID}) {
    id
    name
    description
    required
    sort_position
    type
    survey_section_ID
  }
}
`;

export interface InsertQuestionData {
  insert_SUR_survey_question_one: {
    id: string;
    name: string;
    description: string | null;
    required: boolean;
    sort_position: number;
    survey_section_ID: string;
    type: string;
  }
}

export interface InsertQuestionVariables {
  id: string;
  survey_section_ID: string;
  name: string;
  sort_position: number;
  type: string;
  required?: boolean | null;
  description?: boolean | null;
}

export function useInsertQuestionMutation(
  options?:
    | MutationHookOptions<InsertQuestionData, InsertQuestionVariables>
    | undefined
) {
  return useMutation<InsertQuestionData, InsertQuestionVariables>(
    INSERT_QUESTION_MUTATION,
    options
  );
}
