import {
  Box,
  Paper,
  Grid,
  Container,
  Button,
  InputLabel,
  Card,
  IconButton,
  CardContent,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  SnackbarOrigin,
  SnackbarContent

} from "@material-ui/core";
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { RemoveCircle, Edit, ExpandMore, Menu, AddCircleOutline, RemoveCircleOutline, ControlPoint, CheckCircleOutline, UpdateOutlined, DeleteOutline} from "@material-ui/icons";
import LoadableImage from "../components/LoadableImage";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUpload from "../components/ImageUpload";
import { range } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../SessionContext";
import { useCreateNewProject } from "./queries/CreateProject";
import {gql, useQuery, useMutation} from '@apollo/client'
import EditSurvey from "../surveys/tabs/EditSurvey";
import { max } from "lodash";

export interface NewSessionProps {
}

const steps = ['General Information', 'Channel Settings', 'Sniff Set-Up', 'Survey Set-Up'];
const steps2 = ['General Information','Survey Set-Up'];

const getGeneralInfo =  gql`
query getGeneralInfo($id: uuid) {
  project(where: {id: {_eq: $id}}) {
    id
    image
    play_around_type
    preference_negative
    preference_positive
    preference_question
    sniff_and_rate_description
    session_type
    name
    description
    sniff_test_length
    sniff_rating_scale
    channels(order_by: {channel_index: asc}) {
      channel_index
      description
      name
      image
      duration
      id
      enabled
      channel_type
    }
    mixes(order_by: {sort_position: asc}) {
      channels
      name
      id
      description
      sort_position
    }
    survey_id
    survey {
      name
      internal_name
      id
      description
      SUR_sections(order_by: {sort_position: asc}) {
          ID
          title
          description
          image
          survey_id
          sort_position
          popin_info
        SUR_survey_questions(order_by: {sort_position: asc}) {
          description
          id
          max_value
          min_value
          mixes
          name
          options {
            id
            question_id
            sort_position
            text
          }
        }
      }
    }
    project_customers(order_by: {app_user: {email: asc}}) {
      app_user {
        email
        id
      }
    }
    project_consumers(order_by: {app_user: {email: asc}}) {
      app_user {
        email
        id
      }
    }
  }
}
`

const updateGeneralInfo = gql`
mutation updateGeneralInfo($id: uuid, $image: String, $play_around_type: String, $preference_negative: String, $preference_positive: String, $preference_question: String, $session_type: String, $name: String, $sniff_and_rate_description:String, $description: String, $sniff_test_length: Int, $sniff_rating_scale: Int  ) {
  update_project(where: {id: {_eq: $id}}, _set: {image: $image, play_around_type: $play_around_type, preference_negative: $preference_negative, preference_positive: $preference_positive, preference_question: $preference_question, session_type: $session_type, name: $name, sniff_and_rate_description: $sniff_and_rate_description, description: $description, sniff_test_length: $sniff_test_length, sniff_rating_scale: $sniff_rating_scale}) {
    returning {
      id
      image
      play_around_type
      preference_negative
      preference_positive
      preference_question
      sniff_and_rate_description
      session_type
      name
      description
      sniff_test_length
      sniff_rating_scale
      channels(order_by: {channel_index: asc}) {
        channel_index
        description
        name
        image
        duration
        id
        enabled
        channel_type
      }
      mixes(order_by: {sort_position: asc}) {
        channels
        name
        id
        description
        sort_position
      }
      survey_id
      survey {
        name
        internal_name
        id
        description
        SUR_sections(order_by: {sort_position: asc}) {
            ID
            title
            description
            image
            survey_id
            sort_position
            popin_info
          SUR_survey_questions(order_by: {sort_position: asc}) {
            description
            id
            max_value
            min_value
            mixes
            name
            options {
              id
              question_id
              sort_position
              text
            }
          }
        }
      }
      project_customers {
        app_user {
          email
          id
        }
      }
      project_consumers {
        app_user {
          email
          id
        }
      }
    }
  }
}
`

const updateChannels = gql`
mutation updateChannels($channelid: uuid!, $channelname: String, $duration: Int!, $description: String, $channel_type: String) {
  update_project_channel(where: {id: {_eq: $channelid}}, _set: {name: $channelname, duration: $duration, description: $description, channel_type: $channel_type}) {
    returning {
      project {
        id
        image
        play_around_type
        preference_negative
        preference_positive
        preference_question
        sniff_and_rate_description
        session_type
        name
        description
        sniff_test_length
        sniff_rating_scale
        channels(order_by: {channel_index: asc}) {
          channel_index
          description
          name
          image
          duration
          id
          enabled
          channel_type
        }
        mixes(order_by: {sort_position: asc}) {
          channels
          name
          id
          description
          sort_position
        }
        survey_id
        survey {
          name
          internal_name
          id
          description
          SUR_sections(order_by: {sort_position: asc}) {
              ID
              title
              description
              image
              survey_id
              sort_position
              popin_info
            SUR_survey_questions(order_by: {sort_position: asc}) {
              description
              id
              max_value
              min_value
              mixes
              name
              options {
                id
                question_id
                sort_position
                text
              }
            }
          }
        }
      }
    }
  }
}
`

const addSniff = gql`
mutation addSniff($project_id: uuid!, $description: String, $sort_position: Int!) {
  insert_MIX_mix_one(object: {name: "NEW SNIFF", project_id: $project_id, description: $description, sort_position: $sort_position, channels: "{0,0,0,0,0,0,0,0}"}) {
    id
    project {
      id
      image
      play_around_type
      preference_negative
      preference_positive
      preference_question
      sniff_and_rate_description
      session_type
      name
      description
      sniff_test_length
      sniff_rating_scale
      channels(order_by: {channel_index: asc}) {
        channel_index
        description
        name
        image
        duration
        id
        enabled
        channel_type
      }
      mixes(order_by: {sort_position: asc}) {
        channels
        name
        id
        description
        sort_position
      }
      survey_id
      survey {
        name
        internal_name
        id
        description
        SUR_sections(order_by: {sort_position: asc}) {
            ID
            title
            description
            image
            survey_id
            sort_position
            popin_info
          SUR_survey_questions(order_by: {sort_position: asc}) {
            description
            id
            max_value
            min_value
            mixes
            name
            options {
              id
              question_id
              sort_position
              text
            }
          }
        }
      }
    }
  }
}
`

const deletesniff = gql`
mutation deleteSniff($id: uuid!) {
  delete_MIX_mix(where: {id: {_eq: $id}}) {
    returning {
        project {
        id
        image
        play_around_type
        preference_negative
        preference_positive
        preference_question
        sniff_and_rate_description
        session_type
        name
        description
        sniff_test_length
        sniff_rating_scale
        channels(order_by: {channel_index: asc}) {
          channel_index
          description
          name
          image
          duration
          id
          enabled
          channel_type
        }
        mixes(order_by: {sort_position: asc}) {
          channels
          name
          id
          description
          sort_position
        }
        survey_id
        survey {
          name
          internal_name
          id
          description
          SUR_sections(order_by: {sort_position: asc}) {
            ID
            title
            description
            image
            survey_id
            sort_position
            popin_info
            SUR_survey_questions(order_by: {sort_position: asc}) {
              description
              id
              max_value
              min_value
              mixes
              name
              options {
                id
                question_id
                sort_position
                text
              }
            }
          }
        }          
      }
    }
  }
}
`

const duplicateSniff = gql`
mutation addSniff($project_id: uuid!, $name: String, $description: String, $channels: _int4, $sort_position: Int) {
  insert_MIX_mix_one(object: {name: $name, description: $description, project_id: $project_id, channels: $channels, sort_position: $sort_position}) {
    id
    project {
      id
      image
      play_around_type
      preference_negative
      preference_positive
      preference_question
      sniff_and_rate_description
      session_type
      name
      description
      sniff_test_length
      sniff_rating_scale
      channels(order_by: {channel_index: asc}) {
        channel_index
        description
        name
        image
        duration
        id
        enabled
        channel_type
      }
      mixes(order_by: {sort_position: asc}) {
        channels
        name
        id
        description
        sort_position
      }
      survey_id
      survey {
        name
        internal_name
        id
        description
        SUR_sections(order_by: {sort_position: asc}) {
            ID
            title
            description
            image
            survey_id
            sort_position
            popin_info
          SUR_survey_questions(order_by: {sort_position: asc}) {
            description
            id
            max_value
            min_value
            mixes
            name
            options {
              id
              question_id
              sort_position
              text
            }
          }
        }
      }
    }
  }
}
`

const updateSniffTestSettings = gql`
mutation updateSniff($sniff_id: uuid!, $channels: _int4!, $name: String, $description: String) {
  update_MIX_mix(where: {id: {_eq: $sniff_id}}, _set: {channels: $channels, name: $name, description: $description}) {
    returning {
    id
    project {
      id
      image
      play_around_type
      preference_negative
      preference_positive
      preference_question
      sniff_and_rate_description
      session_type
      name
      description
      sniff_test_length
      sniff_rating_scale
      channels(order_by: {channel_index: asc}) {
        channel_index
        description
        name
        image
        duration
        id
        enabled
        channel_type
      }
      mixes(order_by: {sort_position: asc}) {
        channels
        name
        id
        description
        sort_position
      }
      survey_id
      survey {
        name
        internal_name
        id
        description
        SUR_sections(order_by: {sort_position: asc}) {
            ID
            title
            description
            image
            survey_id
            sort_position
            popin_info
          SUR_survey_questions(order_by: {sort_position: asc}) {
            description
            id
            max_value
            min_value
            mixes
            name
            options {
              id
              question_id
              sort_position
              text
            }
          }
        }
      }
    }
    }
  }
}
`

const addSection = gql`
mutation addSection($survey_id: uuid!, $sort_position: Int) {
  insert_SUR_section(objects: {survey_id: $survey_id, sort_position: $sort_position}) {
    returning {
      SUR_survey {
        project {
        id
        image
        play_around_type
        preference_negative
        preference_positive
        preference_question
        sniff_and_rate_description
        session_type
        name
        description
        sniff_test_length
        sniff_rating_scale
        channels(order_by: {channel_index: asc}) {
          channel_index
          description
          name
          image
          duration
          id
          enabled
          channel_type
        }
        mixes(order_by: {sort_position: asc}) {
          channels
          name
          id
          description
          sort_position
        }
        survey_id
        survey {
          name
          internal_name
          id
          description
          SUR_sections(order_by: {sort_position: asc}) {
              ID
              title
              description
              image
              survey_id
              sort_position
              popin_info
            SUR_survey_questions(order_by: {sort_position: asc}) {
              description
              id
              max_value
              min_value
              mixes
              name
              options {
                id
                question_id
                sort_position
                text
              }
            }
          }
        }          
        }
      }
    }
  }
}
`

const deleteSection = gql`
mutation deleteSection($section_id: uuid!) {
  delete_SUR_section(where: {ID: {_eq: $section_id}}) {
    returning {
        SUR_survey {
          project {
          id
          image
          play_around_type
          preference_negative
          preference_positive
          preference_question
          sniff_and_rate_description
          session_type
          name
          description
          sniff_test_length
          sniff_rating_scale
          channels(order_by: {channel_index: asc}) {
            channel_index
            description
            name
            image
            duration
            id
            enabled
            channel_type
          }
          mixes(order_by: {sort_position: asc}) {
            channels
            name
            id
            description
            sort_position
          }
          survey_id
          survey {
            name
            internal_name
            id
            description
            SUR_sections(order_by: {sort_position: asc}) {
              ID
              title
              description
              image
              survey_id
              sort_position
              popin_info
              SUR_survey_questions(order_by: {sort_position: asc}) {
                description
                id
                max_value
                min_value
                mixes
                name
                options {
                  id
                  question_id
                  sort_position
                  text
                }
              }
            }
          }          
          }
        }
    }
  }
}
`

const updateSections = gql`
mutation updateSections($sectionid: uuid!, $image: String, $description: String, $title: String, $popin_info: String) {
  update_SUR_section(where: {ID: {_eq: $sectionid}}, _set: {image: $image, description: $description, title: $title, popin_info: $popin_info}) {
    returning {
      SUR_survey {
        project {
          id
          image
          play_around_type
          preference_negative
          preference_positive
          preference_question
          sniff_and_rate_description
          session_type
          name
          description
          sniff_test_length
          sniff_rating_scale
          channels(order_by: {channel_index: asc}) {
            channel_index
            description
            name
            image
            duration
            id
            enabled
            channel_type
          }
          mixes(order_by: {sort_position: asc}) {
            channels
            name
            id
            description
            sort_position
          }
          survey_id
          survey {
            name
            internal_name
            id
            description
            SUR_sections(order_by: {sort_position: asc}) {
              ID
              title
              description
              image
              survey_id
              sort_position
              popin_info
              SUR_survey_questions(order_by: {sort_position: asc}) {
                description
                id
                max_value
                min_value
                mixes
                name
                options {
                  id
                  question_id
                  sort_position
                  text
                }
              }
            }
          }
        }
      }
    }
  }
}
`

const codeSearch = gql`
query codeLookup($code: String!) {
   MIX_mix(where: {description: {_eq: $code}}) {
     description
   }
 }  
`

export interface State extends SnackbarOrigin {
  open: boolean;
}

export function NewSniffTest() {
  const [createNewProject] = useCreateNewProject();
  const [isBusy, setIsBusy] = useState(false);
  const [testId, setTestId] = useState("0");
  const [generalInfo, setGeneralInfo] = useState({})
  const userId = useUserId();
  const [isDisabled, setIsDisabled] = useState(true)
  let id = "0"
  const {data: generalinfo, loading: loading, refetch: refetchgeneral} = useQuery(getGeneralInfo, {fetchPolicy: "network-only", variables: {id: id}})
  const [updateGeneral] = useMutation(updateGeneralInfo)
  const [updateChannelSettings] = useMutation(updateChannels)
  const [addSniffTest] = useMutation(addSniff)
  const [addDuplicateSniff] = useMutation(duplicateSniff)
  const [updateSniffTestSet] = useMutation(updateSniffTestSettings)
  const [addSurveySection] = useMutation(addSection)
  const [deleteSurveySection] = useMutation(deleteSection)
  const [updateSurveySections] = useMutation(updateSections)
  const [deleteSniff] = useMutation(deletesniff)
  const [theSteps, setTheSteps] = useState(steps)
  const {data: codesearchResults, refetch: refetchCodeSearch} = useQuery(codeSearch, {fetchPolicy: "network-only", variables: {code: ""}})

  useEffect(() => {
    if(generalinfo){
        setGeneralInfo(generalinfo.project[0])
        if (generalinfo.project[0].session_type == 'End-Product Test') {
          setTheSteps(steps2)
        } else {
          setTheSteps(steps)
        }
    }
  }, [generalinfo]);

  // snackbar stuff
  const [state, setState] = React.useState<State>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });

  const { vertical, horizontal, open } = state;

  const AlertMe = (newState: SnackbarOrigin) => {
      setState({ open: true, ...newState });
  }

  const handleClose1 = () => {
      setState({ ...state, open: false });
  };


  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 10;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = (
    initialName: string,
    inititalSniffLength: string,
    initialSniffRatingScale: string,
    initialSessionType: string,
    initialImage: string,
    initialDescription: string,
    intitialSniffAndRateDescription: string,
    initialPreferenceQuest: string,
    initialPlayAroundType: string,
    initialPositive: string,
    initialNegative: string,
  ) => {
    setIsBusy(true);
    createNewProject({variables: {
      name: initialName,
      image: initialImage || '',
      description: initialDescription || '',
      sniff_and_rate_description: intitialSniffAndRateDescription || '',
      play_around_type: initialPlayAroundType || '',
      preference_question: initialPreferenceQuest || '',
      preference_positive: initialPositive || '',
      preference_negative: initialNegative || '',
      session_type: initialSessionType || undefined,
      sniff_test_length: parseInt(inititalSniffLength) || 5,
      sniff_rating_scale: parseInt(initialSniffRatingScale) || 10,
      channels: range(8).map(index => ({
        name: `Channel ${index + 1}`,
        channel_index: index,
      })),
      owner_id: userId!
    }}).then((resp) => {
      if (resp.data) {
        setTestId(resp.data.insert_project_one.id)
        setGeneralInfo(resp.data.insert_project_one)
        refetchgeneral({id:testId})
        if (initialSessionType == 'End-Product Test') {
          setTheSteps(steps2)
        } else {
          setTheSteps(steps)
        }
        AlertMe({vertical: 'bottom', horizontal: 'center'})
        setIsDisabled(false)
      }
    }).catch(e => {
      setIsBusy(false);
      console.log('Failed to create session');
      console.log(e);
    })

  };

  const handleUpdate = (
    initialName: string,
    inititalSniffLength: string,
    initialSniffRatingScale: string,
    initialSessionType: string,
    initialImage: string,
    initialDescription: string,
    intitialSniffAndRateDescription: string,
    initialPreferenceQuest: string,
    initialPlayAroundType: string,
    initialPositive: string,
    initialNegative: string,
  ) => {

    setIsBusy(true);
    updateGeneral({variables: {
      id: testId,
      name: initialName,
      image: initialImage || '',
      description: initialDescription || '',
      sniff_and_rate_description: intitialSniffAndRateDescription || '',
      play_around_type: initialPlayAroundType || '',
      preference_question: initialPreferenceQuest || '',
      preference_positive: initialPositive || '',
      preference_negative: initialNegative || '',
      session_type: initialSessionType || undefined,
      sniff_test_length: parseInt(inititalSniffLength) || 5,
      sniff_rating_scale: parseInt(initialSniffRatingScale) || 10,  
    }}).then((resp) => {
      if (resp.data) {
        AlertMe({vertical: 'bottom', horizontal: 'center'})
        if (resp.data.update_project.returning[0].session_type == 'End-Product Test') {
          setTheSteps(steps2)
        } else {
          setTheSteps(steps)
        }
        setGeneralInfo(resp.data.update_project.returning[0])
      }
    }).catch(e => {
      setIsBusy(false);
      console.log('Failed to update session');
      console.log(e);
    })

  };

  const handleUpdate2 = (thischannelsettings: []) => {
      setIsBusy(true);

      thischannelsettings.map(channel => {
        updateChannelSettings({variables: {
          channelid: channel['id'],
          channelname:channel['name'],
          channel_type:channel['channel_type'],
          duration:channel['duration'],
          description:channel['description']
        }}).then ((resp) => {
          if (resp.data){
            setGeneralInfo(resp.data.update_project_channel.returning[0].project)
          }
        }).catch(e => {
          setIsBusy(false);
          console.log('Failed to update channels');
          console.log(e);         
        })
      });
      refetchgeneral({id:testId})
      AlertMe({vertical: 'bottom', horizontal: 'center'})

  };

  async function handleSubmit3(allinfo: any, sniffSettings: any[]) {

    if (sniffSettings.length > 0) {
      handleUpdate3(sniffSettings)
    }

    let promise = new Promise((resolve, reject) => {
        let text = "";
        let charset = "abcdefghijklmnopqrstuvwxyz"
        for (var i = 0; i < 2; i++)
        text += charset.charAt(Math.floor(Math.random() * charset.length)); 
        text = text.toUpperCase()
        let numbers = "";
        let charset2 = "0123456789"
        for (var i = 0; i < 2; i++)
        numbers += charset2.charAt(Math.floor(Math.random() * charset2.length)); 
        refetchCodeSearch({code: text+numbers}).then ((resp) => {
            if (resp.data.MIX_mix.length > 0) {
                handleSubmit3(allinfo, sniffSettings)
            } else {
                resolve(text+numbers)
            }
        }).catch(e => {

        })           
    })
    const thecode = await promise
    let highestSortPosition = 0
    highestSortPosition =
    max(
      (allinfo.mixes || []).map(
        (mix: any) => mix.sort_position
      )
    ) || 0;
    const newSortPosition = highestSortPosition + 1000 + Math.round(Math.random() * 100);
    addSniffTest({variables: {
    project_id: testId,
    description: thecode,
    sort_position: newSortPosition
    }}).then((resp) => {
    if (resp.data) {
        setGeneralInfo(resp.data.insert_MIX_mix_one.project)
        AlertMe({vertical: 'bottom', horizontal: 'center'})
    }
    }).catch(e => {
    setIsBusy(false);
    console.log('Failed to create sniff test');
    console.log(e);
    })
  };

  //delete sniff
  const handleDeleteSniff = ( id: string, e:Event) => {
    e.stopPropagation()
    setIsBusy(true);
    deleteSniff({variables: {
      id: id,
    }}).then((resp) => {
      if (resp.data) {
        setGeneralInfo(resp.data.delete_MIX_mix.returning[0].project)
        AlertMe({vertical: 'bottom', horizontal: 'center'})
      }
    }).catch(e => {
      setIsBusy(false);
      console.log('Failed to delete section');
      console.log(e);
    })
  };

  async function handleDuplicate( sniffSettings: any[], index: any, allinfo: any, e:Event, sort_position: any) {
    handleUpdate3(sniffSettings)
    e.stopPropagation()
    setIsBusy(true);
    let promise = new Promise((resolve, reject) => {
      let text = "";
      let charset = "abcdefghijklmnopqrstuvwxyz"
      for (var i = 0; i < 3; i++)
      text += charset.charAt(Math.floor(Math.random() * charset.length)); 
      text = text.toUpperCase()
      let numbers = "";
      let charset2 = "0123456789"
      for (var i = 0; i < 3; i++)
      numbers += charset2.charAt(Math.floor(Math.random() * charset2.length)); 
      refetchCodeSearch({code: text+numbers}).then ((resp) => {
          if (resp.data.MIX_mix.length > 0) {
              handleSubmit3(allinfo, sniffSettings)
          } else {
              resolve(text+numbers)
          }
      }).catch(e => {

      })           
    })
    const thecode = await promise
    addDuplicateSniff({variables: {
      project_id: testId,
      description: thecode,
      sort_position: sort_position + 1,
      name: sniffSettings[index]["name"] + " *duplicate",
      channels: `{${sniffSettings[index]["channels"].join(", ")}}`,
      owner_id: userId!
    }}).then((resp) => {
      if (resp.data) {
        setGeneralInfo(resp.data.insert_MIX_mix_one.project)
        AlertMe({vertical: 'bottom', horizontal: 'center'})
      }
    }).catch(e => {
      setIsBusy(false);
      console.log('Failed to create duplicate sniff');
      console.log(e);
    })
  };

  const handleUpdate3 = ( sniffSettings: any[]) => {
    setIsBusy(true);
    sniffSettings.map(sniff => {
      updateSniffTestSet({variables: {
        sniff_id: sniff["id"],
        description: sniff["description"],
        name: sniff["name"],
        channels: `{${sniff["channels"].join(", ")}}`,
      }}).then ((resp) => {
        if (resp.data){
          setGeneralInfo(resp.data.update_MIX_mix.returning[0].project)
        }
      }).catch(e => {
        setIsBusy(false);
        console.log('Failed to update sniff');
        console.log(e);         
      })
    });
    refetchgeneral({id:testId})
    AlertMe({vertical: 'bottom', horizontal: 'center'})
  };

    //add survey section
    const handleSubmit4 = ( surveyid: string, sections: []) => {
      let highestSortPosition = 0
      highestSortPosition =
      max(
        (sections || []).map(
          (section: any) => section.sort_position
        )
      ) || 0;
    const newSortPosition = highestSortPosition + 1000 + Math.round(Math.random() * 100);
    setIsBusy(true);
    addSurveySection({variables: {
      survey_id: surveyid,
      sort_position: newSortPosition
    }}).then((resp) => {
      if (resp.data) {
        setGeneralInfo(resp.data.insert_SUR_section.returning[0].SUR_survey.project)
        AlertMe({vertical: 'bottom', horizontal: 'center'})
      }
    }).catch(e => {
      setIsBusy(false);
      console.log('Failed to create section');
      console.log(e);
    })
  };

  //delete survey section
  const handleDelete4 = ( sectionid: string) => {
        setIsBusy(true);
        deleteSurveySection({variables: {
          section_id: sectionid,
        }}).then((resp) => {
          if (resp.data) {
            setGeneralInfo(resp.data.delete_SUR_section.returning[0].SUR_survey.project)
            AlertMe({vertical: 'bottom', horizontal: 'center'})
          }
        }).catch(e => {
          setIsBusy(false);
          console.log('Failed to delete section');
          console.log(e);
        })
      };

  //update Survey Sections
  const handleUpdate4 = ( section: any[]) => {
    setIsBusy(true);
    section.map(sections => {
        updateSurveySections({variables: {
        sectionid: sections["ID"],
        description: sections["description"],
        title: sections["title"],
        image: sections["image"],
        popin_info: sections["popin_info"]
        }}).then ((resp) => {
        if (resp.data){
            setGeneralInfo(resp.data.update_SUR_section.returning[0].SUR_survey.project)
        }
        }).catch(e => {
        setIsBusy(false);
        console.log('Failed to update survey');
        console.log(e);         
        })
    });
    //refetchgeneral({sniffid:p.sessionId})
    AlertMe({vertical: 'bottom', horizontal: 'center'})
    };

  const getStepContent = (step: any) => {
    type ObjectKey = keyof typeof generalInfo;
    const sessiontype = 'session_type' as ObjectKey
    let thistype = generalInfo[sessiontype]
    switch (step) {
      case 0:
        return <NewSessionForm onSubmit={handleSubmit} onUpdate={handleUpdate} isBusy={isBusy} testid={testId} generalinfo={generalInfo} />
      case 1:
        if (thistype == 'End-Product Test') {
          return <SurveySettings onSubmit4={handleSubmit4} onUpdate4={handleUpdate4} onDelete4={handleDelete4} isBusy={isBusy} testid={testId} generalinfo={generalInfo}/>
        } else {
          return <ChannelSettings onUpdate2={handleUpdate2} isBusy={isBusy} testid={testId} generalinfo={generalInfo}/>
        }
      case 2:
        return <SniffTestSetup onSubmit3={handleSubmit3} onDeleteSniff={handleDeleteSniff} onUpdate3={handleUpdate3} onDuplicate={handleDuplicate} testid={testId} generalinfo={generalInfo} isBusy={isBusy}/>
      case 3:
        return <SurveySettings onSubmit4={handleSubmit4} onUpdate4={handleUpdate4} onDelete4={handleDelete4} isBusy={isBusy} testid={testId} generalinfo={generalInfo}/>
    }
  }

  if (loading) {
    return (<div>loading data</div>)
  }

  return (
    <Container>
      <Box display="flex" justifyContent="center" width="100%">
        <Stepper activeStep={activeStep} style={{ border: '1px solid gray' }}>
        {theSteps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} >{label}</StepLabel>       
              </Step>
            );
          })}
        {activeStep === theSteps.length - 1 ? (
          <React.Fragment>
            <Typography>

            </Typography>
            <Box display="flex" flexDirection="row">
              <Box display="flex"/>
              <Button onClick={handleBack}>Back</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box  display="flex" flexDirection="row">
              <Button
                color="primary"
                disabled={activeStep === 0}
                onClick={handleBack}            
              >
                Back
              </Button>
              <Box display="flex" />
              {isStepOptional(activeStep) && (
                <Button color="default" onClick={handleSkip}>
                  Skip
                </Button>
              )}
              <Button onClick={handleNext} color="primary" disabled={isDisabled}>
                {activeStep === theSteps.length - 1 ? '' : 'NEXT'}
              </Button>
            </Box>
          </React.Fragment>
        )}       
        </Stepper>
        </Box>
        <Box>
          <Box marginTop="20px">
            <div style={{ width: '100%' }}>{getStepContent(activeStep)}</div>
          </Box>
        </Box>
        <Snackbar open={open} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal}} key={vertical + horizontal} onClose={handleClose1} style={{marginLeft: '120px', color: 'green'}}>
            <div>       
            <SnackbarContent style={{
                backgroundColor:'green',
                minWidth: 800,
                display: 'block',
                textAlign: 'center'
                }}
                message={<div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} id="client-snackbar"><CheckCircleOutline/>&nbsp;&nbsp;Added Successfully!</div>}
            />
            </div>
          </Snackbar>  
      </Container>
  );
}

interface NewSessionFormProps {
  testid?: string,
  generalinfo?: any,
  isBusy?: boolean;
  initialName?: string;
  initialSessionType?: string;
  initialPlayAroundType?: string;
  initialPreferenceQuest?: string;
  initialPositive?: string;
  initialNegative?: string;
  initialImage?: string;
  intitialDescription?: string;
  intitialSniffAndRateDescription?: string

  onSubmit: (sessionName: string, sniffLength: string, sniffRatingScale: string, sessionType: string, image: string, description: string, sniffAndRateDescription: string, preferenceQuest: string, playAroundType: string, positiveWording: string, negativeWording: string) => void;
  onUpdate: (sessionName: string, sniffLength: string, sniffRatingScale: string, sessionType: string, image: string, description: string, sniffAndRateDescription: string, preferenceQuest: string, playAroundType: string, positiveWording: string, negativeWording: string) => void;
}

function NewSessionForm(p: NewSessionFormProps) {

  const EMAIL_LOOKUP = gql`
  query emailLookup($email: String) {
      app_user(where: {email: {_eq: $email}}) {
        id
        email
      }
    }
  `

  const ADD_CUSTOMER = gql`
  mutation insertProjectCustomer($user_id: uuid!, $project_id: uuid!) {
    insert_project_customers(objects: {user_id: $user_id, project_id: $project_id}) {
      returning {
        project {
          project_customers {
            app_user {
              id
              email
            }
          }
          channels {
            id
            duration
          }
        }
        project_id
      }
    }
  }
  `

  const ADD_OIL_USER = gql`
  mutation addOilUser($app_user_id: uuid!, $channel_id: uuid!, $oil_level: Int!, $project_id: uuid!) {
    insert_project_channel_oil(objects: {app_user_id: $app_user_id, channel_id: $channel_id, oil_level: $oil_level, project_id: $project_id}) {
      returning {
        app_user_id
        channel_id
        id
        oil_level
        project_id
        project {
          project_customers(order_by: {app_user: {email: asc}}) {
            app_user {
              email
              id
            }
          }
          project_consumers(order_by: {app_user: {email: asc}}) {
            app_user {
              email
              id
            }
          }
        }
      }
    }
  }
  `

  const NO_ADMIN = gql`
  mutation noAdmin($userid: uuid) {
    update_app_user(where: {id: {_eq: $userid}}, _set: {is_admin: false}) {
      returning {
        display_name
        is_admin
      }
    }
  }
  `

  const DELETE_CUSTOMER = gql`
  mutation deleteProjectCustomer($user_id: uuid!, $project_id: uuid!) {
    delete_project_customers(where: {project_id: {_eq: $project_id}, user_id: {_eq: $user_id}}) {
      returning {
        project {
          project_customers {
            app_user {
              email
              id
            }
          }
        }
      }
    }
    delete_project_channel_oil(where: {app_user_id: {_eq: $user_id}, project: {id: {_eq: $project_id}}}) {
      returning {
        app_user_id
        project_id
      }
    }
  }
  `

  const ADD_CONSUMER = gql`
  mutation insertProjectConsumer($user_id: uuid!, $project_id: uuid!) {
    insert_project_consumers(objects: {user_id: $user_id, project_id: $project_id}) {
      returning {
        project {
          project_customers {
            app_user {
              id
              email
            }
          }
          channels {
            id
            duration
          }
        }
        project_id
      }
    }
  }
  `

  const DELETE_CONSUMER = gql`
  mutation deleteProjectConsumer($user_id: uuid!, $project_id: uuid!) {
    delete_project_consumers(where: {project_id: {_eq: $project_id}, user_id: {_eq: $user_id}}) {
      returning {
        project {
          project_consumers {
            app_user {
              email
              id
            }
          }
        }
      }
    }
    delete_project_channel_oil(where: {app_user_id: {_eq: $user_id}, project: {id: {_eq: $project_id}}}) {
      returning {
        app_user_id
        project_id
      }
    }
  }
  `

  const ADD_PLACEHOLDER = gql`
  mutation RegisterUserSimple($email: String!, $name: String!, $password: String!) {
    app_user_register(email: $email, name: $name, password: $password) {
      success
      user {
        projects {
          project_customers {
            app_user {
              id
              email
            }
          }
        }
      id
      }
    }
  }
  `

  const [isBusy, setIsBusy] = useState(p.isBusy || false);
  const [sessionName, setSessionName] = useState(p.initialName || "");
  const [sniffLength, setSniffLength] = useState("");
  const [sniffRatingScale, setSniffRatingScale] = useState("");
  const [testDescription, setTestDescription] = useState(p.intitialDescription || "");
  const [sniffAndRateDescription, setSniffAndRateDescription] = useState(p.intitialSniffAndRateDescription || "");
  const [image, setImage] = useState(p.initialImage || "");
  const [sessionType, setSessionType] = useState(p.initialSessionType || "Pre-Set Sniff Test");
  const [preferenceQuest, setPreferenceQuest] = useState(p.initialPreferenceQuest || "");
  const [playAroundType, setPlayAroundType] = useState(p.initialPlayAroundType || "Standard");
  const [positiveWording, setPositiveWording] = useState(p.initialPositive || "");
  const [negativeWording, setNegativeWording] = useState(p.initialNegative || "");
  const [projectId, setProjectId] = useState("")
  const {refetch: lookupEmail} = useQuery(EMAIL_LOOKUP)
  const [customers, setCustomers] = useState([{}])
  const [customerInsert, setCustomerInsert] = useState([])
  const [addCustomer] = useMutation(ADD_CUSTOMER)
  const [addOilUser] = useMutation(ADD_OIL_USER)
  const [noAdmin] = useMutation(NO_ADMIN)
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER)
  const [consumers, setConsumers] = useState([{}])
  const [consumerInsert, setConsumerInsert] = useState([])
  const [addConsumer] = useMutation(ADD_CONSUMER)
  const [deleteConsumer] = useMutation(DELETE_CONSUMER)
  const [addPlaceholder] = useMutation(ADD_PLACEHOLDER)
  const maySubmit = !isBusy && sessionName.length > 0;
  const testid = p.testid
  const generalinfo = p.generalinfo
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
 

  useEffect(() => {
    if(generalinfo?.name){
      setSessionName(generalinfo?.name)
      setImage(generalinfo?.image)
      setSniffAndRateDescription(generalinfo?.sniff_and_rate_description)
      setTestDescription(generalinfo?.description)
      setSessionType(generalinfo?.session_type)
      setSniffLength(generalinfo.sniff_test_length)
      setSniffRatingScale(generalinfo.sniff_rating_scale)
      setPlayAroundType(generalinfo?.play_around_type)
      setPreferenceQuest(generalinfo?.preference_question)
      setPositiveWording(generalinfo?.preference_positive)
      setNegativeWording(generalinfo?.preference_negative)
      setCustomers(generalinfo?.project_customers)
      setConsumers(generalinfo?.project_consumers)
      setProjectId(generalinfo?.id)
      if (p.generalinfo.description) {

        let contentState = convertFromHTML(p.generalinfo.description) as any as ContentState;
        setEditorState(EditorState.createWithContent(contentState))
        
      }
      setIsBusy(false)
    }
  }, [generalinfo]);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    if (html != "<p></p>") {
      setTestDescription(html)
    }
  }, [editorState]);

  const handleSubmit = () => {
    p.onSubmit(sessionName,  sniffLength, sniffRatingScale, sessionType, image, testDescription, sniffAndRateDescription, preferenceQuest, playAroundType, positiveWording, negativeWording);
  };

  const handleUpdate = () => {
    p.onUpdate(sessionName,  sniffLength, sniffRatingScale, sessionType, image, testDescription, sniffAndRateDescription, preferenceQuest, playAroundType, positiveWording, negativeWording);
  };

  const updateCustomers = (e:any) => {
    let custs = e.target.value.trim()
    custs = custs.replace(/[\n\r\t\s]+/g, ",").split(",")
    setCustomerInsert(custs)
  }

const addCustomers = (e:any) => {
  let theuserid = "0"
  if (projectId == "") {
    alert("Please save general information before adding customers to project!")
  } else {
    customerInsert.forEach(emails => {
      let noadminid = "0"
      lookupEmail({email: emails}).then ((resp) => {
        if (resp.data.app_user.length > 0){ 
          theuserid = resp.data.app_user[0].id
            addCustomer({variables: {
              user_id: resp.data.app_user[0].id,
              project_id: projectId
            }}).then((resp2) => {
              if (resp2.data) {
                  resp2.data.insert_project_customers.returning[0].project.channels.forEach((channel: any) => {
                  addOilUser({variables: {
                    app_user_id: theuserid,
                    channel_id: channel.id,
                    oil_level: channel.duration,
                    project_id: projectId
                  }}).then((resp9) => {
                    if (resp9.data) {
                      setCustomers(resp9.data.insert_project_channel_oil.returning[0].project.project_customers)   
                    }
                  })
                })                   
              }
            }).catch(e => {
              setIsBusy(false);
              console.log('Failed to add user');
              console.log(e);
            })       
        } else {
            console.log("cannot find " + emails)
            addPlaceholder({variables: {
              email: emails,
              name: emails,
              password: emails
            }}).then((resp3) => {
              if (resp3.data) {
                noadminid = resp3.data.app_user_register.user.id
                addCustomer({variables: {
                  user_id: resp3.data.app_user_register.user.id,
                  project_id: projectId
                }}).then((resp4) => {
                  if (resp4.data) {
                    noAdmin({variables: {
                      userid:noadminid
                    }})
                    resp4.data.insert_project_customers.returning[0].project.channels.forEach((channel: any) => {
                    addOilUser({variables: {
                      app_user_id: noadminid,
                      channel_id: channel.id,
                      oil_level: channel.duration,
                      project_id: projectId
                    }}).then((resp9) => {
                      if (resp9.data) {
                        setCustomers(resp9.data.insert_project_channel_oil.returning[0].project.project_customers)   
                      }
                    })
                    })                  
                  }
                }).catch(e => {
                  setIsBusy(false);
                  console.log('Failed to add user');
                  console.log(e);
                })               
              }
            }).catch(e => {
              setIsBusy(false);
              console.log('Failed to add user');
              console.log(e);
            })  
        }
        }).catch(e => {
        console.log(e);         
        })
    })
  }
}

const deleteCustomers = (userid: String) => {
  deleteCustomer({variables: {
    user_id: userid,
    project_id: projectId
  }}).then((resp) => {
    if (resp.data) {
      setCustomers(resp.data.delete_project_customers.returning[0].project.project_customers)                   
    }
  }).catch(e => {
    setIsBusy(false);
    console.log('Failed to add user');
    console.log(e);
  })   
}

const updateConsumers = (e:any) => {
  let custs = e.target.value.trim()
  custs = custs.replace(/[\n\r\t\s]+/g, ",").split(",")
  setConsumerInsert(custs)
}

const addConsumers = (e:any) => {
  if (projectId == "") {
    alert("Please save general information before adding consumers to project!")
  } else {
    consumerInsert.forEach(emails => {
      let noadminid = "0"
      let theuserid = "0"
      lookupEmail({email: emails}).then ((resp) => {
        if (resp.data.app_user.length > 0){
            theuserid = resp.data.app_user[0].id 
            addConsumer({variables: {
              user_id: resp.data.app_user[0].id,
              project_id: projectId
            }}).then((resp2) => {
              if (resp2.data) {
                resp2.data.insert_project_consumers.returning[0].project.channels.forEach((channel: any) => {
                  addOilUser({variables: {
                    app_user_id: theuserid,
                    channel_id: channel.id,
                    oil_level: channel.duration,
                    project_id: projectId
                  }}).then((resp9) => {
                    if (resp9.data) {
                      setConsumers(resp9.data.insert_project_channel_oil.returning[0].project.project_consumers)   
                    }
                  })
                })                
              }
            }).catch(e => {
              setIsBusy(false);
              console.log('Failed to add user');
              console.log(e);
            })       
        } else {
            console.log("cannot find " + emails)
            addPlaceholder({variables: {
              email: emails,
              name: emails,
              password: emails
            }}).then((resp3) => {
              if (resp3.data) {
                noadminid = resp3.data.app_user_register.user.id
                addConsumer({variables: {
                  user_id: resp3.data.app_user_register.user.id,
                  project_id: projectId
                }}).then((resp4) => {
                  if (resp4.data) {
                    noAdmin({variables: {
                      userid:noadminid
                    }})
                    resp4.data.insert_project_consumers.returning[0].project.channels.forEach((channel: any) => {
                      addOilUser({variables: {
                        app_user_id: noadminid,
                        channel_id: channel.id,
                        oil_level: channel.duration,
                        project_id: projectId
                      }}).then((resp9) => {
                        if (resp9.data) {
                          setConsumers(resp9.data.insert_project_channel_oil.returning[0].project.project_consumers)   
                        }
                      })
                      })            
                  }
                }).catch(e => {
                  setIsBusy(false);
                  console.log('Failed to add user');
                  console.log(e);
                })               
              }
            }).catch(e => {
              setIsBusy(false);
              console.log('Failed to add user');
              console.log(e);
            })  
        }
        }).catch(e => {
        console.log(e);         
        })
    })
  }
}

const deleteConsumers = (userid: String) => {
  deleteConsumer({variables: {
    user_id: userid,
    project_id: projectId
  }}).then((resp) => {
    if (resp.data) {
      setConsumers(resp.data.delete_project_consumers.returning[0].project.project_consumers)                   
    }
  }).catch(e => {
    setIsBusy(false);
    console.log('Failed to add user');
    console.log(e);
  })   
}

  return (
    <Grid container spacing={2} >
    <Grid container style={{position: 'sticky', top: '0', backgroundColor: '#eeeeee', zIndex: 10, paddingTop: '10px', paddingBottom: '10px'}}>
    <Grid item xs={12} justify="center" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    { testid == "0" &&
    <Button color="primary" onClick={handleSubmit} disabled={!maySubmit} >
    <ControlPoint />
        &nbsp;&nbsp;SAVE GENERAL INFORMATION
    </Button>
    }   
    { testid != "0" &&
    <Button color="primary" fullWidth onClick={handleUpdate} disabled={!maySubmit} >
    <CheckCircleOutline />
        &nbsp;&nbsp;UPDATE GENERAL INFORMATION
    </Button>  
    }  
    </Grid>
    </Grid>
    <Grid item xs={6}>
    <Box component={Card} style={{ border: '1px solid gray'}}>
      <CardContent>
        <Typography variant="h5">
          New Test
        </Typography>
        <Box marginTop="20px">
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            label="Name of Test (Required)"
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
            disabled={isBusy}
          />
        </Box>
        <Box marginTop="40px">
            <Typography variant="h5" style={{marginBottom: 25}}>
              Test Description
            </Typography>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
          </Box>
        <Box marginTop="40px">
          <div>
            <Typography variant="h5" style={{marginBottom: 25}}>
              Test Image
            </Typography>
            <LoadableImage
              url={image}
            />
            <ImageUpload onSuccess={setImage} />
          </div>
        </Box>
        <Box marginTop="40px">
          <Typography variant="h5" style={{marginBottom: 25, marginTop: 25}}>
              Sniff Settings
          </Typography>
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            multiline
            rows={5}
            variant="outlined"
            label="Sniff & Rate Description"
            value={sniffAndRateDescription}
            onChange={(e) => setSniffAndRateDescription(e.target.value)}
            disabled={isBusy}
          />
        </Box>  
        <Box marginTop="40px">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Sniff Test Length"
              value={generalinfo.sniff_test_length}
              defaultValue="5"
              onChange={(e) => setSniffLength(e.target.value)}
            />
          </Box>
          <Box marginTop="40px">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Sniff Test Rating Scale"
              defaultValue="10"
              value={generalinfo.sniff_rating_scale}
              onChange={(e) => setSniffRatingScale(e.target.value)}
            />
          </Box> 
      </CardContent>
    </Box>
    </Grid>
    <Grid item xs={6}>
    <Box component={Card} style={{ border: '1px solid gray'}}>
      <CardContent>
        <Box>
          <FormControl>
          <Typography variant="h5" style={{marginBottom: 25}}>
              Test Type
          </Typography>
          <RadioGroup style={{ marginBottom: 15 }}
            color="primary"
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => {setSessionType(e.target.value)}}
            value={sessionType}
          >
            <FormControlLabel value="Pre-Set Sniff Test" control={<Radio color="primary" />} label="Pre-Set Sniff Test" />
            <FormControlLabel value="Full Test" control={<Radio color="primary" />} label="Full Test" />
            <FormControlLabel value="End-Product Test" control={<Radio color="primary" />} label="End-Product Test" />
          </RadioGroup>
          </FormControl>
          { sessionType == "Full Test" &&
          <div>
          <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginBottom: 15 }}>Play Around Phase Setup</FormLabel>
          <RadioGroup
            color="primary"
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => setPlayAroundType(e.target.value)}
            value={playAroundType}
          >
            <FormControlLabel value="Standard" control={<Radio color="primary" />} label="Standard" />
            <FormControlLabel value="Customized" control={<Radio color="primary" />} label="Customized" />
          </RadioGroup>
          </FormControl>
          { playAroundType == "Customized" &&
            <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box marginTop="20px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    label="Preference Question"
                    value={preferenceQuest}
                    onChange={(e) => setPreferenceQuest(e.target.value)}
                    disabled={isBusy}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box marginTop="20px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    label="Preference Positive Wording"
                    value={positiveWording}
                    onChange={(e) => setPositiveWording(e.target.value)}
                    disabled={isBusy}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box marginTop="20px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    label="Preference Negative Wording"
                    value={negativeWording}
                    onChange={(e) => setNegativeWording(e.target.value)}
                    disabled={isBusy}
                  />
                </Box>
              </Grid>
            </Grid>
            </div>
            }
          </div>
          }
          <Typography variant="h6" style={{ marginTop: 25 }}>
          Customers
          </Typography>
          {customers?.map((x:any) => (
            <Grid container spacing={2} alignItems="center" style={{ marginTop:5 }}>
              <Grid item xs={6} style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}>
                <Typography variant="subtitle2" style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}>
                {x?.app_user?.email}&nbsp;&nbsp;
                <CheckCircleOutline fontSize="small" htmlColor="green" style={{display: 'none'}}></CheckCircleOutline>
                </Typography>
              </Grid>   
              <Grid item xs={3} >
                  {/*<Link href="#" color="inherit" style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}><Edit fontSize="small"></Edit>&nbsp;Modify</Link>*/}
              </Grid>
              <Grid item xs={3}>
                <Link onClick={() => deleteCustomers(x?.app_user?.id)} href="#" color="inherit" style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}><RemoveCircle fontSize="small" htmlColor="red"></RemoveCircle>&nbsp;Delete</Link>
              </Grid>                 
            </Grid>
            ))}
            <Box marginTop="40px">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                onChange={(ev) => updateCustomers(ev)}
              />
              <Button fullWidth color="primary" variant="contained" style={{marginTop: '10px'}} onClick={(ev) => addCustomers(ev)}>
                ADD CUSTOMERS
              </Button>
            </Box>
            <Typography variant="h6" style={{ marginTop: 25 }}>
            Consumers
            </Typography>
            {consumers?.map((x:any) => (
            <Grid container spacing={2} alignItems="center" style={{ marginTop:5 }}>
              <Grid item xs={6} style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}>
                <Typography variant="subtitle2" style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}>
                {x?.app_user?.email}&nbsp;&nbsp;
                <CheckCircleOutline fontSize="small" htmlColor="green" style={{display: 'none'}}></CheckCircleOutline>
                </Typography>
              </Grid>    
              <Grid item xs={3} >
              </Grid>
              <Grid item xs={3}>
                <Link onClick={() => deleteConsumers(x?.app_user?.id)} href="#" color="inherit" style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12
                }}><RemoveCircle fontSize="small" htmlColor="red"></RemoveCircle>&nbsp;Delete</Link>
              </Grid>                 
            </Grid>
            ))}
            <Box marginTop="40px">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                onChange={(ev) => updateConsumers(ev)}
              />
              <Button fullWidth color="primary" variant="contained" style={{marginTop: '10px'}} onClick={(ev) => addConsumers(ev)}>
                ADD CONSUMERS
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Box>
      </Grid>
      </Grid>
  );
}

interface NewChannelSettingsProps {
  testid?: string,
  generalinfo?: any,
  isBusy?: boolean;

  onUpdate2: (thischannelsettings: []) => void;
}

function ChannelSettings(p: NewChannelSettingsProps) {

  const [channelSettings, setChannelSettings] = useState(p.generalinfo.channels);
  const handleUpdate2 = () => { p.onUpdate2(channelSettings)};

  const updateName = (e:any, index:number) => {
    let newChannelSettings = [...channelSettings]
    newChannelSettings[index] = {...newChannelSettings[index], name: e.target.value}
    setChannelSettings(newChannelSettings)
  }

  const updateDuration = (e:any, index:number) => {
    let newChannelSettings = [...channelSettings]
    newChannelSettings[index] = {...newChannelSettings[index], duration: e.target.value}
    setChannelSettings(newChannelSettings)
  }

  const updateDesc = (e:any, index:number) => {
    let newChannelSettings = [...channelSettings]
    newChannelSettings[index] = {...newChannelSettings[index], description: e.target.value}
    setChannelSettings(newChannelSettings)
  }

  const updateChannelType = (e:any, index:number) => {
      let newChannelSettings = [...channelSettings]
      newChannelSettings[index] = {...newChannelSettings[index], channel_type: e}
      setChannelSettings(newChannelSettings)
    }

return (
<div>
<Grid container style={{position: 'sticky', top: '0', backgroundColor: '#eeeeee', zIndex: 10}}>
  <Grid item xs={12} justify="center" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
  <Button color="primary" onClick={handleUpdate2} >
  <CheckCircleOutline />
      &nbsp;&nbsp;UPDATE CHANNEL SETTINGS
  </Button>  
  </Grid>
  </Grid>
{channelSettings.map((channel: any, index: any) => (
<Accordion style={{ marginTop: '15px', border: '1px solid gray' }}>
  <AccordionSummary
    expandIcon={<ExpandMore />}
    aria-controls="panel2a-content"
    id="panel2a-header"
  >
  <Typography>{channel.name}</Typography>
  </AccordionSummary>
  <AccordionDetails >
  <Box marginTop="40px" width="100%" paddingLeft="20%" paddingRight="20%">
          <Typography variant="h6">
          Channel Name
          </Typography>
          <Box marginTop="10px">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              defaultValue={channel.name}
              onChange={(ev) => updateName(ev, index)}
            />
          </Box>
          <Typography variant="h6" style={{ marginTop: 40 }}>
          Channel Ingredients Description
          </Typography>
          <Box marginTop="10px">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              multiline
              rows={8}
              variant="outlined"
              defaultValue={channel.description}
              onChange={(ev) => updateDesc(ev, index)}
            />
          </Box>
          <Typography variant="h6">
          Duration
          </Typography>
          <Box marginTop="10px">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              defaultValue={channel.duration}
              onChange={(ev) => updateDuration(ev, index)}
            />
          </Box>
          {p.generalinfo?.session_type !== 'Pre-Set Sniff Test' &&
          <FormControl style={{ marginBottom: 40}}>
          <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginTop: 40}}><Typography variant="h6">Channel Type</Typography></FormLabel>
          <RadioGroup
            color="primary"
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => {updateChannelType(e.target.value, index)}}
            value={channel.channel_type}    
          >
            <FormControlLabel value="Customizable" control={<Radio color="primary" />} label="Customizable" />
            <FormControlLabel value="Locked" control={<Radio color="primary" />} label="Locked" />
            <FormControlLabel value="Hidden" control={<Radio color="primary" />} label="Hidden" />
          </RadioGroup>
          </FormControl>
          }
        </Box>
  </AccordionDetails>
</Accordion>
))}
</div>
);
}

interface NewSurveyProps {
  testid?: string,
  generalinfo?: any,
  isBusy?: boolean;

  onSubmit4: (surveyid: string, section: []) => void;
  onDelete4: (sectionid: string) => void;
  onUpdate4: (section: []) => void;
}

function SurveySettings(p: NewSurveyProps) {

  const SWAP_SECTION = gql`
  mutation updateSectionSort($sectionid: uuid!, $sort_position: Int!) {
      update_SUR_section(where: {ID: {_eq: $sectionid}}, _set: {sort_position: $sort_position}) {
        returning {
          SUR_survey {
              SUR_sections(order_by: {sort_position: asc}) {
                ID
                title
                description
                image
                survey_id
                sort_position
                SUR_survey_questions(order_by: {sort_position: asc}) {
                  description
                  id
                  max_value
                  min_value
                  mixes
                  name
                  options {
                    id
                    question_id
                    sort_position
                    text
                  }
                }
              }
            } 
          }
        }
      }
    
  `

  const onSubmit4 = () => { p.onSubmit4(p.generalinfo.survey_id, section)};
  const onDelete4 = (sectionid: string) => { p.onDelete4(sectionid)};
  const handleUpdate4 = () => { p.onUpdate4(section)};
  const [section, setSection] = useState(p.generalinfo.survey.SUR_sections);
  const [swapSections] = useMutation(SWAP_SECTION)

  useEffect(() => {
  if(p.generalinfo){
      setSection(p.generalinfo.survey.SUR_sections)
  }
  }, [p.generalinfo]);

  useEffect(() => {
  if(section){
      console.log("changed")
  }
  }, [section]);

  const updateSectionTitle = (e:any, index:number) => {
  const newSection = [...section]
  newSection[index] = {...newSection[index], title: e.target.value}
  setSection(newSection)
  }

  const updateSectionDesc = (e:any, index:number) => {
  const newSection = [...section]
  newSection[index] = {...newSection[index], description: e.target.value}
  setSection(newSection)
  }

  const updatePopinInfo = (e:any, index:number) => {
  const newSection = [...section]
  newSection[index] = {...newSection[index], popin_info: e.target.value}
  setSection(newSection)
  }

  const setTheSectionImage = (ev: any, index:number) => {
  const newSection = [...section]
  newSection[index] = {...newSection[index], image: ev}
  setSection(newSection)
  }

  const onSectionMoved = (sectionIndex: number, direction: string) => {
  const nextSection = section[direction === 'up' ? sectionIndex - 1 : sectionIndex + 1];
  const nextSectionID = nextSection.ID
  const nextSectionSortPosition = nextSection.sort_position
  const thisSectionID = section[sectionIndex].ID
  const thisSectionSortPosition = section[sectionIndex].sort_position

  swapSections({
      variables: {
          sectionid: thisSectionID,
          sort_position: nextSectionSortPosition
      }}).then ((resp) => {
      if (resp.data){
          swapSections({
              variables: {
                  sectionid: nextSectionID,
                  sort_position: thisSectionSortPosition
              }}).then ((resp2) => {
              if (resp2.data){
                  setSection(resp2.data.update_SUR_section.returning[0].SUR_survey.SUR_sections)   
              }
              }).catch(e => {
              console.log(e);         
              })
      }
      }).catch(e => {
      console.log(e);         
      })
  }

return (
  <div>
  <Grid container spacing={2} style={{position: 'sticky', top: '0', backgroundColor: '#eeeeee', zIndex: 10}}>
      <Grid item xs={3} />
      <Grid item xs={3} >
      <Button color="primary" fullWidth onClick={onSubmit4} style={{marginBottom: '10px'}}>
      <ControlPoint />
          &nbsp;&nbsp;Add Section
      </Button>      
      </Grid>
      <Grid item xs={3} >
      <Button color="primary" fullWidth onClick={handleUpdate4} style={{marginBottom: '10px', color: 'green'}}>
      <UpdateOutlined />
          &nbsp;&nbsp;Update Survey
      </Button>      
      </Grid>
      <Grid item xs={3} />
  </Grid>
  {section.length > 0 && section.map((section: any, index: any) => ( 
  <div style={{ marginTop: '15px'}} >
      <Paper elevation={3} style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '30px',
            border: '1px solid gray'
        }}>
      <Grid container spacing={2}>
          <Grid item xs={7} style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
          &nbsp;&nbsp;{section.title}
          </Grid>
          <Grid item xs={2} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
          <Button onClick={() => onSectionMoved(index, "up")}>
          move up
          </Button>
          </Grid>
          <Grid item xs={2} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
          <Button onClick={() => onSectionMoved(index, "down")}>
              move down
          </Button>            
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => onDelete4(section.ID)}aria-label="delete">
                <FontAwesomeIcon icon={faTrash} fixedWidth />
            </IconButton>        
          </Grid>
      </Grid>
      <Grid container spacing={2}>
      <Grid item xs={12}>
      <Box component={Card} style={{
            paddingLeft: 100,
            paddingRight: 100,
        }} >
          <CardContent>
          <Box marginTop="20px" >
              <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Section Title"
              value={section.title}
              onChange={(ev) => updateSectionTitle(ev, index)}
              
              />
          </Box>
          <Box marginTop="40px">
              <div>
              <InputLabel> Section Image</InputLabel>
              <LoadableImage
                  url={section.image}
              />
              <ImageUpload onSuccess={(ev) => setTheSectionImage(ev, index)} />
              </div>
          </Box>
          <Box marginTop="40px">
              <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              label="End Section Popin Title"
              value={section.description}
              onChange={(ev) => updateSectionDesc(ev, index)}
              
              />
          </Box>
          <Box marginTop="40px">
                <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                label="End Section Popin Information"
                value={section.popin_info}
                onChange={(ev) => updatePopinInfo(ev, index)}
                
                />
            </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
              <EditSurvey surveyId={section.ID} />
          </Box>
          </CardContent>
      </Box>
      </Grid>
      </Grid>
      </Paper>         
  </div>

  ))}

</div>
);
}

interface NewSniffTestSetupProps {
  testid?: string,
  generalinfo?: any,
  isBusy?: boolean;

  onSubmit3: (allinfo: any, sniffSettings: []) => void;
  onDuplicate: (sniffSettings: [], index: any, allinfo: any, e:any, sort_position: any) => void;
  onDeleteSniff: (id: any, e:any) => void;
  onUpdate3: (sniffSettings: []) => void;
}

function SniffTestSetup(p: NewSniffTestSetupProps) {

  const GetChannelName = gql`
  query getChannelData($project_id: uuid) {
    project_channel(where: {project_id: {_eq: $project_id}}, order_by: {channel_index: asc}) {
      name
      channel_index
    }
  }
  `

  const SWAP_MIX = gql`
  mutation updateMixSort($mixid: uuid!, $sort_position: Int!) {
    update_MIX_mix(where: {id: {_eq: $mixid}}, _set: {sort_position: $sort_position}) {
      returning {
        project {
          mixes(order_by: {sort_position: asc}) {
            sort_position
            id
            name
            description
            channels
          }
        }
      }
    }
  }
  `

  const generalinfo = p.generalinfo
  const testId = p.testid
  const indexid = 0
  const {data: channelname, refetch: refetchchanelname} = useQuery(GetChannelName, {fetchPolicy: "network-only", variables: {project_id: testId, index: indexid}})
  const onSubmit3 = () => { p.onSubmit3(generalinfo, sniffSettings)};
  const handleDuplicate = (index: any, e:any, sort_position: any) => { p.onDuplicate(sniffSettings, index, generalinfo, e, sort_position)};
  const handleUpdate3 = () => { p.onUpdate3(sniffSettings)};
  const handleDeleteSniff = (id: any, e:any) => { p.onDeleteSniff(id, e)};
  const [channelNames, setChannelNames] = useState(channelname)
  const [sniffSettings, setSniffSettings] = useState(p.generalinfo.mixes);
  const [swapMixes] = useMutation(SWAP_MIX);


  useEffect(() => {
    if(generalinfo?.name){
      setSniffSettings(generalinfo.mixes)
      console.log(sniffSettings)
      setChannelNames(refetchchanelname)
    }
  }, [generalinfo]);

  const updateName = (e:any, index:number) => {
    let newSniffSettings = [...sniffSettings]
    newSniffSettings[index] = {...newSniffSettings[index], name: e.target.value}
    setSniffSettings(newSniffSettings)
  }

  const getChannelName1 = (thisindex: any) => {
    if(channelname) {
      return channelname.project_channel[thisindex].name
    } else {
      return ""
    }
  }

  const incrementFlow = (event: any, channelindex: any, sniffindex:any) => {
  const newSniffSettings = JSON.parse(JSON.stringify(sniffSettings))
  if (newSniffSettings[sniffindex].channels[channelindex] < 100) {
    newSniffSettings[sniffindex].channels[channelindex] = newSniffSettings[sniffindex].channels[channelindex] + 20
  }
  setSniffSettings(newSniffSettings)
  }

  const decrementFlow = (event: any, channelindex: any, sniffindex: any) => {
  const newSniffSettings = JSON.parse(JSON.stringify(sniffSettings))
  if (newSniffSettings[sniffindex].channels[channelindex] > 0) {
    newSniffSettings[sniffindex].channels[channelindex] = newSniffSettings[sniffindex].channels[channelindex] - 20
  }
  setSniffSettings(newSniffSettings)
  }

  const onMixMoved = (sniffIndex: number, direction: string, e:any) => {
    e.stopPropagation()
    const nextMix = sniffSettings[direction === 'up' ? sniffIndex - 1 : sniffIndex + 1];
    const nextMixId = nextMix.id
    const nextMixSortPosition = nextMix.sort_position
    const thisMixID = sniffSettings[sniffIndex].id
    const thisMixSortPosition = sniffSettings[sniffIndex].sort_position
    console.log(thisMixID + " - " + nextMixSortPosition + " - " + nextMixId + " - " + thisMixSortPosition)
    swapMixes({
        variables: {
            mixid: thisMixID,
            sort_position: nextMixSortPosition
        }}).then ((resp) => {
        if (resp.data){
            swapMixes({
                variables: {
                    mixid: nextMixId,
                    sort_position: thisMixSortPosition
                }}).then ((resp2) => {
                if (resp2.data){
                  console.log(resp2.data.update_MIX_mix.returning[0].project.mixes)
                    setSniffSettings(resp2.data.update_MIX_mix.returning[0].project.mixes)   
                }
                }).catch(e => {
                console.log(e);         
                })
        }
        }).catch(e => {
        console.log(e);         
        })
    }

  return (
  <div>
  <Grid container spacing={2} style={{position: 'sticky', top: '0', backgroundColor: '#eeeeee', zIndex: 10}}>
    <Grid item xs={3}></Grid>
    <Grid item xs={3} >
    <Button color="primary" fullWidth onClick={onSubmit3} >
    <ControlPoint />
        &nbsp;&nbsp;Add Sniff
    </Button>      
    </Grid>
    <Grid item xs={3} >
    <Button color="primary" disabled={p.generalinfo.mixes.length == 0} fullWidth onClick={handleUpdate3} >
    <ControlPoint />
        &nbsp;&nbsp;Update Sniffs
    </Button>      
    </Grid>
    <Grid item xs={3}></Grid>
  </Grid>

  { sniffSettings.length != 0 && sniffSettings.map((mix: any, index: any) => (
  <Accordion style={{ marginTop: '15px', border: '1px solid gray' }}>
    <AccordionSummary
      expandIcon={<ExpandMore />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    > 
    <Grid container spacing={2}>
    <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }}>
        <Menu></Menu>&nbsp;{mix.name}</Typography>
      </Grid>
      <Grid item xs={2}>
      <Box><Button onClick={(e) => handleDuplicate(index, e, mix.sort_position)} >Duplicate</Button></Box>
        </Grid>
        <Grid item xs={2}>
          <Box>&nbsp;&nbsp;<Button onClick={(e) => handleDeleteSniff(mix.id, e)}>Delete</Button></Box>
        </Grid>
        <Grid item xs={2} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <Button onClick={(e) => onMixMoved(index, "up", e)}>
            move up
            </Button>
        </Grid>
        <Grid item xs={2} style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <Button onClick={(e) => onMixMoved(index, "down", e)}>
                move down
            </Button>            
        </Grid>
    </Grid>
    </AccordionSummary>
    <AccordionDetails style={{marginBottom: 25}}>

    <Grid container spacing={2}>
        <Grid item xs={1}>
        </Grid>
      <Grid item xs={5}>
        <Box>
          <Typography variant="subtitle1" style={{marginTop: '20px'}}>Name</Typography>
        </Box>
        <Box marginTop="10px" marginBottom="25px">
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            defaultValue={mix.name}
            onChange={(ev) => updateName(ev, index)}
          />
        </Box>
      </Grid>
      <Grid item xs={1}>
      </Grid>
      <Grid item xs={4}>
        <Box>
          <Typography variant="subtitle1" style={{marginTop: '20px'}}>Random Code</Typography>
        </Box>
        <Box marginTop="20px">
        <Typography variant="subtitle1" color="primary" >{mix.description}</Typography>
        </Box>
      </Grid>
      { //* ************************************************************CHANNELS 1 AND 5**************************************************************************
      }
      <Grid item xs={4} style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginLeft:35,
        }} >
        1.{getChannelName1(0)}</Typography>
      </Grid>
      <Grid item xs={2}  style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            paddingLeft: 20,
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 0, index)}></RemoveCircleOutline>&nbsp;
        <div style={{
            borderStyle: "solid",
            borderWidth: 4,
            borderColor: "#4A1690",
            borderRadius: 50,
            width: "47px",
            height: "47px",
          }}>
        <span style={{
          lineHeight: "47px",
          display: "block",
          textAlign: "center",
        }}>{mix.channels[0]}</span>
        </div>
        &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 0, index)}></AddCircleOutline></Typography>
      </Grid>
      <Grid item xs={4} style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        5.{getChannelName1(4)}</Typography>
      </Grid>
      <Grid item xs={2}  style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 4, index)}></RemoveCircleOutline>&nbsp;
        <div style={{
            borderStyle: "solid",
            borderWidth: 4,
            borderColor: "#4A1690",
            borderRadius: 50,
            width: "47px",
            height: "47px",
          }}>
        <span style={{
          lineHeight: "47px",
          display: "block",
          textAlign: "center",
        }}>{mix.channels[4]}</span>
        </div>
        &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 4, index)}></AddCircleOutline></Typography>
      </Grid>
      { //* ************************************************************CHANNELS 2 AND 6**************************************************************************
      }
      <Grid item xs={4} style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginLeft:35,
        }} >
        2.{getChannelName1(1)}</Typography>
      </Grid>
      <Grid item xs={2}  style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            paddingLeft: 20,
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 1, index)}></RemoveCircleOutline>&nbsp;
        <div style={{
            borderStyle: "solid",
            borderWidth: 4,
            borderColor: "#4A1690",
            borderRadius: 50,
            width: "47px",
            height: "47px",
          }}>
        <span style={{
          lineHeight: "47px",
          display: "block",
          textAlign: "center",
        }}>{mix.channels[1]}</span>
        </div>
        &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 1, index)}></AddCircleOutline></Typography>
      </Grid>
      <Grid item xs={4} style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        6.{getChannelName1(5)}</Typography>
      </Grid>
      <Grid item xs={2}  style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 5, index)}></RemoveCircleOutline>&nbsp;
        <div style={{
            borderStyle: "solid",
            borderWidth: 4,
            borderColor: "#4A1690",
            borderRadius: 50,
            width: "47px",
            height: "47px",
          }}>
        <span style={{
          lineHeight: "47px",
          display: "block",
          textAlign: "center",
        }}>{mix.channels[5]}</span>
        </div>
        &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 5, index)}></AddCircleOutline></Typography>
      </Grid>
      { //* ************************************************************CHANNELS 3 AND 7**************************************************************************
      }
      <Grid item xs={4} style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginLeft:35,
        }} >
        3.{getChannelName1(2)}</Typography>
      </Grid>
      <Grid item xs={2}  style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            paddingLeft: 20,
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 2, index)}></RemoveCircleOutline>&nbsp;
        <div style={{
            borderStyle: "solid",
            borderWidth: 4,
            borderColor: "#4A1690",
            borderRadius: 50,
            width: "47px",
            height: "47px",
          }}>
        <span style={{
          lineHeight: "47px",
          display: "block",
          textAlign: "center",
        }}>{mix.channels[2]}</span>
        </div>
        &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 2, index)}></AddCircleOutline></Typography>
      </Grid>
      <Grid item xs={4} style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        7.{getChannelName1(6)}</Typography>
      </Grid>
      <Grid item xs={2}  style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 6, index)}></RemoveCircleOutline>&nbsp;
        <div style={{
            borderStyle: "solid",
            borderWidth: 4,
            borderColor: "#4A1690",
            borderRadius: 50,
            width: "47px",
            height: "47px",
          }}>
        <span style={{
          lineHeight: "47px",
          display: "block",
          textAlign: "center",
        }}>{mix.channels[6]}</span>
        </div>
        &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 6, index)}></AddCircleOutline></Typography>
      </Grid>  
      { //* ************************************************************CHANNELS 4 AND 8**************************************************************************
      }
      <Grid item xs={4} style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginLeft:35,
        }} >
        4.{getChannelName1(3)}</Typography>
      </Grid>
      <Grid item xs={2}  style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            paddingLeft: 20,
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 3, index)}></RemoveCircleOutline>&nbsp;
        <div style={{
            borderStyle: "solid",
            borderWidth: 4,
            borderColor: "#4A1690",
            borderRadius: 50,
            width: "47px",
            height: "47px",
          }}>
        <span style={{
          lineHeight: "47px",
          display: "block",
          textAlign: "center",
        }}>{mix.channels[3]}</span>
        </div>
        &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 3, index)}></AddCircleOutline></Typography>
      </Grid>
      <Grid item xs={4} style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        8.{getChannelName1(7)}</Typography>
      </Grid>
      <Grid item xs={2}  style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <Typography style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }} >
        <RemoveCircleOutline color="primary" onClick={(ev) => decrementFlow(ev, 7, index)}></RemoveCircleOutline>&nbsp;
        <div style={{
            borderStyle: "solid",
            borderWidth: 4,
            borderColor: "#4A1690",
            borderRadius: 50,
            width: "47px",
            height: "47px",
          }}>
        <span style={{
          lineHeight: "47px",
          display: "block",
          textAlign: "center",
        }}>{mix.channels[7]}</span>
        </div>
        &nbsp;<AddCircleOutline color="primary" onClick={(ev) => incrementFlow(ev, 7, index)}></AddCircleOutline></Typography>
      </Grid>    
    </Grid>
    </AccordionDetails>
  </Accordion>
  ))}
  </div>
  );
  }